import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }

export interface SharedGraphQlOperations {
    /** shared/src/auth.ts */
    CurrentAuthState: (variables: CurrentAuthStateVariables) => CurrentAuthStateResult

    /** shared/src/backend/file.ts */
    HighlightedFile: (variables: HighlightedFileVariables) => HighlightedFileResult

    /** shared/src/backend/file.ts */
    HighlightedFileVSCE: (variables: HighlightedFileVSCEVariables) => HighlightedFileVSCEResult

    /** shared/src/backend/repo.ts */
    ResolveRawRepoName: (variables: ResolveRawRepoNameVariables) => ResolveRawRepoNameResult

    /** shared/src/backend/repo.ts */
    TreeEntries: (variables: TreeEntriesVariables) => TreeEntriesResult

    /** shared/src/backend/settings.ts */
    ViewerSettings: (variables: ViewerSettingsVariables) => ViewerSettingsResult

    /** shared/src/codeintel/legacy-extensions/lsif/definition-hover.ts */
    LegacyDefinitionAndHover: (variables: LegacyDefinitionAndHoverVariables) => LegacyDefinitionAndHoverResult

    /** shared/src/codeintel/legacy-extensions/lsif/implementations.ts */
    LegacyImplementations: (variables: LegacyImplementationsVariables) => LegacyImplementationsResult

    /** shared/src/codeintel/legacy-extensions/lsif/ranges.ts */
    LegacyRanges: (variables: LegacyRangesVariables) => LegacyRangesResult

    /** shared/src/codeintel/legacy-extensions/lsif/references.ts */
    LegacyReferences: (variables: LegacyReferencesVariables) => LegacyReferencesResult

    /** shared/src/codeintel/legacy-extensions/lsif/stencil.ts */
    LegacyStencil: (variables: LegacyStencilVariables) => LegacyStencilResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyResolveRepo: (variables: LegacyResolveRepoVariables) => LegacyResolveRepoResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyResolveRepo2: (variables: LegacyResolveRepo2Variables) => LegacyResolveRepo2Result

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyRepositoryIntrospection: (
        variables: LegacyRepositoryIntrospectionVariables
    ) => LegacyRepositoryIntrospectionResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyLocalCodeIntelIntrospectionQuery: (
        variables: LegacyLocalCodeIntelIntrospectionQueryVariables
    ) => LegacyLocalCodeIntelIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacySymbolInfoIntrospectionQuery: (
        variables: LegacySymbolInfoIntrospectionQueryVariables
    ) => LegacySymbolInfoIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacySymbolLocationRangeIntrospectionQuery: (
        variables: LegacySymbolLocationRangeIntrospectionQueryVariables
    ) => LegacySymbolLocationRangeIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyFileContent: (variables: LegacyFileContentVariables) => LegacyFileContentResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyStencilIntrospectionQuery: (
        variables: LegacyStencilIntrospectionQueryVariables
    ) => LegacyStencilIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyCodeIntelSearch2: (variables: LegacyCodeIntelSearch2Variables) => LegacyCodeIntelSearch2Result

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyCodeIntelSearch3: (variables: LegacyCodeIntelSearch3Variables) => LegacyCodeIntelSearch3Result

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LocalCodeIntel: (variables: LocalCodeIntelVariables) => LocalCodeIntelResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacySymbolInfo: (variables: LegacySymbolInfoVariables) => LegacySymbolInfoResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacySymbolInfo2: (variables: LegacySymbolInfo2Variables) => LegacySymbolInfo2Result

    /** shared/src/extensions/helpers.ts */
    Extensions: (variables: ExtensionsVariables) => ExtensionsResult

    /** shared/src/search/backend.ts */
    ListSearchContexts: (variables: ListSearchContextsVariables) => ListSearchContextsResult

    /** shared/src/search/backend.ts */
    FetchSearchContext: (variables: FetchSearchContextVariables) => FetchSearchContextResult

    /** shared/src/search/backend.ts */
    FetchSearchContextBySpec: (variables: FetchSearchContextBySpecVariables) => FetchSearchContextBySpecResult

    /** shared/src/search/backend.ts */
    CreateSearchContext: (variables: CreateSearchContextVariables) => CreateSearchContextResult

    /** shared/src/search/backend.ts */
    UpdateSearchContext: (variables: UpdateSearchContextVariables) => UpdateSearchContextResult

    /** shared/src/search/backend.ts */
    DeleteSearchContext: (variables: DeleteSearchContextVariables) => DeleteSearchContextResult

    /** shared/src/search/backend.ts */
    IsSearchContextAvailable: (variables: IsSearchContextAvailableVariables) => IsSearchContextAvailableResult

    /** shared/src/search/backend.ts */
    highlightCode: (variables: highlightCodeVariables) => highlightCodeResult

    /** shared/src/search/backend.ts */
    EventLogsData: (variables: EventLogsDataVariables) => EventLogsDataResult

    /** shared/src/search/backend.ts */
    DefaultSearchContextSpec: (variables: DefaultSearchContextSpecVariables) => DefaultSearchContextSpecResult

    /** shared/src/settings/edit.ts */
    EditSettings: (variables: EditSettingsVariables) => EditSettingsResult

    /** shared/src/settings/edit.ts */
    OverwriteSettings: (variables: OverwriteSettingsVariables) => OverwriteSettingsResult

    /** shared/src/settings/temporary/TemporarySettingsStorage.ts */
    GetTemporarySettings: (variables: GetTemporarySettingsVariables) => GetTemporarySettingsResult

    /** shared/src/settings/temporary/TemporarySettingsStorage.ts */
    EditTemporarySettings: (variables: EditTemporarySettingsVariables) => EditTemporarySettingsResult

    /** shared/src/settings/temporary/testUtils.tsx */
    TemporarySettings: (variables: TemporarySettingsVariables) => TemporarySettingsResult

    /** shared/src/testing/driver.ts */
    ExternalServicesForTests: (variables: ExternalServicesForTestsVariables) => ExternalServicesForTestsResult

    /** shared/src/testing/driver.ts */
    RepositoryNameForTests: (variables: RepositoryNameForTestsVariables) => RepositoryNameForTestsResult

    /** shared/src/testing/driver.ts */
    SiteForTests: (variables: SiteForTestsVariables) => SiteForTestsResult

    /** shared/src/testing/driver.ts */
    UpdateSiteConfigurationForTests: (
        variables: UpdateSiteConfigurationForTestsVariables
    ) => UpdateSiteConfigurationForTestsResult

    /** shared/src/testing/driver.ts */
    UserSettingsForTests: (variables: UserSettingsForTestsVariables) => UserSettingsForTestsResult

    /** shared/src/testing/driver.ts */
    OverwriteSettingsForTests: (variables: OverwriteSettingsForTestsVariables) => OverwriteSettingsForTestsResult
}

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** An arbitrarily large integer encoded as a decimal string. */
    BigInt: string
    /**
     * An RFC 3339-encoded UTC date string, such as 1973-11-29T21:33:09Z. This value can be parsed into a
     * JavaScript Date using Date.parse. To produce this value from a JavaScript Date instance, use
     * Date#toISOString.
     */
    DateTime: string
    /** A Git object ID (SHA-1 hash, 40 hexadecimal characters). */
    GitObjectID: string
    /** A string that contains valid JSON, with additional support for //-style comments and trailing commas. */
    JSONCString: string
    /** A valid JSON value. */
    JSONValue: unknown
    /** A quadruple that represents all possible states of the published value: true, false, 'draft', or null. */
    PublishedValue: boolean | 'draft'
}

/** A new external service. */
export interface AddExternalServiceInput {
    /** The JSON configuration of the external service. */
    config: Scalars['String']
    /** The display name of the external service. */
    displayName: Scalars['String']
    /** The kind of the external service. */
    kind: ExternalServiceKind
    /**
     * The namespace this external service belongs to.
     * This can be used both for a user and an organization.
     */
    namespace?: InputMaybe<Scalars['ID']>
}

/** Input object for adding insight view to dashboard. */
export interface AddInsightViewToDashboardInput {
    /** ID of the dashboard. */
    dashboardId: Scalars['ID']
    /** ID of the insight view to attach to the dashboard */
    insightViewId: Scalars['ID']
}

/** The possible types of alerts (Alert.type values). */
export enum AlertType {
    ERROR = 'ERROR',
    INFO = 'INFO',
    WARNING = 'WARNING',
}

/** A pre-defined periods to get site analytics. */
export enum AnalyticsDateRange {
    /** Custom date range. */
    CUSTOM = 'CUSTOM',
    /** Last month date range. */
    LAST_MONTH = 'LAST_MONTH',
    /** Last 3 months date range. */
    LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
    /** Last week date range. */
    LAST_WEEK = 'LAST_WEEK',
}

/** Group site analytics by period. */
export enum AnalyticsGrouping {
    /** Group data by day. */
    DAILY = 'DAILY',
    /** Group data by week. */
    WEEKLY = 'WEEKLY',
}

/** Denotes the type of operation of a given log entry. */
export enum AuditLogOperation {
    /** Denotes this log entry represents an INSERT query. */
    CREATE = 'CREATE',
    /** Denotes this log entry represents an UPDATE query. */
    MODIFY = 'MODIFY',
}

/** BackfillQueueOrderBy enumerates the ways a backfill queue list can be ordered. */
export enum BackfillQueueOrderBy {
    QUEUE_POSITION = 'QUEUE_POSITION',
    STATE = 'STATE',
}

/** Enum of the possible background routine types */
export enum BackgroundRoutineType {
    /** Custom routine */
    CUSTOM = 'CUSTOM',
    /** DB-backed worker */
    DB_BACKED = 'DB_BACKED',
    /** Periodic routine */
    PERIODIC = 'PERIODIC',
    /** Periodic routine with metrics set up */
    PERIODIC_WITH_METRICS = 'PERIODIC_WITH_METRICS',
}

/** The state of the batch change. */
export enum BatchChangeState {
    CLOSED = 'CLOSED',
    DRAFT = 'DRAFT',
    OPEN = 'OPEN',
}

/** The possible sources of a batch spec. */
export enum BatchSpecSource {
    /** The spec was created from the local src-cli workflow. */
    LOCAL = 'LOCAL',
    /**
     * This spec was created for remote server-side execution, e.g. from the web UI editor,
     * or with src batch remote.
     */
    REMOTE = 'REMOTE',
}

/** The possible states of a batch spec. */
export enum BatchSpecState {
    /** The execution has been canceled. */
    CANCELED = 'CANCELED',
    /** The execution is being canceled. */
    CANCELING = 'CANCELING',
    /** This spec was processed successfully. */
    COMPLETED = 'COMPLETED',
    /** This spec failed to be processed. */
    FAILED = 'FAILED',
    /** The spec is not yet enqueued for processing. */
    PENDING = 'PENDING',
    /** This spec is being processed. */
    PROCESSING = 'PROCESSING',
    /** This spec is queued to be processed. */
    QUEUED = 'QUEUED',
}

/** State of the workspace resolution. */
export enum BatchSpecWorkspaceResolutionState {
    /** Resolving workspaces finished successfully. */
    COMPLETED = 'COMPLETED',
    /** An error occured while resolving workspaces. Will be retried eventually. */
    ERRORED = 'ERRORED',
    /** A fatal error occured while resolving workspaces. No retries will be made. */
    FAILED = 'FAILED',
    /** Currently resolving workspaces. */
    PROCESSING = 'PROCESSING',
    /** Not yet started resolving. Will be picked up by a worker eventually. */
    QUEUED = 'QUEUED',
}

/** The states a workspace can be in. */
export enum BatchSpecWorkspaceState {
    /** Execution has been canceled. */
    CANCELED = 'CANCELED',
    /** Execution is being canceled. This is an async process. */
    CANCELING = 'CANCELING',
    /** Execution finished successfully. */
    COMPLETED = 'COMPLETED',
    /** A fatal error occured while executing. No retries will be made. */
    FAILED = 'FAILED',
    /** The workspace is not yet enqueued for execution. */
    PENDING = 'PENDING',
    /** Currently executing on the workspace. */
    PROCESSING = 'PROCESSING',
    /** Not yet started executing. Will be picked up by a worker eventually. */
    QUEUED = 'QUEUED',
    /**
     * The workspace will not be enqueued for execution, because either the
     * workspace is unsupported/ignored or has 0 steps to execute.
     */
    SKIPPED = 'SKIPPED',
}

/** All valid states a bulk operation can be in. */
export enum BulkOperationState {
    /** No operations are still running and all of them finished without error. */
    COMPLETED = 'COMPLETED',
    /** No operations are still running and at least one of them finished with an error. */
    FAILED = 'FAILED',
    /** The bulk operation is still processing on some changesets. */
    PROCESSING = 'PROCESSING',
}

/** The available types of jobs that can be run over a batch change. */
export enum BulkOperationType {
    /** Bulk close changesets. */
    CLOSE = 'CLOSE',
    /** Bulk post comments over all involved changesets. */
    COMMENT = 'COMMENT',
    /** Bulk detach changesets from a batch change. */
    DETACH = 'DETACH',
    /** Bulk merge changesets. */
    MERGE = 'MERGE',
    /** Bulk publish changesets. */
    PUBLISH = 'PUBLISH',
    /** Bulk reenqueue failed changesets. */
    REENQUEUE = 'REENQUEUE',
}

/** The state of checks (e.g., for continuous integration) on a changeset. */
export enum ChangesetCheckState {
    FAILED = 'FAILED',
    PASSED = 'PASSED',
    PENDING = 'PENDING',
}

/** The state of a changeset on the code host on which it's hosted. */
export enum ChangesetExternalState {
    CLOSED = 'CLOSED',
    DELETED = 'DELETED',
    DRAFT = 'DRAFT',
    MERGED = 'MERGED',
    OPEN = 'OPEN',
    READONLY = 'READONLY',
}

/** The publication state of a changeset on Sourcegraph */
export enum ChangesetPublicationState {
    /** The changeset has been created on the code host. */
    PUBLISHED = 'PUBLISHED',
    /** The changeset has not yet been created on the code host. */
    UNPUBLISHED = 'UNPUBLISHED',
}

/** The reconciler state of a changeset on Sourcegraph */
export enum ChangesetReconcilerState {
    /** The changeset is not enqueued for processing. */
    COMPLETED = 'COMPLETED',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset and will retry it for a number of retries.
     */
    ERRORED = 'ERRORED',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset that can't be fixed by retrying.
     */
    FAILED = 'FAILED',
    /**
     * The changeset reconciler is currently computing the delta between the
     * If a delta exists, the reconciler tries to update the state of the
     * changeset on the code host and on Sourcegraph to the desired state.
     */
    PROCESSING = 'PROCESSING',
    /** The changeset is enqueued for the reconciler to process it. */
    QUEUED = 'QUEUED',
    /** The changeset is scheduled, and will be enqueued when its turn comes in Sourcegraph's rollout window. */
    SCHEDULED = 'SCHEDULED',
}

/** The review state of a changeset. */
export enum ChangesetReviewState {
    APPROVED = 'APPROVED',
    CHANGES_REQUESTED = 'CHANGES_REQUESTED',
    COMMENTED = 'COMMENTED',
    DISMISSED = 'DISMISSED',
    PENDING = 'PENDING',
}

/** This enum declares all operations supported by the reconciler. */
export enum ChangesetSpecOperation {
    /** The changeset is kept in the batch change, but it's marked as archived. */
    ARCHIVE = 'ARCHIVE',
    /** Close the changeset on the codehost. */
    CLOSE = 'CLOSE',
    /** The changeset is removed from some of the associated batch changes. */
    DETACH = 'DETACH',
    /** Import an existing changeset from the code host with the ExternalID from the spec. */
    IMPORT = 'IMPORT',
    /** Publish a changeset to the codehost. */
    PUBLISH = 'PUBLISH',
    /** Publish a changeset to the codehost as a draft changeset. (Only on supported code hosts). */
    PUBLISH_DRAFT = 'PUBLISH_DRAFT',
    /** Push a new commit to the code host. */
    PUSH = 'PUSH',
    /** The changeset is re-added to the batch change. */
    REATTACH = 'REATTACH',
    /** Reopen the changeset on the codehost. */
    REOPEN = 'REOPEN',
    /** Internal operation to get around slow code host updates. */
    SLEEP = 'SLEEP',
    /** Sync the changeset with the current state on the codehost. */
    SYNC = 'SYNC',
    /** Move the existing changeset out of being a draft. */
    UNDRAFT = 'UNDRAFT',
    /**
     * Update the existing changeset on the codehost. This is purely the changeset resource on the code host,
     * not the git commit. For updates to the commit, see 'PUSH'.
     */
    UPDATE = 'UPDATE',
}

/**
 * A ChangesetSpecPublicationStateInput is a tuple containing a changeset spec ID
 * and its desired UI publication state.
 */
export interface ChangesetSpecPublicationStateInput {
    /** The changeset spec ID. */
    changesetSpec: Scalars['ID']
    /** The desired publication state. */
    publicationState: Scalars['PublishedValue']
}

/** The type of the changeset spec. */
export enum ChangesetSpecType {
    /** References a branch and a patch to be applied to create the changeset from. */
    BRANCH = 'BRANCH',
    /** References an existing changeset on a code host to be imported. */
    EXISTING = 'EXISTING',
}

/** The visual state a changeset is currently in. */
export enum ChangesetState {
    /** The changeset is published, not being reconciled and closed on the code host. */
    CLOSED = 'CLOSED',
    /** The changeset is published, not being reconciled and has been deleted on the code host. */
    DELETED = 'DELETED',
    /** The changeset is published, not being reconciled and in draft state on the code host. */
    DRAFT = 'DRAFT',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset that can't be fixed by retrying.
     */
    FAILED = 'FAILED',
    /** The changeset is published, not being reconciled and merged on the code host. */
    MERGED = 'MERGED',
    /** The changeset is published, not being reconciled and open on the code host. */
    OPEN = 'OPEN',
    /**
     * The changeset reconciler is currently computing the delta between the
     * If a delta exists, the reconciler tries to update the state of the
     * changeset on the code host and on Sourcegraph to the desired state.
     */
    PROCESSING = 'PROCESSING',
    /**
     * The changeset is published, and is now read-only, most likely due to the
     * repository being archived.
     */
    READONLY = 'READONLY',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset and will retry it for a number of retries.
     */
    RETRYING = 'RETRYING',
    /** The changeset is scheduled, and will be enqueued when its turn comes in Sourcegraph's rollout window. */
    SCHEDULED = 'SCHEDULED',
    /** The changeset has not been marked as to be published. */
    UNPUBLISHED = 'UNPUBLISHED',
}

/** The clone status of a repository. */
export enum CloneStatus {
    CLONED = 'CLONED',
    CLONING = 'CLONING',
    NOT_CLONED = 'NOT_CLONED',
}

/**
 * DEPRECATED: This type was renamed to SettingsEdit.
 * NOTE: GraphQL does not support @deprecated directives on INPUT_FIELD_DEFINITION (input fields).
 */
export interface ConfigurationEdit {
    /** DEPRECATED */
    keyPath: Array<KeyPathSegment>
    /** DEPRECATED */
    value?: InputMaybe<Scalars['JSONValue']>
    /** DEPRECATED */
    valueIsJSONCEncodedString?: InputMaybe<Scalars['Boolean']>
}

/** CreateFileBlockInput contains the information necessary to create a file block. */
export interface CreateFileBlockInput {
    /** Path within the repository, e.g. "client/web/file.tsx". */
    filePath: Scalars['String']
    /** An optional line range. If omitted, we display the entire file. */
    lineRange?: InputMaybe<CreateFileBlockLineRangeInput>
    /** Name of the repository, e.g. "github.com/sourcegraph/sourcegraph". */
    repositoryName: Scalars['String']
    /**
     * An optional revision, e.g. "pr/feature-1", "a9505a2947d3df53558e8c88ff8bcef390fc4e3e".
     * If omitted, we use the latest revision (HEAD).
     */
    revision?: InputMaybe<Scalars['String']>
}

/** Input to create a line range for a file block. */
export interface CreateFileBlockLineRangeInput {
    /** The last line to fetch (0-indexed, exclusive). */
    endLine: Scalars['Int']
    /** The first line to fetch (0-indexed, inclusive). */
    startLine: Scalars['Int']
}

/** Input object for creating a new dashboard. */
export interface CreateInsightsDashboardInput {
    /** Permissions to grant to the dashboard. */
    grants: InsightsPermissionGrantsInput
    /** Dashboard title. */
    title: Scalars['String']
}

/**
 * GraphQL does not accept union types as inputs, so we have to use
 * all possible optional inputs with an enum to select the actual block input we want to use.
 */
export interface CreateNotebookBlockInput {
    /** File input. */
    fileInput?: InputMaybe<CreateFileBlockInput>
    /** ID of the block. */
    id: Scalars['String']
    /** Markdown input. */
    markdownInput?: InputMaybe<Scalars['String']>
    /** Query input. */
    queryInput?: InputMaybe<Scalars['String']>
    /** Symbol input. */
    symbolInput?: InputMaybe<CreateSymbolBlockInput>
    /** Block type. */
    type: NotebookBlockType
}

/** CreateSymbolBlockInput contains the information necessary to create a symbol block. */
export interface CreateSymbolBlockInput {
    /** Path within the repository, e.g. "client/web/file.tsx". */
    filePath: Scalars['String']
    /** Number of lines to show before and after the matched symbol line. */
    lineContext: Scalars['Int']
    /** Name of the repository, e.g. "github.com/sourcegraph/sourcegraph". */
    repositoryName: Scalars['String']
    /**
     * An optional revision, e.g. "pr/feature-1", "a9505a2947d3df53558e8c88ff8bcef390fc4e3e".
     * If omitted, we use the latest revision (HEAD).
     */
    revision?: InputMaybe<Scalars['String']>
    /** Name of the symbol container. */
    symbolContainerName: Scalars['String']
    /** The symbol kind. */
    symbolKind: SymbolKind
    /** The symbol name. */
    symbolName: Scalars['String']
}

/** Represents the severity level of a diagnostic. */
export enum DiagnosticSeverity {
    ERROR = 'ERROR',
    HINT = 'HINT',
    INFORMATION = 'INFORMATION',
    WARNING = 'WARNING',
}

/** The type of content in a hunk line. */
export enum DiffHunkLineType {
    /** Added line. */
    ADDED = 'ADDED',
    /** Deleted line. */
    DELETED = 'DELETED',
    /** Unchanged line. */
    UNCHANGED = 'UNCHANGED',
}

/** A description of a user event. */
export interface Event {
    /** The additional argument information. */
    argument?: InputMaybe<Scalars['String']>
    /**
     * An optional cohort ID to identify the user as part of a specific A/B test.
     * The cohort ID is expected to be a date in the form YYYY-MM-DD
     */
    cohortID?: InputMaybe<Scalars['String']>
    /** Device ID used for Amplitude analytics. Used on Sourcegraph Cloud only. */
    deviceID?: InputMaybe<Scalars['String']>
    /** Device session ID to identify the user's session for analytics. */
    deviceSessionID?: InputMaybe<Scalars['String']>
    /** The name of the event. */
    event: Scalars['String']
    /**
     * Event ID used to deduplicate events that occur simultaneously in Amplitude analytics.
     * See https://developers.amplitude.com/docs/http-api-v2#optional-keys. Used on Sourcegraph Cloud only.
     */
    eventID?: InputMaybe<Scalars['Int']>
    /** The first sourcegraph URL visited by the user, stored in a browser cookie. */
    firstSourceURL?: InputMaybe<Scalars['String']>
    /**
     * Insert ID used to deduplicate events that re-occur in the event of retries or
     * backfills in Amplitude analytics. See https://developers.amplitude.com/docs/http-api-v2#optional-keys.
     * Used on Sourcegraph Cloud only.
     */
    insertID?: InputMaybe<Scalars['String']>
    /** The last sourcegraph URL visited by the user, stored in a browser cookie. */
    lastSourceURL?: InputMaybe<Scalars['String']>
    /** The original referrer for a user */
    originalReferrer?: InputMaybe<Scalars['String']>
    /**
     * Public argument information. PRIVACY: Do NOT include any potentially private information in this field.
     * These properties get sent to our analytics tools for Cloud, so must not include private information,
     * such as search queries or repository names.
     */
    publicArgument?: InputMaybe<Scalars['String']>
    /**
     * An optional referrer parameter for the user's current session.
     * Only captured and stored on Sourcegraph Cloud.
     */
    referrer?: InputMaybe<Scalars['String']>
    /** The sessions first url for a user */
    sessionFirstURL?: InputMaybe<Scalars['String']>
    /** The session referrer for a user */
    sessionReferrer?: InputMaybe<Scalars['String']>
    /** The source of the event. */
    source: EventSource
    /** The URL when the event was logged. */
    url: Scalars['String']
    /** The randomly generated unique user ID stored in a browser cookie. */
    userCookieID: Scalars['String']
}

/** The product sources where events can come from. */
export enum EventSource {
    BACKEND = 'BACKEND',
    CODEHOSTINTEGRATION = 'CODEHOSTINTEGRATION',
    IDEEXTENSION = 'IDEEXTENSION',
    STATICWEB = 'STATICWEB',
    WEB = 'WEB',
}

/** Supported status of monitor events. */
export enum EventStatus {
    ERROR = 'ERROR',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
}

/** The compatibility of the executor with the sourcegraph instance. */
export enum ExecutorCompatibility {
    /** Executor version is more than one version behind the Sourcegraph instance. */
    OUTDATED = 'OUTDATED',
    /** Executor is up-to-date with the Sourcegraph instance. */
    UP_TO_DATE = 'UP_TO_DATE',
    /** Executor version is more than one version ahead of the Sourcegraph instance. */
    VERSION_AHEAD = 'VERSION_AHEAD',
}

/** Enum of the possible scopes for executor secrets. */
export enum ExecutorSecretScope {
    /** The secret is meant to be used with Batch Changes execution. */
    BATCHES = 'BATCHES',
    /** The secret is meant to be used with Auto-indexing. */
    CODEINTEL = 'CODEINTEL',
}

/** A specific kind of external service. */
export enum ExternalServiceKind {
    AWSCODECOMMIT = 'AWSCODECOMMIT',
    AZUREDEVOPS = 'AZUREDEVOPS',
    BITBUCKETCLOUD = 'BITBUCKETCLOUD',
    BITBUCKETSERVER = 'BITBUCKETSERVER',
    GERRIT = 'GERRIT',
    GITHUB = 'GITHUB',
    GITLAB = 'GITLAB',
    GITOLITE = 'GITOLITE',
    GOMODULES = 'GOMODULES',
    JVMPACKAGES = 'JVMPACKAGES',
    NPMPACKAGES = 'NPMPACKAGES',
    OTHER = 'OTHER',
    PAGURE = 'PAGURE',
    PERFORCE = 'PERFORCE',
    PHABRICATOR = 'PHABRICATOR',
    PYTHONPACKAGES = 'PYTHONPACKAGES',
    RUBYPACKAGES = 'RUBYPACKAGES',
    RUSTPACKAGES = 'RUSTPACKAGES',
}

/** The possible states of an external service sync job. */
export enum ExternalServiceSyncJobState {
    /** Sync job has been canceled. */
    CANCELED = 'CANCELED',
    /** Sync job is being canceled. */
    CANCELING = 'CANCELING',
    /** Sync finished successfully. */
    COMPLETED = 'COMPLETED',
    /** An error occurred while syncing. Will be retried eventually. */
    ERRORED = 'ERRORED',
    /** A fatal error occurred while syncing. No retries will be made. */
    FAILED = 'FAILED',
    /** Currently syncing. */
    PROCESSING = 'PROCESSING',
    /** Not yet started. Will be picked up by a worker eventually. */
    QUEUED = 'QUEUED',
}

/** Additional options when performing a permissions sync. */
export interface FetchPermissionsOptions {
    /**
     * Indicate that any caches added for optimization encountered during this permissions
     * sync should be invalidated.
     */
    invalidateCaches?: InputMaybe<Scalars['Boolean']>
}

/** All possible types of Git objects. */
export enum GitObjectType {
    /** A Git blob object. */
    GIT_BLOB = 'GIT_BLOB',
    /** A Git commit object. */
    GIT_COMMIT = 'GIT_COMMIT',
    /** A Git tag object. */
    GIT_TAG = 'GIT_TAG',
    /** A Git tree object. */
    GIT_TREE = 'GIT_TREE',
    /** A Git object of unknown type. */
    GIT_UNKNOWN = 'GIT_UNKNOWN',
}

/** Ordering options for Git refs. */
export enum GitRefOrder {
    /** By the authored or committed at date, whichever is more recent. */
    AUTHORED_OR_COMMITTED_AT = 'AUTHORED_OR_COMMITTED_AT',
}

/** All possible types of Git refs. */
export enum GitRefType {
    /** A Git branch (in refs/heads/). */
    GIT_BRANCH = 'GIT_BRANCH',
    /** A Git ref that is neither a branch nor tag. */
    GIT_REF_OTHER = 'GIT_REF_OTHER',
    /** A Git tag (in refs/tags/). */
    GIT_TAG = 'GIT_TAG',
}

/** Fields that can be grouped on for compute powered insights. */
export enum GroupByField {
    AUTHOR = 'AUTHOR',
    DATE = 'DATE',
    LANG = 'LANG',
    PATH = 'PATH',
    REPO = 'REPO',
}

/** Input for a happiness feedback submission. */
export interface HappinessFeedbackSubmissionInput {
    /** The path that the happiness feedback will be submitted from. */
    currentPath?: InputMaybe<Scalars['String']>
    /** The feedback text from the user. */
    feedback?: InputMaybe<Scalars['String']>
}

/** A specific highlighted line range to fetch. */
export interface HighlightLineRange {
    /**
     * The last line to fetch (0-indexed, inclusive). Values outside the bounds of the file will
     * automatically be clamped within the valid range.
     */
    endLine: Scalars['Int']
    /**
     * The first line to fetch (0-indexed, inclusive). Values outside the bounds of the file will
     * automatically be clamped within the valid range.
     */
    startLine: Scalars['Int']
}

/** The format and highlighting to use when requesting highlighting information for a file. */
export enum HighlightResponseFormat {
    /** HTML formatted file content with syntax highlighting. */
    HTML_HIGHLIGHT = 'HTML_HIGHLIGHT',
    /** HTML formatted file content without syntax highlighting. */
    HTML_PLAINTEXT = 'HTML_PLAINTEXT',
    /** SCIP highlighting information as JSON. */
    JSON_SCIP = 'JSON_SCIP',
}

/** Denotes the confidence in the correctness of the proposed index target. */
export enum InferedPreciseSupportLevel {
    /**
     * An auto-indexing job configuration was able to be infered for this
     * directory that has a high likelyhood of being complete enough to result
     * in an LSIF index.
     */
    INDEX_JOB_INFERED = 'INDEX_JOB_INFERED',
    /**
     * The language is known to have an LSIF indexer associated with it
     * but this may not be the directory from which it should be invoked.
     * Relevant build tool configuration may be available at a parent directory.
     */
    LANGUAGE_SUPPORTED = 'LANGUAGE_SUPPORTED',
    /**
     * Relevant build tool configuration files were located that indicate
     * a good possibility of this directory being where an LSIF indexer
     * could be invoked, however we have or can not infer a potentially complete
     * auto indexing job configuration.
     */
    PROJECT_STRUCTURE_SUPPORTED = 'PROJECT_STRUCTURE_SUPPORTED',
}

/** Possible queue states */
export enum InsightQueueItemState {
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    NEW = 'NEW',
    PROCESSING = 'PROCESSING',
    QUEUED = 'QUEUED',
    UNKNOWN = 'UNKNOWN',
}

/** Input object for permissions to grant. */
export interface InsightsPermissionGrantsInput {
    /** Set global to true to grant global permission. */
    global?: InputMaybe<Scalars['Boolean']>
    /** Organizations to grant permissions to. */
    organizations?: InputMaybe<Array<Scalars['ID']>>
    /** Specific users to grant permissions to. */
    users?: InputMaybe<Array<Scalars['ID']>>
}

/** Input for the default values for filters and aggregates for an insight. */
export interface InsightViewControlsInput {
    /** Input for the default filters for an insight. */
    filters: InsightViewFiltersInput
    /** Input for series' sort order. */
    seriesDisplayOptions: SeriesDisplayOptionsInput
}

/** Input for the default values by which the insight is filtered. */
export interface InsightViewFiltersInput {
    /** A regex string for which to exclude repositories in a filter. */
    excludeRepoRegex?: InputMaybe<Scalars['String']>
    /** A regex string for which to include repositories in a filter. */
    includeRepoRegex?: InputMaybe<Scalars['String']>
    /** A list of query based search contexts to include in the filters for the view. */
    searchContexts?: InputMaybe<Array<Scalars['String']>>
}

/**
 * A segment of a key path that locates a nested JSON value in a root JSON value. Exactly one field in each
 * KeyPathSegment must be non-null.
 * For example, in {"a": [0, {"b": 3}]}, the value 3 is located at the key path ["a", 1, "b"].
 */
export interface KeyPathSegment {
    /** The index of the array at this location to descend into. */
    index?: InputMaybe<Scalars['Int']>
    /** The name of the property in the object at this location to descend into. */
    property?: InputMaybe<Scalars['String']>
}

/** Options for a line chart data series */
export interface LineChartDataSeriesOptionsInput {
    /** The label for the data series. */
    label?: InputMaybe<Scalars['String']>
    /** The line color for the data series. */
    lineColor?: InputMaybe<Scalars['String']>
}

/** Options for a line chart */
export interface LineChartOptionsInput {
    /** The chart title. */
    title?: InputMaybe<Scalars['String']>
}

/** Input for a line chart search insight data series. */
export interface LineChartSearchInsightDataSeriesInput {
    /** Whether or not to generate the timeseries results from the query capture groups. Defaults to false if not provided. */
    generatedFromCaptureGroups?: InputMaybe<Scalars['Boolean']>
    /** The field to group results by. (For compute powered insights only.) This field is experimental and should be considered unstable in the API. */
    groupBy?: InputMaybe<GroupByField>
    /** Options for this line chart data series. */
    options: LineChartDataSeriesOptionsInput
    /** The query string. */
    query: Scalars['String']
    /**
     * The scope of repositories. The repository scope can be provided at the LineChartSearchInsightInput level.
     * If scope is provided here will take priority of any other scope provide at a higher level in the input.
     */
    repositoryScope?: InputMaybe<RepositoryScopeInput>
    /** Unique ID for the series. Omit this field if it's a new series. */
    seriesId?: InputMaybe<Scalars['String']>
    /**
     * The scope of time. This time scope can also be provided at the LineChartSearchInsightInput level.
     * If the scope is provided here it will take priority over any other scope provided at a higher level in the input.
     */
    timeScope?: InputMaybe<TimeScopeInput>
}

/** Input for a line chart search insight. */
export interface LineChartSearchInsightInput {
    /** The dashboard IDs to associate this insight with once created. */
    dashboards?: InputMaybe<Array<Scalars['ID']>>
    /** The list of data series to create (or add) to this insight. */
    dataSeries: Array<LineChartSearchInsightDataSeriesInput>
    /** The options for this line chart. */
    options: LineChartOptionsInput
    /** The scope of repositories for the insight. If provided here it will apply to all series unless overwritten. */
    repositoryScope?: InputMaybe<RepositoryScopeInput>
    /** The scope of time for the insight view. If provided here it will apply to all series unless overwritten. */
    timeScope?: InputMaybe<TimeScopeInput>
    /** The default values for filters and aggregates for this line chart. */
    viewControls?: InputMaybe<InsightViewControlsInput>
}

/** The state an LSIF index can be in. */
export enum LSIFIndexState {
    /** This index was processed successfully. */
    COMPLETED = 'COMPLETED',
    /** This index failed to be processed. */
    ERRORED = 'ERRORED',
    /** This index is being processed. */
    PROCESSING = 'PROCESSING',
    /** This index is queued to be processed later. */
    QUEUED = 'QUEUED',
}

/** The state an LSIF upload can be in. */
export enum LSIFUploadState {
    /** This upload was processed successfully. */
    COMPLETED = 'COMPLETED',
    /**
     * This upload is deleted and its metadata is reconstructed from existing
     * audit log entries.
     */
    DELETED = 'DELETED',
    /**
     * This upload is queued for deletion. This upload was previously in the
     * COMPLETED state and evicted, replaced by a newer upload, or deleted by
     * a user. This upload is able to answer code intelligence queries until
     * the commit graph of the upload's repository is next calculated, at which
     * point the upload will become unreachable.
     */
    DELETING = 'DELETING',
    /** This upload failed to be processed. */
    ERRORED = 'ERRORED',
    /** This upload is being processed. */
    PROCESSING = 'PROCESSING',
    /** This upload is queued to be processed later. */
    QUEUED = 'QUEUED',
    /** This upload is currently being transferred to Sourcegraph. */
    UPLOADING = 'UPLOADING',
}

/** Describes options for rendering Markdown. */
export interface MarkdownOptions {
    /** A dummy null value (empty input types are not allowed yet). */
    alwaysNil?: InputMaybe<Scalars['String']>
}

/** The input required to create an action. */
export interface MonitorActionInput {
    /** An email action. */
    email?: InputMaybe<MonitorEmailInput>
    /** A Slack webhook action. */
    slackWebhook?: InputMaybe<MonitorSlackWebhookInput>
    /** A webhook action. */
    webhook?: InputMaybe<MonitorWebhookInput>
}

/** The input required to edit an action. */
export interface MonitorEditActionInput {
    /** An email action. */
    email?: InputMaybe<MonitorEditEmailInput>
    /** A Slack webhook action. */
    slackWebhook?: InputMaybe<MonitorEditSlackWebhookInput>
    /** A webhook action. */
    webhook?: InputMaybe<MonitorEditWebhookInput>
}

/** The input required to edit an email action. */
export interface MonitorEditEmailInput {
    /**
     * The id of an email action. If unset, this will
     * be treated as a new email action and be created
     * rather than updated.
     */
    id?: InputMaybe<Scalars['ID']>
    /** The desired state after the update. */
    update: MonitorEmailInput
}

/** The input required to edit a code monitor. */
export interface MonitorEditInput {
    /** The id of the monitor. */
    id: Scalars['ID']
    /** The desired state after the udpate. */
    update: MonitorInput
}

/** The input required to edit a Slack webhook action. */
export interface MonitorEditSlackWebhookInput {
    /**
     * The id of a Slack webhook action. If unset, this will
     * be treated as a new Slack webhook action and be created
     * rather than updated.
     */
    id?: InputMaybe<Scalars['ID']>
    /** The desired state after the update. */
    update: MonitorSlackWebhookInput
}

/** The input required to edit a trigger. */
export interface MonitorEditTriggerInput {
    /** The id of the Trigger. */
    id: Scalars['ID']
    /** The desired state after the udpate. */
    update: MonitorTriggerInput
}

/** The input required to edit a webhook action. */
export interface MonitorEditWebhookInput {
    /**
     * The id of a webhook action. If unset, this will
     * be treated as a new webhook action and be created
     * rather than updated.
     */
    id?: InputMaybe<Scalars['ID']>
    /** The desired state after the update. */
    update: MonitorWebhookInput
}

/** The input required to create an email action. */
export interface MonitorEmailInput {
    /** Whether the email action is enabled or not. */
    enabled: Scalars['Boolean']
    /** Use header to automatically approve the message in a read-only or moderated mailing list. */
    header: Scalars['String']
    /** Whether to include the result contents in the email message */
    includeResults: Scalars['Boolean']
    /** The priority of the email. */
    priority: MonitorEmailPriority
    /** A list of users or orgs which will receive the email. */
    recipients: Array<Scalars['ID']>
}

/** The priority of an email action. */
export enum MonitorEmailPriority {
    CRITICAL = 'CRITICAL',
    NORMAL = 'NORMAL',
}

/** The input required to create a code monitor. */
export interface MonitorInput {
    /** A meaningful description of the code monitor. */
    description: Scalars['String']
    /** Whether the code monitor is enabled or not. */
    enabled: Scalars['Boolean']
    /**
     * The namespace represents the owner of the code monitor.
     * Owners can either be users or organizations.
     */
    namespace: Scalars['ID']
}

/** The input required to create a Slack webhook action. */
export interface MonitorSlackWebhookInput {
    /** Whether the Slack webhook action is enabled or not. */
    enabled: Scalars['Boolean']
    /** Whether to include the result contents in Slack notification message. */
    includeResults: Scalars['Boolean']
    /** The URL that will receive a payload when the action is triggered. */
    url: Scalars['String']
}

/** The input required to create a trigger. */
export interface MonitorTriggerInput {
    /** The query string. */
    query: Scalars['String']
}

/** The input required to create a webhook action. */
export interface MonitorWebhookInput {
    /** Whether the webhook action is enabled or not. */
    enabled: Scalars['Boolean']
    /** Whether to include the result contents in webhook payload. */
    includeResults: Scalars['Boolean']
    /** The URL that will receive a payload when the action is triggered. */
    url: Scalars['String']
}

/** An enum to describe the reasons why search aggregations are not available */
export enum NotAvailableReasonType {
    INVALID_AGGREGATION_MODE_FOR_QUERY = 'INVALID_AGGREGATION_MODE_FOR_QUERY',
    INVALID_QUERY = 'INVALID_QUERY',
    OTHER_ERROR = 'OTHER_ERROR',
    TIMEOUT_EXTENSION_AVAILABLE = 'TIMEOUT_EXTENSION_AVAILABLE',
    TIMEOUT_NO_EXTENSION_AVAILABLE = 'TIMEOUT_NO_EXTENSION_AVAILABLE',
}

/** Enum of possible block types. */
export enum NotebookBlockType {
    FILE = 'FILE',
    MARKDOWN = 'MARKDOWN',
    QUERY = 'QUERY',
    SYMBOL = 'SYMBOL',
}

/** Input for a new notebook. */
export interface NotebookInput {
    /** Array of notebook blocks. */
    blocks: Array<CreateNotebookBlockInput>
    /**
     * Notebook namespace (user or org). Controls the visibility of the notebook
     * and who can edit the notebook. Only the notebook creator can update the namespace.
     */
    namespace: Scalars['ID']
    /**
     * Public property controls the visibility of the notebook. A public notebook is available to
     * any user on the instance. Private notebooks are only available to their creators.
     */
    public: Scalars['Boolean']
    /** The title of the notebook. */
    title: Scalars['String']
}

/** NotebooksOrderBy enumerates the ways notebooks can be ordered. */
export enum NotebooksOrderBy {
    NOTEBOOK_CREATED_AT = 'NOTEBOOK_CREATED_AT',
    NOTEBOOK_STAR_COUNT = 'NOTEBOOK_STAR_COUNT',
    NOTEBOOK_UPDATED_AT = 'NOTEBOOK_UPDATED_AT',
}

/** The recipient's possible responses to an invitation to join an organization as a member. */
export enum OrganizationInvitationResponseType {
    /** The invitation was accepted by the recipient. */
    ACCEPT = 'ACCEPT',
    /** The invitation was rejected by the recipient. */
    REJECT = 'REJECT',
}

/** Input for the createOutboundWebhook mutation. */
export interface OutboundWebhookCreateInput {
    /**
     * The event types the outbound webhook will receive.
     *
     * At least one event type must be provided.
     */
    eventTypes: Array<OutboundWebhookScopedEventTypeInput>
    /** The secret shared with the outbound webhook. */
    secret: Scalars['String']
    /** The outbound webhook URL. */
    url: Scalars['String']
}

/** Event type input for the outbound webhook mutations. */
export interface OutboundWebhookScopedEventTypeInput {
    /**
     * The event type, which must match a key returned from
     * outboundWebhookEventTypes.
     */
    eventType: Scalars['String']
    /**
     * An optional scope for the event type.
     *
     * Currently unused.
     */
    scope?: InputMaybe<Scalars['String']>
}

/** Input for the updateOutboundWebhook mutation. */
export interface OutboundWebhookUpdateInput {
    /**
     * The event types the outbound webhook will receive. This list replaces the
     * event types previously registered on the webhook.
     *
     * At least one event type must be provided.
     */
    eventTypes: Array<OutboundWebhookScopedEventTypeInput>
    /** The outbound webhook URL. */
    url: Scalars['String']
}

/** The only way we can recognize ownership at this point is through CODEOWNERS file entry. */
export enum OwnershipReasonType {
    CODEOWNERS_FILE_ENTRY = 'CODEOWNERS_FILE_ENTRY',
}

/**
 * A namespace represents a distinct context within which permission policies
 * are defined and enforced.
 */
export enum PermissionNamespace {
    /** This represents the Batch Changes namespace. */
    BATCH_CHANGES = 'BATCH_CHANGES',
}

/** Status types of permissions providers. */
export enum PermissionsProviderStatus {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

/** Status types of permissions sync jobs. */
export enum PermissionsSyncJobStatus {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

/** Options for a pie chart */
export interface PieChartOptionsInput {
    /**
     * The threshold for which groups fall into the "other category". Only categories with a percentage greater than
     * this value will be separately rendered.
     */
    otherThreshold: Scalars['Float']
    /** The title for the pie chart. */
    title: Scalars['String']
}

/** Input for a pie chart search insight */
export interface PieChartSearchInsightInput {
    /** The dashboard IDs to associate this insight with once created. */
    dashboards?: InputMaybe<Array<Scalars['ID']>>
    /** Options for this pie chart. */
    presentationOptions: PieChartOptionsInput
    /** The query string. */
    query: Scalars['String']
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
}

/** Possible states for PreciseIndexes. */
export enum PreciseIndexState {
    COMPLETED = 'COMPLETED',
    DELETED = 'DELETED',
    DELETING = 'DELETING',
    INDEXING = 'INDEXING',
    INDEXING_COMPLETED = 'INDEXING_COMPLETED',
    INDEXING_ERRORED = 'INDEXING_ERRORED',
    PROCESSING = 'PROCESSING',
    PROCESSING_ERRORED = 'PROCESSING_ERRORED',
    QUEUED_FOR_INDEXING = 'QUEUED_FOR_INDEXING',
    QUEUED_FOR_PROCESSING = 'QUEUED_FOR_PROCESSING',
    UPLOADING_INDEX = 'UPLOADING_INDEX',
}

/** Ownership level of the recommended precise code-intel indexer. */
export enum PreciseSupportLevel {
    /** When the recommended indexer is maintained by us. */
    NATIVE = 'NATIVE',
    /**
     * When the recommended indexer is maintained by a third-party
     * but is recommended over a native indexer, where one exists.
     */
    THIRD_PARTY = 'THIRD_PARTY',
    /** When there is no known indexer. */
    UNKNOWN = 'UNKNOWN',
}

/**
 * An input type that describes a product license to be generated and signed.
 * FOR INTERNAL USE ONLY.
 */
export interface ProductLicenseInput {
    /** The expiration date of this product license, expressed as the number of seconds since the epoch. */
    expiresAt: Scalars['Int']
    /** The tags that indicate which features are activated by this license. */
    tags: Array<Scalars['String']>
    /** The number of users for which this product subscription is valid. */
    userCount: Scalars['Int']
}

/** Input object for adding insight view to dashboard. */
export interface RemoveInsightViewFromDashboardInput {
    /** ID of the dashboard. */
    dashboardId: Scalars['ID']
    /** ID of the insight view to remove from the dashboard */
    insightViewId: Scalars['ID']
}

/** RepositoryOrderBy enumerates the ways a repositories list can be ordered. */
export enum RepositoryOrderBy {
    REPO_CREATED_AT = 'REPO_CREATED_AT',
    /** deprecated (use the equivalent REPOSITORY_CREATED_AT) */
    REPOSITORY_CREATED_AT = 'REPOSITORY_CREATED_AT',
    REPOSITORY_NAME = 'REPOSITORY_NAME',
    SIZE = 'SIZE',
}

/** Different repository permission levels. */
export enum RepositoryPermission {
    READ = 'READ',
}

/** A custom repository scope for an insight data series. */
export interface RepositoryScopeInput {
    /** The list of repositories included in this scope. */
    repositories: Array<Scalars['String']>
    /** A search query to select repositories for this scope. */
    repositoryCriteria?: InputMaybe<Scalars['String']>
}

/** Input for saving a new view on an insight. */
export interface SaveInsightAsNewViewInput {
    /** The dashboard ID to associate this insight with once created. */
    dashboard?: InputMaybe<Scalars['ID']>
    /** The insight view ID we are creating a new view from. */
    insightViewId: Scalars['ID']
    /** The options for this line chart. */
    options: LineChartOptionsInput
    /** The default values for filters and aggregates for this line chart. */
    viewControls?: InputMaybe<InsightViewControlsInput>
}

/** Supported aggregation modes for search aggregations */
export enum SearchAggregationMode {
    AUTHOR = 'AUTHOR',
    CAPTURE_GROUP = 'CAPTURE_GROUP',
    PATH = 'PATH',
    REPO = 'REPO',
}

/**
 * Tiered list of types of search-based support for a language. This may be expanded as different
 * indexing methods are introduced.
 */
export enum SearchBasedSupportLevel {
    /** Universal-ctags is used for indexing this language. */
    BASIC = 'BASIC',
    /** The language has no configured search-based code-intel support. */
    UNSUPPORTED = 'UNSUPPORTED',
}

/** Input for editing an existing search context. */
export interface SearchContextEditInput {
    /** Search context description. */
    description: Scalars['String']
    /**
     * Search context name. Not the same as the search context spec. Search context namespace and search context name
     * are used to construct the fully-qualified search context spec.
     * Example mappings from search context spec to search context name: global -> global, @user -> user, @org -> org,
     * @user/ctx1 -> ctx1, @org/ctxs/ctx -> ctxs/ctx.
     */
    name: Scalars['String']
    /**
     * Public property controls the visibility of the search context. Public search context is available to
     * any user on the instance. If a public search context contains private repositories, those are filtered out
     * for unauthorized users. Private search contexts are only available to their owners. Private user search context
     * is available only to the user, private org search context is available only to the members of the org, and private
     * instance-level search contexts are available only to site-admins.
     */
    public: Scalars['Boolean']
    /**
     * Sourcegraph search query that defines the search context.
     * e.g. "r:^github\.com/org (rev:bar or rev:HEAD) file:^sub/dir"
     */
    query: Scalars['String']
}

/** Input for a new search context. */
export interface SearchContextInput {
    /** Search context description. */
    description: Scalars['String']
    /**
     * Search context name. Not the same as the search context spec. Search context namespace and search context name
     * are used to construct the fully-qualified search context spec.
     * Example mappings from search context spec to search context name: global -> global, @user -> user, @org -> org,
     * @user/ctx1 -> ctx1, @org/ctxs/ctx -> ctxs/ctx.
     */
    name: Scalars['String']
    /** Namespace of the search context (user or org). If not set, search context is considered instance-level. */
    namespace?: InputMaybe<Scalars['ID']>
    /**
     * Public property controls the visibility of the search context. Public search context is available to
     * any user on the instance. If a public search context contains private repositories, those are filtered out
     * for unauthorized users. Private search contexts are only available to their owners. Private user search context
     * is available only to the user, private org search context is available only to the members of the org, and private
     * instance-level search contexts are available only to site-admins.
     */
    public: Scalars['Boolean']
    /**
     * Sourcegraph search query that defines the search context.
     * e.g. "r:^github\.com/org (rev:bar or rev:HEAD) file:^sub/dir"
     */
    query: Scalars['String']
}

/** Input for a set of revisions to be searched within a repository. */
export interface SearchContextRepositoryRevisionsInput {
    /** ID of the repository to be searched. */
    repositoryID: Scalars['ID']
    /** Revisions in the repository to be searched. */
    revisions: Array<Scalars['String']>
}

/** SearchContextsOrderBy enumerates the ways a search contexts list can be ordered. */
export enum SearchContextsOrderBy {
    SEARCH_CONTEXT_SPEC = 'SEARCH_CONTEXT_SPEC',
    SEARCH_CONTEXT_UPDATED_AT = 'SEARCH_CONTEXT_UPDATED_AT',
}

/** Required input to generate a time series for a search insight using live preview. */
export interface SearchInsightLivePreviewInput {
    /** Whether or not to generate the timeseries results from the query capture groups. */
    generatedFromCaptureGroups: Scalars['Boolean']
    /** Use this field to specify a compute insight. Note: this is experimental and should be considered unstable */
    groupBy?: InputMaybe<GroupByField>
    /** The desired label for the series. Will be overwritten when series are dynamically generated. */
    label: Scalars['String']
    /** The query string. */
    query: Scalars['String']
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
    /** The scope of time. */
    timeScope: TimeScopeInput
}

/** Required input to generate a live preview for an insight. */
export interface SearchInsightPreviewInput {
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
    /** The series to generate previews for */
    series: Array<SearchSeriesPreviewInput>
    /** The scope of time. */
    timeScope: TimeScopeInput
}

/** The search pattern type. */
export enum SearchPatternType {
    keyword = 'keyword',
    literal = 'literal',
    lucky = 'lucky',
    regexp = 'regexp',
    standard = 'standard',
    structural = 'structural',
}

/** The output format to emit for a parsed query. */
export enum SearchQueryOutputFormat {
    /** JSON format. */
    JSON = 'JSON',
    /** Mermaid flowchart format. */
    MERMAID = 'MERMAID',
    /** S-expression format. */
    SEXP = 'SEXP',
}

/**
 * Represents phases in query parsing. The parse tree corresponds closely to the
 * input query syntax. A subsequent processing phase on the parse tree generates a
 * job tree. The job tree is an internal representation analogous to a database
 * query plan. The job tree discards information about query syntax and corresponds
 * closely to backend services (text search, git commit search, etc.).
 */
export enum SearchQueryOutputPhase {
    JOB_TREE = 'JOB_TREE',
    PARSE_TREE = 'PARSE_TREE',
}

/** The output format to emit for a parsed query. */
export enum SearchQueryOutputVerbosity {
    /** Basic verbosity outputs nodes and essential fields associated with nodes. */
    BASIC = 'BASIC',
    /** Maximal verbosity outputs nodes and all information associated with nodes. */
    MAXIMAL = 'MAXIMAL',
    /** Minimal verbosity outputs only nodes. */
    MINIMAL = 'MINIMAL',
}

/** Required input to generate a live preview for a series. */
export interface SearchSeriesPreviewInput {
    /** Whether or not to generate the timeseries results from the query capture groups. */
    generatedFromCaptureGroups: Scalars['Boolean']
    /** Use this field to specify a compute insight. Note: this is experimental and should be considered unstable */
    groupBy?: InputMaybe<GroupByField>
    /** The desired label for the series. Will be overwritten when series are dynamically generated. */
    label: Scalars['String']
    /** The query string. */
    query: Scalars['String']
}

/** The version of the search syntax. */
export enum SearchVersion {
    /** Search syntax that defaults to regexp search. */
    V1 = 'V1',
    /** Search syntax that defaults to literal-only search. */
    V2 = 'V2',
    /** Search syntax that defaults to standard search. */
    V3 = 'V3',
}

/** Input type for series display options. */
export interface SeriesDisplayOptionsInput {
    /** Max number of series to return. */
    limit?: InputMaybe<Scalars['Int']>
    /** Max number of samples to return. */
    numSamples?: InputMaybe<Scalars['Int']>
    /** Sort options for the series. */
    sortOptions?: InputMaybe<SeriesSortOptionsInput>
}

/** Sort direction for series. */
export enum SeriesSortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

/** Sort mode for series. */
export enum SeriesSortMode {
    DATE_ADDED = 'DATE_ADDED',
    LEXICOGRAPHICAL = 'LEXICOGRAPHICAL',
    RESULT_COUNT = 'RESULT_COUNT',
}

/** Input type for series sort options. */
export interface SeriesSortOptionsInput {
    /** Sort direction for series. */
    direction: SeriesSortDirection
    /** Sort mode for series. */
    mode: SeriesSortMode
}

/** An edit to a JSON property in a settings JSON object. The JSON property to edit can be nested. */
export interface SettingsEdit {
    /**
     * The key path of the property to update.
     *
     * Inserting into an existing array is not yet supported.
     */
    keyPath: Array<KeyPathSegment>
    /**
     * The new JSON-encoded value to insert. If the field's value is not set, the property is removed. (This is
     * different from the field's value being the JSON null value.)
     *
     * When the value is a non-primitive type, it must be specified using a GraphQL variable, not an inline literal,
     * or else the GraphQL parser will return an error.
     */
    value?: InputMaybe<Scalars['JSONValue']>
    /**
     * Whether to treat the value as a JSONC-encoded string, which makes it possible to perform an edit that
     * preserves (or adds/removes) comments.
     */
    valueIsJSONCEncodedString?: InputMaybe<Scalars['Boolean']>
}

/**
 * Input for Mutation.settingsMutation, which contains fields that all settings (global, organization, and user
 * settings) mutations need.
 */
export interface SettingsMutationGroupInput {
    /**
     * The ID of the last-known settings known to the client, or null if there is none. This field is used to
     * prevent race conditions when there are concurrent editors.
     */
    lastID?: InputMaybe<Scalars['Int']>
    /** The subject whose settings to mutate (organization, user, etc.). */
    subject: Scalars['ID']
}

/** SiteUserOrderBy enumerates the ways a users list can be ordered. */
export enum SiteUserOrderBy {
    /** The datetime when user was added to the system. */
    CREATED_AT = 'CREATED_AT',
    /** The datetime when user was soft deleted. */
    DELETED_AT = 'DELETED_AT',
    /** User's primary email. */
    EMAIL = 'EMAIL',
    /** The total number of user's event_logs. */
    EVENTS_COUNT = 'EVENTS_COUNT',
    /** The last event_log datetime. */
    LAST_ACTIVE_AT = 'LAST_ACTIVE_AT',
    /** Whether the user is site admin or not. */
    SITE_ADMIN = 'SITE_ADMIN',
    USERNAME = 'USERNAME',
}

/** SiteUsersDateRangeInput argument to filter based on date range or date equals to null */
export interface SiteUsersDateRangeInput {
    /** Equal to Null */
    empty?: InputMaybe<Scalars['Boolean']>
    /** Greater than or equal to */
    gte?: InputMaybe<Scalars['DateTime']>
    /** Less than or equal to */
    lte?: InputMaybe<Scalars['DateTime']>
    /** Negation */
    not?: InputMaybe<Scalars['Boolean']>
}

/** SiteUsersNumberRangeInput argument to filter based on the number range */
export interface SiteUsersNumberRangeInput {
    /** Less than or equal to */
    gte?: InputMaybe<Scalars['Float']>
    /** Greater than or equal to */
    lte?: InputMaybe<Scalars['Float']>
}

/** Input for a user satisfaction (NPS) survey submission. */
export interface SurveySubmissionInput {
    /** The answer to "What would make Sourcegraph better?" */
    better?: InputMaybe<Scalars['String']>
    /**
     * User-provided email address, if there is no currently authenticated user. If there is, this value
     * will not be used.
     */
    email?: InputMaybe<Scalars['String']>
    /** The answer to "What do you use Sourcegraph for?". */
    otherUseCase?: InputMaybe<Scalars['String']>
    /** User's likelihood of recommending Sourcegraph to a friend, from 0-10. */
    score: Scalars['Int']
}

/** Possible answers to "You use Sourcegraph to..." in the NPS Survey. */
export enum SurveyUseCase {
    FIX_SECURITY_VULNERABILITIES = 'FIX_SECURITY_VULNERABILITIES',
    IMPROVE_CODE_QUALITY = 'IMPROVE_CODE_QUALITY',
    RESPOND_TO_INCIDENTS = 'RESPOND_TO_INCIDENTS',
    REUSE_CODE = 'REUSE_CODE',
    UNDERSTAND_NEW_CODE = 'UNDERSTAND_NEW_CODE',
}

/**
 * All possible kinds of symbols. This set matches that of the Language Server Protocol
 * (https://microsoft.github.io/language-server-protocol/specification#workspace_symbol).
 */
export enum SymbolKind {
    ARRAY = 'ARRAY',
    BOOLEAN = 'BOOLEAN',
    CLASS = 'CLASS',
    CONSTANT = 'CONSTANT',
    CONSTRUCTOR = 'CONSTRUCTOR',
    ENUM = 'ENUM',
    ENUMMEMBER = 'ENUMMEMBER',
    EVENT = 'EVENT',
    FIELD = 'FIELD',
    FILE = 'FILE',
    FUNCTION = 'FUNCTION',
    INTERFACE = 'INTERFACE',
    KEY = 'KEY',
    METHOD = 'METHOD',
    MODULE = 'MODULE',
    NAMESPACE = 'NAMESPACE',
    NULL = 'NULL',
    NUMBER = 'NUMBER',
    OBJECT = 'OBJECT',
    OPERATOR = 'OPERATOR',
    PACKAGE = 'PACKAGE',
    PROPERTY = 'PROPERTY',
    STRING = 'STRING',
    STRUCT = 'STRUCT',
    TYPEPARAMETER = 'TYPEPARAMETER',
    UNKNOWN = 'UNKNOWN',
    VARIABLE = 'VARIABLE',
}

/** A time scope defined using a time interval (ex. 5 days) */
export interface TimeIntervalStepInput {
    /** The time unit for the interval. */
    unit: TimeIntervalStepUnit
    /** The value for the interval. */
    value: Scalars['Int']
}

/** Time interval units. */
export enum TimeIntervalStepUnit {
    DAY = 'DAY',
    HOUR = 'HOUR',
    MONTH = 'MONTH',
    WEEK = 'WEEK',
    YEAR = 'YEAR',
}

/** A custom time scope for an insight data series. */
export interface TimeScopeInput {
    /** Sets a time scope using a step interval (intervals of time). */
    stepInterval?: InputMaybe<TimeIntervalStepInput>
}

/** Fields to update for an existing external service. */
export interface UpdateExternalServiceInput {
    /** The updated config, if provided. */
    config?: InputMaybe<Scalars['String']>
    /** The updated display name, if provided. */
    displayName?: InputMaybe<Scalars['String']>
    /** The id of the external service to update. */
    id: Scalars['ID']
}

/** Input object for updating a dashboard. */
export interface UpdateInsightsDashboardInput {
    /** Permissions to grant to the dashboard. */
    grants?: InputMaybe<InsightsPermissionGrantsInput>
    /** Dashboard title. */
    title?: InputMaybe<Scalars['String']>
}

/** Input object for update insight series mutation. */
export interface UpdateInsightSeriesInput {
    /** The desired activity state (enabled or disabled) for the series. */
    enabled?: InputMaybe<Scalars['Boolean']>
    /** Unique ID for the series. */
    seriesId: Scalars['String']
}

/** Input for updating a line chart search insight. */
export interface UpdateLineChartSearchInsightInput {
    /** The complete list of data series on this line chart. Note: excluding a data series will remove it. */
    dataSeries: Array<LineChartSearchInsightDataSeriesInput>
    /** The presentation options for this line chart. */
    presentationOptions: LineChartOptionsInput
    /** The scope of repositories for the insight, this scope will apply to all dataSeries unless another scope is provided by a series. */
    repositoryScope?: InputMaybe<RepositoryScopeInput>
    /** The time scope for this insight, this scope will apply to all dataSeries unless another scope is provided by a series. */
    timeScope?: InputMaybe<TimeScopeInput>
    /** The default values for filters and aggregates for this line chart. */
    viewControls: InsightViewControlsInput
}

/** Input for updating a pie chart search insight */
export interface UpdatePieChartSearchInsightInput {
    /** Options for this pie chart. */
    presentationOptions: PieChartOptionsInput
    /** The query string. */
    query: Scalars['String']
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
}

/** A period of time in which a set of users have been active. */
export enum UserActivePeriod {
    /** All time. */
    ALL_TIME = 'ALL_TIME',
    /** Since the first day of the current month at 00:00 UTC. */
    THIS_MONTH = 'THIS_MONTH',
    /** Since the latest Monday at 00:00 UTC. */
    THIS_WEEK = 'THIS_WEEK',
    /** Since today at 00:00 UTC. */
    TODAY = 'TODAY',
}

/** A user event. */
export enum UserEvent {
    CODEINTEL = 'CODEINTEL',
    CODEINTELINTEGRATION = 'CODEINTELINTEGRATION',
    CODEINTELINTEGRATIONREFS = 'CODEINTELINTEGRATIONREFS',
    CODEINTELREFS = 'CODEINTELREFS',
    PAGEVIEW = 'PAGEVIEW',
    SEARCHQUERY = 'SEARCHQUERY',
    STAGEAUTOMATE = 'STAGEAUTOMATE',
    STAGECODE = 'STAGECODE',
    STAGECONFIGURE = 'STAGECONFIGURE',
    STAGEDEPLOY = 'STAGEDEPLOY',
    /** Product stages */
    STAGEMANAGE = 'STAGEMANAGE',
    STAGEMONITOR = 'STAGEMONITOR',
    STAGEPACKAGE = 'STAGEPACKAGE',
    STAGEPLAN = 'STAGEPLAN',
    STAGEREVIEW = 'STAGEREVIEW',
    STAGESECURE = 'STAGESECURE',
    STAGEVERIFY = 'STAGEVERIFY',
}

/** Input type of a user (identified either by username or email address) with its repository permission. */
export interface UserPermissionInput {
    /**
     * Depending on the bindID option in the permissions.userMapping site configuration property,
     * the elements of the list are either all usernames (bindID of "username") or all email
     * addresses (bindID of "email").
     */
    bindID: Scalars['String']
    /** The highest level of repository permission. */
    permission?: InputMaybe<RepositoryPermission>
}

/** A user (identified either by username or email address) with its sub-repository permissions. */
export interface UserSubRepoPermission {
    /**
     * Depending on the bindID option in the permissions.userMapping site configuration property,
     * the elements of the list are either all usernames (bindID of "username") or all email
     * addresses (bindID of "email").
     */
    bindID: Scalars['String']
    /**
     * DEPRECATED
     * An array of paths that the user is not allowed to access, in glob format.
     */
    pathExcludes?: InputMaybe<Array<Scalars['String']>>
    /**
     * DEPRECATED
     * An array of paths that the user is allowed to access, in glob format.
     */
    pathIncludes?: InputMaybe<Array<Scalars['String']>>
    /**
     * An array of paths in glob format. Paths starting with a minus (-)
     * (i.e. "-/dev/private") prevent access, otherwise paths grant access.
     * The last applicable path takes precedence.
     * When paths is set, pathIncludes and pathExcludes are ignored.
     */
    paths?: InputMaybe<Array<Scalars['String']>>
}

/** Possible sort orderings for a workspace connection. */
export enum WorkspacesSortOrder {
    /** Sort by repository name in ascending order. */
    REPO_NAME_ASC = 'REPO_NAME_ASC',
    /** Sort by repository name in descending order. */
    REPO_NAME_DESC = 'REPO_NAME_DESC',
}

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
    /** Indicates this type is a scalar. */
    SCALAR = 'SCALAR',
    /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
    OBJECT = 'OBJECT',
    /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
    INTERFACE = 'INTERFACE',
    /** Indicates this type is a union. `possibleTypes` is a valid field. */
    UNION = 'UNION',
    /** Indicates this type is an enum. `enumValues` is a valid field. */
    ENUM = 'ENUM',
    /** Indicates this type is an input object. `inputFields` is a valid field. */
    INPUT_OBJECT = 'INPUT_OBJECT',
    /** Indicates this type is a list. `ofType` is a valid field. */
    LIST = 'LIST',
    /** Indicates this type is a non-null. `ofType` is a valid field. */
    NON_NULL = 'NON_NULL',
}

export type CurrentAuthStateVariables = Exact<{ [key: string]: never }>

export type CurrentAuthStateResult = {
    __typename?: 'Query'
    currentUser: {
        __typename: 'User'
        id: string
        databaseID: number
        username: string
        avatarURL: string | null
        displayName: string | null
        siteAdmin: boolean
        tags: Array<string>
        url: string
        settingsURL: string | null
        viewerCanAdminister: boolean
        tosAccepted: boolean
        searchable: boolean
        organizations: {
            __typename?: 'OrgConnection'
            nodes: Array<{
                __typename: 'Org'
                id: string
                name: string
                displayName: string | null
                url: string
                settingsURL: string | null
            }>
        }
        session: { __typename?: 'Session'; canSignOut: boolean }
        emails: Array<{ __typename?: 'UserEmail'; email: string; verified: boolean; isPrimary: boolean }>
        latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
    } | null
}

export type HighlightedFileVariables = Exact<{
    repoName: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    disableTimeout: Scalars['Boolean']
    ranges: Array<HighlightLineRange>
    format: HighlightResponseFormat
}>

export type HighlightedFileResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            file:
                | {
                      __typename?: 'BatchSpecWorkspaceFile'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | {
                      __typename?: 'GitBlob'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | {
                      __typename?: 'VirtualFile'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | null
        } | null
    } | null
}

export type HighlightedFileVSCEVariables = Exact<{
    repoName: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    disableTimeout: Scalars['Boolean']
    ranges: Array<HighlightLineRange>
}>

export type HighlightedFileVSCEResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            file:
                | {
                      __typename?: 'BatchSpecWorkspaceFile'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | {
                      __typename?: 'GitBlob'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | {
                      __typename?: 'VirtualFile'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | null
        } | null
    } | null
}

export type ResolveRawRepoNameVariables = Exact<{
    repoName: Scalars['String']
}>

export type ResolveRawRepoNameResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        uri: string
        mirrorInfo: { __typename?: 'MirrorRepositoryInfo'; cloned: boolean }
    } | null
}

export type TreeEntriesVariables = Exact<{
    repoName: Scalars['String']
    revision: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    first: InputMaybe<Scalars['Int']>
}>

export type TreeEntriesResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            tree: {
                __typename?: 'GitTree'
                isRoot: boolean
                url: string
                entries: Array<
                    | {
                          __typename?: 'GitBlob'
                          name: string
                          path: string
                          isDirectory: boolean
                          url: string
                          isSingleChild: boolean
                          submodule: { __typename?: 'Submodule'; url: string; commit: string } | null
                      }
                    | {
                          __typename?: 'GitTree'
                          name: string
                          path: string
                          isDirectory: boolean
                          url: string
                          isSingleChild: boolean
                          submodule: { __typename?: 'Submodule'; url: string; commit: string } | null
                      }
                >
            } | null
        } | null
    } | null
}

export type TreeFields = {
    __typename?: 'GitTree'
    isRoot: boolean
    url: string
    entries: Array<
        | {
              __typename?: 'GitBlob'
              name: string
              path: string
              isDirectory: boolean
              url: string
              isSingleChild: boolean
              submodule: { __typename?: 'Submodule'; url: string; commit: string } | null
          }
        | {
              __typename?: 'GitTree'
              name: string
              path: string
              isDirectory: boolean
              url: string
              isSingleChild: boolean
              submodule: { __typename?: 'Submodule'; url: string; commit: string } | null
          }
    >
}

type TreeEntryFields_GitBlob_ = {
    __typename?: 'GitBlob'
    name: string
    path: string
    isDirectory: boolean
    url: string
    isSingleChild: boolean
    submodule: { __typename?: 'Submodule'; url: string; commit: string } | null
}

type TreeEntryFields_GitTree_ = {
    __typename?: 'GitTree'
    name: string
    path: string
    isDirectory: boolean
    url: string
    isSingleChild: boolean
    submodule: { __typename?: 'Submodule'; url: string; commit: string } | null
}

export type TreeEntryFields = TreeEntryFields_GitBlob_ | TreeEntryFields_GitTree_

export type SettingsCascadeFields = {
    __typename?: 'SettingsCascade'
    final: string
    subjects: Array<
        | {
              __typename: 'DefaultSettings'
              id: string
              settingsURL: string | null
              viewerCanAdminister: boolean
              latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
          }
        | {
              __typename: 'Org'
              id: string
              settingsURL: string | null
              viewerCanAdminister: boolean
              name: string
              displayName: string | null
              latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
          }
        | {
              __typename: 'Site'
              id: string
              settingsURL: string | null
              viewerCanAdminister: boolean
              siteID: string
              allowSiteSettingsEdits: boolean
              latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
          }
        | {
              __typename: 'User'
              id: string
              settingsURL: string | null
              viewerCanAdminister: boolean
              username: string
              displayName: string | null
              latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
          }
    >
}

export type OrgSettingFields = {
    __typename: 'Org'
    id: string
    settingsURL: string | null
    viewerCanAdminister: boolean
    name: string
    displayName: string | null
    latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
}

export type UserSettingFields = {
    __typename: 'User'
    id: string
    settingsURL: string | null
    viewerCanAdminister: boolean
    username: string
    displayName: string | null
    latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
}

export type SiteSettingFields = {
    __typename: 'Site'
    id: string
    settingsURL: string | null
    viewerCanAdminister: boolean
    siteID: string
    allowSiteSettingsEdits: boolean
    latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
}

export type DefaultSettingFields = {
    __typename: 'DefaultSettings'
    id: string
    settingsURL: string | null
    viewerCanAdminister: boolean
    latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
}

export type ViewerSettingsVariables = Exact<{ [key: string]: never }>

export type ViewerSettingsResult = {
    __typename?: 'Query'
    viewerSettings: {
        __typename?: 'SettingsCascade'
        final: string
        subjects: Array<
            | {
                  __typename: 'DefaultSettings'
                  id: string
                  settingsURL: string | null
                  viewerCanAdminister: boolean
                  latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
              }
            | {
                  __typename: 'Org'
                  id: string
                  settingsURL: string | null
                  viewerCanAdminister: boolean
                  name: string
                  displayName: string | null
                  latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
              }
            | {
                  __typename: 'Site'
                  id: string
                  settingsURL: string | null
                  viewerCanAdminister: boolean
                  siteID: string
                  allowSiteSettingsEdits: boolean
                  latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
              }
            | {
                  __typename: 'User'
                  id: string
                  settingsURL: string | null
                  viewerCanAdminister: boolean
                  username: string
                  displayName: string | null
                  latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
              }
        >
    }
}

export type LegacyDefinitionAndHoverVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
}>

export type LegacyDefinitionAndHoverResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            blob: {
                __typename?: 'GitBlob'
                lsif: {
                    __typename?: 'GitBlobLSIFData'
                    definitions: {
                        __typename?: 'LocationConnection'
                        nodes: Array<{
                            __typename?: 'Location'
                            resource: {
                                __typename?: 'CodeIntelGitBlob'
                                path: string
                                repository: { __typename?: 'CodeIntelRepository'; name: string }
                                commit: { __typename?: 'CodeIntelCommit'; oid: string }
                            }
                            range: {
                                __typename?: 'Range'
                                start: { __typename?: 'Position'; line: number; character: number }
                                end: { __typename?: 'Position'; line: number; character: number }
                            } | null
                        }>
                    }
                    hover: {
                        __typename?: 'Hover'
                        markdown: { __typename?: 'Markdown'; text: string }
                        range: {
                            __typename?: 'Range'
                            start: { __typename?: 'Position'; line: number; character: number }
                            end: { __typename?: 'Position'; line: number; character: number }
                        }
                    } | null
                } | null
            } | null
        } | null
    } | null
}

export type LegacyImplementationsVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
    after: InputMaybe<Scalars['String']>
}>

export type LegacyImplementationsResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            blob: {
                __typename?: 'GitBlob'
                lsif: {
                    __typename?: 'GitBlobLSIFData'
                    implementations: {
                        __typename?: 'LocationConnection'
                        nodes: Array<{
                            __typename?: 'Location'
                            resource: {
                                __typename?: 'CodeIntelGitBlob'
                                path: string
                                repository: { __typename?: 'CodeIntelRepository'; name: string }
                                commit: { __typename?: 'CodeIntelCommit'; oid: string }
                            }
                            range: {
                                __typename?: 'Range'
                                start: { __typename?: 'Position'; line: number; character: number }
                                end: { __typename?: 'Position'; line: number; character: number }
                            } | null
                        }>
                        pageInfo: { __typename?: 'PageInfo'; endCursor: string | null }
                    }
                } | null
            } | null
        } | null
    } | null
}

export type LegacyRangesVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    startLine: Scalars['Int']
    endLine: Scalars['Int']
}>

export type LegacyRangesResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            blob: {
                __typename?: 'GitBlob'
                lsif: {
                    __typename?: 'GitBlobLSIFData'
                    ranges: {
                        __typename?: 'CodeIntelligenceRangeConnection'
                        nodes: Array<{
                            __typename?: 'CodeIntelligenceRange'
                            range: {
                                __typename?: 'Range'
                                start: { __typename?: 'Position'; line: number; character: number }
                                end: { __typename?: 'Position'; line: number; character: number }
                            }
                            definitions: {
                                __typename?: 'LocationConnection'
                                nodes: Array<{
                                    __typename?: 'Location'
                                    resource: { __typename?: 'CodeIntelGitBlob'; path: string }
                                    range: {
                                        __typename?: 'Range'
                                        start: { __typename?: 'Position'; line: number; character: number }
                                        end: { __typename?: 'Position'; line: number; character: number }
                                    } | null
                                }>
                            }
                            references: {
                                __typename?: 'LocationConnection'
                                nodes: Array<{
                                    __typename?: 'Location'
                                    resource: { __typename?: 'CodeIntelGitBlob'; path: string }
                                    range: {
                                        __typename?: 'Range'
                                        start: { __typename?: 'Position'; line: number; character: number }
                                        end: { __typename?: 'Position'; line: number; character: number }
                                    } | null
                                }>
                            }
                            hover: {
                                __typename?: 'Hover'
                                markdown: { __typename?: 'Markdown'; text: string }
                                range: {
                                    __typename?: 'Range'
                                    start: { __typename?: 'Position'; line: number; character: number }
                                    end: { __typename?: 'Position'; line: number; character: number }
                                }
                            } | null
                        }>
                    } | null
                } | null
            } | null
        } | null
    } | null
}

export type LegacyReferencesVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
    after: InputMaybe<Scalars['String']>
}>

export type LegacyReferencesResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            blob: {
                __typename?: 'GitBlob'
                lsif: {
                    __typename?: 'GitBlobLSIFData'
                    references: {
                        __typename?: 'LocationConnection'
                        nodes: Array<{
                            __typename?: 'Location'
                            resource: {
                                __typename?: 'CodeIntelGitBlob'
                                path: string
                                repository: { __typename?: 'CodeIntelRepository'; name: string }
                                commit: { __typename?: 'CodeIntelCommit'; oid: string }
                            }
                            range: {
                                __typename?: 'Range'
                                start: { __typename?: 'Position'; line: number; character: number }
                                end: { __typename?: 'Position'; line: number; character: number }
                            } | null
                        }>
                        pageInfo: { __typename?: 'PageInfo'; endCursor: string | null }
                    }
                } | null
            } | null
        } | null
    } | null
}

export type LegacyStencilVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
}>

export type LegacyStencilResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            blob: {
                __typename?: 'GitBlob'
                lsif: {
                    __typename?: 'GitBlobLSIFData'
                    stencil: Array<{
                        __typename?: 'Range'
                        start: { __typename?: 'Position'; line: number; character: number }
                        end: { __typename?: 'Position'; line: number; character: number }
                    }>
                } | null
            } | null
        } | null
    } | null
}

export type LegacyResolveRepoVariables = Exact<{
    name: Scalars['String']
}>

export type LegacyResolveRepoResult = {
    __typename?: 'Query'
    repository: { __typename?: 'Repository'; id: string; name: string; isFork: boolean; isArchived: boolean } | null
}

export type LegacyResolveRepo2Variables = Exact<{
    name: Scalars['String']
}>

export type LegacyResolveRepo2Result = {
    __typename?: 'Query'
    repository: { __typename?: 'Repository'; name: string } | null
}

export type LegacyRepositoryIntrospectionVariables = Exact<{ [key: string]: never }>

export type LegacyRepositoryIntrospectionResult = {
    __typename?: 'Query'
    __type: { __typename?: '__Type'; fields: Array<{ __typename?: '__Field'; name: string }> | null } | null
}

export type LegacyLocalCodeIntelIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacyLocalCodeIntelIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: { __typename?: '__Type'; fields: Array<{ __typename?: '__Field'; name: string }> | null } | null
}

export type LegacySymbolInfoIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacySymbolInfoIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: { __typename?: '__Type'; fields: Array<{ __typename?: '__Field'; name: string }> | null } | null
}

export type LegacySymbolLocationRangeIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacySymbolLocationRangeIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: { __typename?: '__Type'; fields: Array<{ __typename?: '__Field'; name: string }> | null } | null
}

export type LegacyFileContentVariables = Exact<{
    repo: Scalars['String']
    rev: Scalars['String']
    path: Scalars['String']
}>

export type LegacyFileContentResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            file:
                | { __typename?: 'BatchSpecWorkspaceFile'; content: string }
                | { __typename?: 'GitBlob'; content: string }
                | { __typename?: 'VirtualFile'; content: string }
                | null
        } | null
    } | null
}

export type LegacyStencilIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacyStencilIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: { __typename?: '__Type'; fields: Array<{ __typename?: '__Field'; name: string }> | null } | null
}

export type SearchResults = {
    __typename?: 'Search'
    results: {
        __typename: 'SearchResults'
        results: Array<
            | { __typename?: 'CommitSearchResult' }
            | {
                  __typename: 'FileMatch'
                  file: { __typename?: 'GitBlob'; path: string; commit: { __typename?: 'GitCommit'; oid: string } }
                  repository: { __typename?: 'Repository'; name: string }
                  symbols: Array<{
                      __typename?: 'Symbol'
                      name: string
                      kind: SymbolKind
                      location: {
                          __typename?: 'Location'
                          resource: { __typename?: 'CodeIntelGitBlob'; path: string }
                          range: {
                              __typename?: 'Range'
                              start: { __typename?: 'Position'; line: number; character: number }
                              end: { __typename?: 'Position'; line: number; character: number }
                          } | null
                      }
                  }>
                  lineMatches: Array<{
                      __typename?: 'LineMatch'
                      lineNumber: number
                      offsetAndLengths: Array<Array<number>>
                  }>
              }
            | { __typename?: 'Repository' }
        >
    }
}

export type FileLocal = {
    __typename?: 'Search'
    results: {
        __typename: 'SearchResults'
        results: Array<
            | { __typename?: 'CommitSearchResult' }
            | { __typename?: 'FileMatch'; symbols: Array<{ __typename?: 'Symbol'; fileLocal: boolean }> }
            | { __typename?: 'Repository' }
        >
    }
}

export type LegacyCodeIntelSearch2Variables = Exact<{
    query: Scalars['String']
}>

export type LegacyCodeIntelSearch2Result = {
    __typename?: 'Query'
    search: {
        __typename?: 'Search'
        results: {
            __typename: 'SearchResults'
            results: Array<
                | { __typename?: 'CommitSearchResult' }
                | {
                      __typename: 'FileMatch'
                      file: { __typename?: 'GitBlob'; path: string; commit: { __typename?: 'GitCommit'; oid: string } }
                      repository: { __typename?: 'Repository'; name: string }
                      symbols: Array<{
                          __typename?: 'Symbol'
                          name: string
                          kind: SymbolKind
                          fileLocal: boolean
                          location: {
                              __typename?: 'Location'
                              resource: { __typename?: 'CodeIntelGitBlob'; path: string }
                              range: {
                                  __typename?: 'Range'
                                  start: { __typename?: 'Position'; line: number; character: number }
                                  end: { __typename?: 'Position'; line: number; character: number }
                              } | null
                          }
                      }>
                      lineMatches: Array<{
                          __typename?: 'LineMatch'
                          lineNumber: number
                          offsetAndLengths: Array<Array<number>>
                      }>
                  }
                | { __typename?: 'Repository' }
            >
        }
    } | null
}

export type LegacyCodeIntelSearch3Variables = Exact<{
    query: Scalars['String']
}>

export type LegacyCodeIntelSearch3Result = {
    __typename?: 'Query'
    search: {
        __typename?: 'Search'
        results: {
            __typename: 'SearchResults'
            results: Array<
                | { __typename?: 'CommitSearchResult' }
                | {
                      __typename: 'FileMatch'
                      file: { __typename?: 'GitBlob'; path: string; commit: { __typename?: 'GitCommit'; oid: string } }
                      repository: { __typename?: 'Repository'; name: string }
                      symbols: Array<{
                          __typename?: 'Symbol'
                          name: string
                          kind: SymbolKind
                          location: {
                              __typename?: 'Location'
                              resource: { __typename?: 'CodeIntelGitBlob'; path: string }
                              range: {
                                  __typename?: 'Range'
                                  start: { __typename?: 'Position'; line: number; character: number }
                                  end: { __typename?: 'Position'; line: number; character: number }
                              } | null
                          }
                      }>
                      lineMatches: Array<{
                          __typename?: 'LineMatch'
                          lineNumber: number
                          offsetAndLengths: Array<Array<number>>
                      }>
                  }
                | { __typename?: 'Repository' }
            >
        }
    } | null
}

export type LocalCodeIntelVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
}>

export type LocalCodeIntelResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        id: string
        commit: {
            __typename?: 'GitCommit'
            blob: { __typename?: 'GitBlob'; localCodeIntel: unknown | null } | null
        } | null
    } | null
}

export type LegacySymbolInfoVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
}>

export type LegacySymbolInfoResult = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            blob: {
                __typename?: 'GitBlob'
                symbolInfo: {
                    __typename?: 'SymbolInfo'
                    hover: string | null
                    definition: {
                        __typename?: 'SymbolLocation'
                        repo: string
                        commit: string
                        path: string
                        line: number
                        character: number
                        length: number
                    } | null
                } | null
            } | null
        } | null
    } | null
}

export type LegacySymbolInfo2Variables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
}>

export type LegacySymbolInfo2Result = {
    __typename?: 'Query'
    repository: {
        __typename?: 'Repository'
        commit: {
            __typename?: 'GitCommit'
            blob: {
                __typename?: 'GitBlob'
                symbolInfo: {
                    __typename?: 'SymbolInfo'
                    hover: string | null
                    definition: {
                        __typename?: 'SymbolLocation'
                        repo: string
                        commit: string
                        path: string
                        range: { __typename?: 'LineRange'; line: number; character: number; length: number } | null
                    } | null
                } | null
            } | null
        } | null
    } | null
}

export type ExtensionsVariables = Exact<{
    first: Scalars['Int']
    extensionIDs: Array<Scalars['String']>
    extensionManifestFields: Array<Scalars['String']>
}>

export type ExtensionsResult = {
    __typename?: 'Query'
    extensionRegistry: {
        __typename?: 'ExtensionRegistry'
        extensions: {
            __typename?: 'RegistryExtensionConnection'
            nodes: Array<{
                __typename?: 'RegistryExtension'
                id: string
                extensionID: string
                manifest: { __typename?: 'ExtensionManifest'; jsonFields: unknown } | null
            }>
        }
    }
}

export type SearchContextFields = {
    __typename: 'SearchContext'
    id: string
    name: string
    spec: string
    description: string
    public: boolean
    autoDefined: boolean
    updatedAt: string
    viewerCanManage: boolean
    viewerHasStarred: boolean
    viewerHasAsDefault: boolean
    query: string
    namespace:
        | { __typename: 'Org'; id: string; namespaceName: string }
        | { __typename: 'User'; id: string; namespaceName: string }
        | null
    repositories: Array<{
        __typename?: 'SearchContextRepositoryRevisions'
        revisions: Array<string>
        repository: { __typename?: 'Repository'; name: string }
    }>
}

export type SearchContextRepositoryRevisionsFields = {
    __typename?: 'SearchContextRepositoryRevisions'
    revisions: Array<string>
    repository: { __typename?: 'Repository'; name: string }
}

export type SearchContextMinimalFields = {
    __typename: 'SearchContext'
    id: string
    name: string
    spec: string
    description: string
    public: boolean
    query: string
    autoDefined: boolean
    updatedAt: string
    viewerCanManage: boolean
    viewerHasStarred: boolean
    viewerHasAsDefault: boolean
    namespace?:
        | { __typename: 'Org'; id: string; namespaceName: string }
        | { __typename: 'User'; id: string; namespaceName: string }
        | null
    repositories?: Array<{
        __typename: 'SearchContextRepositoryRevisions'
        revisions: Array<string>
        repository: { __typename?: 'Repository'; name: string }
    }>
}

export type ListSearchContextsVariables = Exact<{
    first: Scalars['Int']
    after: InputMaybe<Scalars['String']>
    query: InputMaybe<Scalars['String']>
    namespaces: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    orderBy: InputMaybe<SearchContextsOrderBy>
    descending: InputMaybe<Scalars['Boolean']>
    useMinimalFields: Scalars['Boolean']
}>

export type ListSearchContextsResult = {
    __typename?: 'Query'
    searchContexts: {
        __typename?: 'SearchContextConnection'
        totalCount: number
        nodes: Array<{
            __typename: 'SearchContext'
            id: string
            name: string
            spec: string
            description: string
            public: boolean
            query: string
            autoDefined: boolean
            updatedAt: string
            viewerCanManage: boolean
            viewerHasStarred: boolean
            viewerHasAsDefault: boolean
            namespace?:
                | { __typename: 'Org'; id: string; namespaceName: string }
                | { __typename: 'User'; id: string; namespaceName: string }
                | null
            repositories?: Array<{
                __typename: 'SearchContextRepositoryRevisions'
                revisions: Array<string>
                repository: { __typename?: 'Repository'; name: string }
            }>
        }>
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null }
    }
}

export type FetchSearchContextVariables = Exact<{
    id: Scalars['ID']
}>

export type FetchSearchContextResult = {
    __typename?: 'Query'
    node:
        | { __typename?: 'AccessToken' }
        | { __typename?: 'BackgroundJob' }
        | { __typename?: 'BatchChange' }
        | { __typename?: 'BatchChangesCredential' }
        | { __typename?: 'BatchSpec' }
        | { __typename?: 'BatchSpecWorkspaceFile' }
        | { __typename?: 'BulkOperation' }
        | { __typename?: 'ChangesetEvent' }
        | { __typename?: 'CodeIntelligenceConfigurationPolicy' }
        | { __typename?: 'Executor' }
        | { __typename?: 'ExecutorSecret' }
        | { __typename?: 'ExecutorSecretAccessLog' }
        | { __typename?: 'ExternalAccount' }
        | { __typename?: 'ExternalChangeset' }
        | { __typename?: 'ExternalService' }
        | { __typename?: 'ExternalServiceSyncJob' }
        | { __typename?: 'GitCommit' }
        | { __typename?: 'GitRef' }
        | { __typename?: 'HiddenBatchSpecWorkspace' }
        | { __typename?: 'HiddenChangesetSpec' }
        | { __typename?: 'HiddenExternalChangeset' }
        | { __typename?: 'InsightsDashboard' }
        | { __typename?: 'InsightView' }
        | { __typename?: 'LSIFIndex' }
        | { __typename?: 'LSIFUpload' }
        | { __typename?: 'Monitor' }
        | { __typename?: 'MonitorActionEvent' }
        | { __typename?: 'MonitorEmail' }
        | { __typename?: 'MonitorQuery' }
        | { __typename?: 'MonitorSlackWebhook' }
        | { __typename?: 'MonitorTriggerEvent' }
        | { __typename?: 'MonitorWebhook' }
        | { __typename?: 'Notebook' }
        | { __typename?: 'Org' }
        | { __typename?: 'OrganizationInvitation' }
        | { __typename?: 'OutboundRequest' }
        | { __typename?: 'OutboundWebhook' }
        | { __typename?: 'OutOfBandMigration' }
        | { __typename?: 'Permission' }
        | { __typename?: 'PermissionsSyncJob' }
        | { __typename?: 'PreciseIndex' }
        | { __typename?: 'ProductLicense' }
        | { __typename?: 'ProductSubscription' }
        | { __typename?: 'RegistryExtension' }
        | { __typename?: 'Repository' }
        | { __typename?: 'Role' }
        | { __typename?: 'SavedSearch' }
        | {
              __typename: 'SearchContext'
              id: string
              name: string
              spec: string
              description: string
              public: boolean
              autoDefined: boolean
              updatedAt: string
              viewerCanManage: boolean
              viewerHasStarred: boolean
              viewerHasAsDefault: boolean
              query: string
              namespace:
                  | { __typename: 'Org'; id: string; namespaceName: string }
                  | { __typename: 'User'; id: string; namespaceName: string }
                  | null
              repositories: Array<{
                  __typename?: 'SearchContextRepositoryRevisions'
                  revisions: Array<string>
                  repository: { __typename?: 'Repository'; name: string }
              }>
          }
        | { __typename?: 'SiteConfigurationChange' }
        | { __typename?: 'Team' }
        | { __typename?: 'User' }
        | { __typename?: 'VisibleBatchSpecWorkspace' }
        | { __typename?: 'VisibleChangesetSpec' }
        | { __typename?: 'Webhook' }
        | { __typename?: 'WebhookLog' }
        | null
}

export type FetchSearchContextBySpecVariables = Exact<{
    spec: Scalars['String']
}>

export type FetchSearchContextBySpecResult = {
    __typename?: 'Query'
    searchContextBySpec: {
        __typename: 'SearchContext'
        id: string
        name: string
        spec: string
        description: string
        public: boolean
        autoDefined: boolean
        updatedAt: string
        viewerCanManage: boolean
        viewerHasStarred: boolean
        viewerHasAsDefault: boolean
        query: string
        namespace:
            | { __typename: 'Org'; id: string; namespaceName: string }
            | { __typename: 'User'; id: string; namespaceName: string }
            | null
        repositories: Array<{
            __typename?: 'SearchContextRepositoryRevisions'
            revisions: Array<string>
            repository: { __typename?: 'Repository'; name: string }
        }>
    } | null
}

export type CreateSearchContextVariables = Exact<{
    searchContext: SearchContextInput
    repositories: Array<SearchContextRepositoryRevisionsInput>
}>

export type CreateSearchContextResult = {
    __typename?: 'Mutation'
    createSearchContext: {
        __typename: 'SearchContext'
        id: string
        name: string
        spec: string
        description: string
        public: boolean
        autoDefined: boolean
        updatedAt: string
        viewerCanManage: boolean
        viewerHasStarred: boolean
        viewerHasAsDefault: boolean
        query: string
        namespace:
            | { __typename: 'Org'; id: string; namespaceName: string }
            | { __typename: 'User'; id: string; namespaceName: string }
            | null
        repositories: Array<{
            __typename?: 'SearchContextRepositoryRevisions'
            revisions: Array<string>
            repository: { __typename?: 'Repository'; name: string }
        }>
    }
}

export type UpdateSearchContextVariables = Exact<{
    id: Scalars['ID']
    searchContext: SearchContextEditInput
    repositories: Array<SearchContextRepositoryRevisionsInput>
}>

export type UpdateSearchContextResult = {
    __typename?: 'Mutation'
    updateSearchContext: {
        __typename: 'SearchContext'
        id: string
        name: string
        spec: string
        description: string
        public: boolean
        autoDefined: boolean
        updatedAt: string
        viewerCanManage: boolean
        viewerHasStarred: boolean
        viewerHasAsDefault: boolean
        query: string
        namespace:
            | { __typename: 'Org'; id: string; namespaceName: string }
            | { __typename: 'User'; id: string; namespaceName: string }
            | null
        repositories: Array<{
            __typename?: 'SearchContextRepositoryRevisions'
            revisions: Array<string>
            repository: { __typename?: 'Repository'; name: string }
        }>
    }
}

export type DeleteSearchContextVariables = Exact<{
    id: Scalars['ID']
}>

export type DeleteSearchContextResult = {
    __typename?: 'Mutation'
    deleteSearchContext: { __typename?: 'EmptyResponse'; alwaysNil: string | null }
}

export type IsSearchContextAvailableVariables = Exact<{
    spec: Scalars['String']
}>

export type IsSearchContextAvailableResult = { __typename?: 'Query'; isSearchContextAvailable: boolean }

export type highlightCodeVariables = Exact<{
    code: Scalars['String']
    fuzzyLanguage: Scalars['String']
    disableTimeout: Scalars['Boolean']
}>

export type highlightCodeResult = { __typename?: 'Query'; highlightCode: string }

export type EventLogsDataVariables = Exact<{
    userId: Scalars['ID']
    first: InputMaybe<Scalars['Int']>
    eventName: Scalars['String']
}>

export type EventLogsDataResult = {
    __typename?: 'Query'
    node:
        | { __typename?: 'AccessToken' }
        | { __typename?: 'BackgroundJob' }
        | { __typename?: 'BatchChange' }
        | { __typename?: 'BatchChangesCredential' }
        | { __typename?: 'BatchSpec' }
        | { __typename?: 'BatchSpecWorkspaceFile' }
        | { __typename?: 'BulkOperation' }
        | { __typename?: 'ChangesetEvent' }
        | { __typename?: 'CodeIntelligenceConfigurationPolicy' }
        | { __typename?: 'Executor' }
        | { __typename?: 'ExecutorSecret' }
        | { __typename?: 'ExecutorSecretAccessLog' }
        | { __typename?: 'ExternalAccount' }
        | { __typename?: 'ExternalChangeset' }
        | { __typename?: 'ExternalService' }
        | { __typename?: 'ExternalServiceSyncJob' }
        | { __typename?: 'GitCommit' }
        | { __typename?: 'GitRef' }
        | { __typename?: 'HiddenBatchSpecWorkspace' }
        | { __typename?: 'HiddenChangesetSpec' }
        | { __typename?: 'HiddenExternalChangeset' }
        | { __typename?: 'InsightsDashboard' }
        | { __typename?: 'InsightView' }
        | { __typename?: 'LSIFIndex' }
        | { __typename?: 'LSIFUpload' }
        | { __typename?: 'Monitor' }
        | { __typename?: 'MonitorActionEvent' }
        | { __typename?: 'MonitorEmail' }
        | { __typename?: 'MonitorQuery' }
        | { __typename?: 'MonitorSlackWebhook' }
        | { __typename?: 'MonitorTriggerEvent' }
        | { __typename?: 'MonitorWebhook' }
        | { __typename?: 'Notebook' }
        | { __typename?: 'Org' }
        | { __typename?: 'OrganizationInvitation' }
        | { __typename?: 'OutboundRequest' }
        | { __typename?: 'OutboundWebhook' }
        | { __typename?: 'OutOfBandMigration' }
        | { __typename?: 'Permission' }
        | { __typename?: 'PermissionsSyncJob' }
        | { __typename?: 'PreciseIndex' }
        | { __typename?: 'ProductLicense' }
        | { __typename?: 'ProductSubscription' }
        | { __typename?: 'RegistryExtension' }
        | { __typename?: 'Repository' }
        | { __typename?: 'Role' }
        | { __typename?: 'SavedSearch' }
        | { __typename?: 'SearchContext' }
        | { __typename?: 'SiteConfigurationChange' }
        | { __typename?: 'Team' }
        | {
              __typename: 'User'
              eventLogs: {
                  __typename?: 'EventLogsConnection'
                  totalCount: number
                  nodes: Array<{ __typename?: 'EventLog'; argument: string | null; timestamp: string; url: string }>
                  pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean }
              }
          }
        | { __typename?: 'VisibleBatchSpecWorkspace' }
        | { __typename?: 'VisibleChangesetSpec' }
        | { __typename?: 'Webhook' }
        | { __typename?: 'WebhookLog' }
        | null
}

export type DefaultSearchContextSpecVariables = Exact<{ [key: string]: never }>

export type DefaultSearchContextSpecResult = {
    __typename?: 'Query'
    defaultSearchContext: { __typename?: 'SearchContext'; spec: string } | null
}

export type EditSettingsVariables = Exact<{
    subject: Scalars['ID']
    lastID: InputMaybe<Scalars['Int']>
    edit: ConfigurationEdit
}>

export type EditSettingsResult = {
    __typename?: 'Mutation'
    configurationMutation: {
        __typename?: 'SettingsMutation'
        editConfiguration: {
            __typename?: 'UpdateSettingsPayload'
            empty: { __typename?: 'EmptyResponse'; alwaysNil: string | null } | null
        } | null
    } | null
}

export type OverwriteSettingsVariables = Exact<{
    subject: Scalars['ID']
    lastID: InputMaybe<Scalars['Int']>
    contents: Scalars['String']
}>

export type OverwriteSettingsResult = {
    __typename?: 'Mutation'
    settingsMutation: {
        __typename?: 'SettingsMutation'
        overwriteSettings: {
            __typename?: 'UpdateSettingsPayload'
            empty: { __typename?: 'EmptyResponse'; alwaysNil: string | null } | null
        } | null
    } | null
}

export type GetTemporarySettingsVariables = Exact<{ [key: string]: never }>

export type GetTemporarySettingsResult = {
    __typename?: 'Query'
    temporarySettings: { __typename?: 'TemporarySettings'; contents: string }
}

export type EditTemporarySettingsVariables = Exact<{
    contents: Scalars['String']
}>

export type EditTemporarySettingsResult = {
    __typename?: 'Mutation'
    editTemporarySettings: { __typename?: 'EmptyResponse'; alwaysNil: string | null }
}

export type TemporarySettingsVariables = Exact<{ [key: string]: never }>

export type TemporarySettingsResult = {
    __typename?: 'Query'
    temporarySettings: { __typename?: 'TemporarySettings'; contents: string }
}

export type ExternalServicesForTestsVariables = Exact<{ [key: string]: never }>

export type ExternalServicesForTestsResult = {
    __typename?: 'Query'
    externalServices: { __typename?: 'ExternalServiceConnection'; totalCount: number }
}

export type RepositoryNameForTestsVariables = Exact<{
    name: Scalars['String']
}>

export type RepositoryNameForTestsResult = {
    __typename?: 'Query'
    repository: { __typename?: 'Repository'; id: string } | null
}

export type SiteForTestsVariables = Exact<{ [key: string]: never }>

export type SiteForTestsResult = {
    __typename?: 'Query'
    site: {
        __typename?: 'Site'
        id: string
        configuration: {
            __typename?: 'SiteConfiguration'
            id: number
            effectiveContents: string
            validationMessages: Array<string>
        }
    }
}

export type UpdateSiteConfigurationForTestsVariables = Exact<{
    lastID: Scalars['Int']
    input: Scalars['String']
}>

export type UpdateSiteConfigurationForTestsResult = { __typename?: 'Mutation'; updateSiteConfiguration: boolean }

export type UserSettingsForTestsVariables = Exact<{ [key: string]: never }>

export type UserSettingsForTestsResult = {
    __typename?: 'Query'
    currentUser: {
        __typename?: 'User'
        id: string
        latestSettings: { __typename?: 'Settings'; id: number; contents: string } | null
    } | null
}

export type OverwriteSettingsForTestsVariables = Exact<{
    subject: Scalars['ID']
    lastID: InputMaybe<Scalars['Int']>
    contents: Scalars['String']
}>

export type OverwriteSettingsForTestsResult = {
    __typename?: 'Mutation'
    settingsMutation: {
        __typename?: 'SettingsMutation'
        overwriteSettings: {
            __typename?: 'UpdateSettingsPayload'
            empty: { __typename?: 'EmptyResponse'; alwaysNil: string | null } | null
        } | null
    } | null
}

export type AccessTokenKeySpecifier = (
    | 'createdAt'
    | 'creator'
    | 'id'
    | 'lastUsedAt'
    | 'note'
    | 'scopes'
    | 'subject'
    | AccessTokenKeySpecifier
)[]
export type AccessTokenFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    lastUsedAt?: FieldPolicy<any> | FieldReadFunction<any>
    note?: FieldPolicy<any> | FieldReadFunction<any>
    scopes?: FieldPolicy<any> | FieldReadFunction<any>
    subject?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AccessTokenConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | AccessTokenConnectionKeySpecifier
)[]
export type AccessTokenConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AggregationGroupKeySpecifier = ('count' | 'label' | 'query' | AggregationGroupKeySpecifier)[]
export type AggregationGroupFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AggregationModeAvailabilityKeySpecifier = (
    | 'available'
    | 'mode'
    | 'reasonUnavailable'
    | AggregationModeAvailabilityKeySpecifier
)[]
export type AggregationModeAvailabilityFieldPolicy = {
    available?: FieldPolicy<any> | FieldReadFunction<any>
    mode?: FieldPolicy<any> | FieldReadFunction<any>
    reasonUnavailable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AlertKeySpecifier = ('isDismissibleWithKey' | 'message' | 'type' | AlertKeySpecifier)[]
export type AlertFieldPolicy = {
    isDismissibleWithKey?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsKeySpecifier = (
    | 'batchChanges'
    | 'codeInsights'
    | 'codeIntel'
    | 'codeIntelByLanguage'
    | 'codeIntelTopRepositories'
    | 'extensions'
    | 'notebooks'
    | 'repos'
    | 'search'
    | 'users'
    | AnalyticsKeySpecifier
)[]
export type AnalyticsFieldPolicy = {
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    codeInsights?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntel?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelByLanguage?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelTopRepositories?: FieldPolicy<any> | FieldReadFunction<any>
    extensions?: FieldPolicy<any> | FieldReadFunction<any>
    notebooks?: FieldPolicy<any> | FieldReadFunction<any>
    repos?: FieldPolicy<any> | FieldReadFunction<any>
    search?: FieldPolicy<any> | FieldReadFunction<any>
    users?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsBatchChangesResultKeySpecifier = (
    | 'changesetsCreated'
    | 'changesetsMerged'
    | AnalyticsBatchChangesResultKeySpecifier
)[]
export type AnalyticsBatchChangesResultFieldPolicy = {
    changesetsCreated?: FieldPolicy<any> | FieldReadFunction<any>
    changesetsMerged?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsCodeIntelByLanguageResultKeySpecifier = (
    | 'count'
    | 'language'
    | 'precision'
    | AnalyticsCodeIntelByLanguageResultKeySpecifier
)[]
export type AnalyticsCodeIntelByLanguageResultFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    language?: FieldPolicy<any> | FieldReadFunction<any>
    precision?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsCodeIntelRepositoryResultKeySpecifier = (
    | 'events'
    | 'hasPrecise'
    | 'kind'
    | 'language'
    | 'name'
    | 'precision'
    | AnalyticsCodeIntelRepositoryResultKeySpecifier
)[]
export type AnalyticsCodeIntelRepositoryResultFieldPolicy = {
    events?: FieldPolicy<any> | FieldReadFunction<any>
    hasPrecise?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    language?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    precision?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsCodeIntelResultKeySpecifier = (
    | 'codeHostEvents'
    | 'crossRepoEvents'
    | 'definitionClicks'
    | 'inAppEvents'
    | 'preciseEvents'
    | 'referenceClicks'
    | 'searchBasedEvents'
    | AnalyticsCodeIntelResultKeySpecifier
)[]
export type AnalyticsCodeIntelResultFieldPolicy = {
    codeHostEvents?: FieldPolicy<any> | FieldReadFunction<any>
    crossRepoEvents?: FieldPolicy<any> | FieldReadFunction<any>
    definitionClicks?: FieldPolicy<any> | FieldReadFunction<any>
    inAppEvents?: FieldPolicy<any> | FieldReadFunction<any>
    preciseEvents?: FieldPolicy<any> | FieldReadFunction<any>
    referenceClicks?: FieldPolicy<any> | FieldReadFunction<any>
    searchBasedEvents?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsCodesInsightsResultKeySpecifier = (
    | 'insightDataPointClicks'
    | 'insightHovers'
    | AnalyticsCodesInsightsResultKeySpecifier
)[]
export type AnalyticsCodesInsightsResultFieldPolicy = {
    insightDataPointClicks?: FieldPolicy<any> | FieldReadFunction<any>
    insightHovers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsExtensionsResultKeySpecifier = (
    | 'browser'
    | 'jetbrains'
    | 'vscode'
    | AnalyticsExtensionsResultKeySpecifier
)[]
export type AnalyticsExtensionsResultFieldPolicy = {
    browser?: FieldPolicy<any> | FieldReadFunction<any>
    jetbrains?: FieldPolicy<any> | FieldReadFunction<any>
    vscode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsMonthlyActiveUsersKeySpecifier = ('count' | 'date' | AnalyticsMonthlyActiveUsersKeySpecifier)[]
export type AnalyticsMonthlyActiveUsersFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    date?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsNotebooksResultsKeySpecifier = (
    | 'blockRuns'
    | 'creations'
    | 'views'
    | AnalyticsNotebooksResultsKeySpecifier
)[]
export type AnalyticsNotebooksResultsFieldPolicy = {
    blockRuns?: FieldPolicy<any> | FieldReadFunction<any>
    creations?: FieldPolicy<any> | FieldReadFunction<any>
    views?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsReposSummartResultKeySpecifier = (
    | 'count'
    | 'preciseCodeIntelCount'
    | AnalyticsReposSummartResultKeySpecifier
)[]
export type AnalyticsReposSummartResultFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    preciseCodeIntelCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsSearchResultKeySpecifier = (
    | 'codeCopied'
    | 'fileOpens'
    | 'fileViews'
    | 'resultClicks'
    | 'searches'
    | AnalyticsSearchResultKeySpecifier
)[]
export type AnalyticsSearchResultFieldPolicy = {
    codeCopied?: FieldPolicy<any> | FieldReadFunction<any>
    fileOpens?: FieldPolicy<any> | FieldReadFunction<any>
    fileViews?: FieldPolicy<any> | FieldReadFunction<any>
    resultClicks?: FieldPolicy<any> | FieldReadFunction<any>
    searches?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsStatItemKeySpecifier = ('nodes' | 'summary' | AnalyticsStatItemKeySpecifier)[]
export type AnalyticsStatItemFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    summary?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsStatItemNodeKeySpecifier = (
    | 'count'
    | 'date'
    | 'registeredUsers'
    | 'uniqueUsers'
    | AnalyticsStatItemNodeKeySpecifier
)[]
export type AnalyticsStatItemNodeFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    date?: FieldPolicy<any> | FieldReadFunction<any>
    registeredUsers?: FieldPolicy<any> | FieldReadFunction<any>
    uniqueUsers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsStatItemSummaryKeySpecifier = (
    | 'totalCount'
    | 'totalRegisteredUsers'
    | 'totalUniqueUsers'
    | AnalyticsStatItemSummaryKeySpecifier
)[]
export type AnalyticsStatItemSummaryFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    totalRegisteredUsers?: FieldPolicy<any> | FieldReadFunction<any>
    totalUniqueUsers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsUsersFrequencyItemKeySpecifier = (
    | 'daysUsed'
    | 'frequency'
    | 'percentage'
    | AnalyticsUsersFrequencyItemKeySpecifier
)[]
export type AnalyticsUsersFrequencyItemFieldPolicy = {
    daysUsed?: FieldPolicy<any> | FieldReadFunction<any>
    frequency?: FieldPolicy<any> | FieldReadFunction<any>
    percentage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsUsersResultKeySpecifier = (
    | 'activity'
    | 'frequencies'
    | 'monthlyActiveUsers'
    | AnalyticsUsersResultKeySpecifier
)[]
export type AnalyticsUsersResultFieldPolicy = {
    activity?: FieldPolicy<any> | FieldReadFunction<any>
    frequencies?: FieldPolicy<any> | FieldReadFunction<any>
    monthlyActiveUsers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuditLogColumnChangeKeySpecifier = ('column' | 'new' | 'old' | AuditLogColumnChangeKeySpecifier)[]
export type AuditLogColumnChangeFieldPolicy = {
    column?: FieldPolicy<any> | FieldReadFunction<any>
    new?: FieldPolicy<any> | FieldReadFunction<any>
    old?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthProviderKeySpecifier = (
    | 'authenticationURL'
    | 'clientID'
    | 'displayName'
    | 'isBuiltin'
    | 'serviceID'
    | 'serviceType'
    | AuthProviderKeySpecifier
)[]
export type AuthProviderFieldPolicy = {
    authenticationURL?: FieldPolicy<any> | FieldReadFunction<any>
    clientID?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    isBuiltin?: FieldPolicy<any> | FieldReadFunction<any>
    serviceID?: FieldPolicy<any> | FieldReadFunction<any>
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthProviderConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | AuthProviderConnectionKeySpecifier
)[]
export type AuthProviderConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AutocompleteMemberSearchItemKeySpecifier = (
    | 'avatarURL'
    | 'displayName'
    | 'id'
    | 'inOrg'
    | 'username'
    | AutocompleteMemberSearchItemKeySpecifier
)[]
export type AutocompleteMemberSearchItemFieldPolicy = {
    avatarURL?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    inOrg?: FieldPolicy<any> | FieldReadFunction<any>
    username?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BackfillStatusKeySpecifier = (
    | 'completedAt'
    | 'cost'
    | 'createdAt'
    | 'errors'
    | 'percentComplete'
    | 'queuePosition'
    | 'runtime'
    | 'startedAt'
    | 'state'
    | BackfillStatusKeySpecifier
)[]
export type BackfillStatusFieldPolicy = {
    completedAt?: FieldPolicy<any> | FieldReadFunction<any>
    cost?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    errors?: FieldPolicy<any> | FieldReadFunction<any>
    percentComplete?: FieldPolicy<any> | FieldReadFunction<any>
    queuePosition?: FieldPolicy<any> | FieldReadFunction<any>
    runtime?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BackgroundJobKeySpecifier = ('id' | 'name' | 'routines' | BackgroundJobKeySpecifier)[]
export type BackgroundJobFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    routines?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BackgroundJobConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | BackgroundJobConnectionKeySpecifier
)[]
export type BackgroundJobConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BackgroundRoutineKeySpecifier = (
    | 'description'
    | 'instances'
    | 'intervalMs'
    | 'name'
    | 'recentRuns'
    | 'stats'
    | 'type'
    | BackgroundRoutineKeySpecifier
)[]
export type BackgroundRoutineFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>
    instances?: FieldPolicy<any> | FieldReadFunction<any>
    intervalMs?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    recentRuns?: FieldPolicy<any> | FieldReadFunction<any>
    stats?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BackgroundRoutineInstanceKeySpecifier = (
    | 'hostName'
    | 'lastStartedAt'
    | 'lastStoppedAt'
    | BackgroundRoutineInstanceKeySpecifier
)[]
export type BackgroundRoutineInstanceFieldPolicy = {
    hostName?: FieldPolicy<any> | FieldReadFunction<any>
    lastStartedAt?: FieldPolicy<any> | FieldReadFunction<any>
    lastStoppedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BackgroundRoutineRecentRunKeySpecifier = (
    | 'at'
    | 'durationMs'
    | 'errorMessage'
    | 'hostName'
    | BackgroundRoutineRecentRunKeySpecifier
)[]
export type BackgroundRoutineRecentRunFieldPolicy = {
    at?: FieldPolicy<any> | FieldReadFunction<any>
    durationMs?: FieldPolicy<any> | FieldReadFunction<any>
    errorMessage?: FieldPolicy<any> | FieldReadFunction<any>
    hostName?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BackgroundRoutineStatsKeySpecifier = (
    | 'avgDurationMs'
    | 'errorCount'
    | 'maxDurationMs'
    | 'minDurationMs'
    | 'runCount'
    | 'since'
    | BackgroundRoutineStatsKeySpecifier
)[]
export type BackgroundRoutineStatsFieldPolicy = {
    avgDurationMs?: FieldPolicy<any> | FieldReadFunction<any>
    errorCount?: FieldPolicy<any> | FieldReadFunction<any>
    maxDurationMs?: FieldPolicy<any> | FieldReadFunction<any>
    minDurationMs?: FieldPolicy<any> | FieldReadFunction<any>
    runCount?: FieldPolicy<any> | FieldReadFunction<any>
    since?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangeKeySpecifier = (
    | 'batchSpecs'
    | 'bulkOperations'
    | 'changesetCountsOverTime'
    | 'changesets'
    | 'changesetsStats'
    | 'closedAt'
    | 'createdAt'
    | 'creator'
    | 'currentSpec'
    | 'description'
    | 'diffStat'
    | 'id'
    | 'lastAppliedAt'
    | 'lastApplier'
    | 'name'
    | 'namespace'
    | 'state'
    | 'updatedAt'
    | 'url'
    | 'viewerCanAdminister'
    | BatchChangeKeySpecifier
)[]
export type BatchChangeFieldPolicy = {
    batchSpecs?: FieldPolicy<any> | FieldReadFunction<any>
    bulkOperations?: FieldPolicy<any> | FieldReadFunction<any>
    changesetCountsOverTime?: FieldPolicy<any> | FieldReadFunction<any>
    changesets?: FieldPolicy<any> | FieldReadFunction<any>
    changesetsStats?: FieldPolicy<any> | FieldReadFunction<any>
    closedAt?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    currentSpec?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    lastAppliedAt?: FieldPolicy<any> | FieldReadFunction<any>
    lastApplier?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangeConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | BatchChangeConnectionKeySpecifier
)[]
export type BatchChangeConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangeDescriptionKeySpecifier = ('description' | 'name' | BatchChangeDescriptionKeySpecifier)[]
export type BatchChangeDescriptionFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangesCodeHostKeySpecifier = (
    | 'credential'
    | 'externalServiceKind'
    | 'externalServiceURL'
    | 'hasWebhooks'
    | 'requiresSSH'
    | 'requiresUsername'
    | BatchChangesCodeHostKeySpecifier
)[]
export type BatchChangesCodeHostFieldPolicy = {
    credential?: FieldPolicy<any> | FieldReadFunction<any>
    externalServiceKind?: FieldPolicy<any> | FieldReadFunction<any>
    externalServiceURL?: FieldPolicy<any> | FieldReadFunction<any>
    hasWebhooks?: FieldPolicy<any> | FieldReadFunction<any>
    requiresSSH?: FieldPolicy<any> | FieldReadFunction<any>
    requiresUsername?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangesCodeHostConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | BatchChangesCodeHostConnectionKeySpecifier
)[]
export type BatchChangesCodeHostConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangesCredentialKeySpecifier = (
    | 'createdAt'
    | 'externalServiceKind'
    | 'externalServiceURL'
    | 'id'
    | 'isSiteCredential'
    | 'sshPublicKey'
    | BatchChangesCredentialKeySpecifier
)[]
export type BatchChangesCredentialFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    externalServiceKind?: FieldPolicy<any> | FieldReadFunction<any>
    externalServiceURL?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    isSiteCredential?: FieldPolicy<any> | FieldReadFunction<any>
    sshPublicKey?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecKeySpecifier = (
    | 'allowIgnored'
    | 'allowUnsupported'
    | 'appliesToBatchChange'
    | 'applyPreview'
    | 'applyURL'
    | 'autoApplyEnabled'
    | 'changesetSpecs'
    | 'createdAt'
    | 'creator'
    | 'description'
    | 'diffStat'
    | 'expiresAt'
    | 'failureMessage'
    | 'files'
    | 'finishedAt'
    | 'id'
    | 'importingChangesets'
    | 'namespace'
    | 'noCache'
    | 'originalInput'
    | 'parsedInput'
    | 'source'
    | 'startedAt'
    | 'state'
    | 'supersedingBatchSpec'
    | 'viewerBatchChangesCodeHosts'
    | 'viewerCanAdminister'
    | 'viewerCanRetry'
    | 'workspaceResolution'
    | BatchSpecKeySpecifier
)[]
export type BatchSpecFieldPolicy = {
    allowIgnored?: FieldPolicy<any> | FieldReadFunction<any>
    allowUnsupported?: FieldPolicy<any> | FieldReadFunction<any>
    appliesToBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    applyPreview?: FieldPolicy<any> | FieldReadFunction<any>
    applyURL?: FieldPolicy<any> | FieldReadFunction<any>
    autoApplyEnabled?: FieldPolicy<any> | FieldReadFunction<any>
    changesetSpecs?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    files?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    importingChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    noCache?: FieldPolicy<any> | FieldReadFunction<any>
    originalInput?: FieldPolicy<any> | FieldReadFunction<any>
    parsedInput?: FieldPolicy<any> | FieldReadFunction<any>
    source?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    supersedingBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    viewerBatchChangesCodeHosts?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanRetry?: FieldPolicy<any> | FieldReadFunction<any>
    workspaceResolution?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | BatchSpecConnectionKeySpecifier)[]
export type BatchSpecConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceKeySpecifier = (
    | 'batchSpec'
    | 'cachedResultFound'
    | 'diffStat'
    | 'finishedAt'
    | 'id'
    | 'ignored'
    | 'onlyFetchWorkspace'
    | 'placeInGlobalQueue'
    | 'placeInQueue'
    | 'queuedAt'
    | 'startedAt'
    | 'state'
    | 'stepCacheResultCount'
    | 'unsupported'
    | BatchSpecWorkspaceKeySpecifier
)[]
export type BatchSpecWorkspaceFieldPolicy = {
    batchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    cachedResultFound?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    onlyFetchWorkspace?: FieldPolicy<any> | FieldReadFunction<any>
    placeInGlobalQueue?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    stepCacheResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    unsupported?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'stats'
    | 'totalCount'
    | BatchSpecWorkspaceConnectionKeySpecifier
)[]
export type BatchSpecWorkspaceConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    stats?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceEnvironmentVariableKeySpecifier = (
    | 'name'
    | 'value'
    | BatchSpecWorkspaceEnvironmentVariableKeySpecifier
)[]
export type BatchSpecWorkspaceEnvironmentVariableFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceFileKeySpecifier = (
    | 'binary'
    | 'byteSize'
    | 'canonicalURL'
    | 'content'
    | 'createdAt'
    | 'externalURLs'
    | 'highlight'
    | 'id'
    | 'isDirectory'
    | 'modifiedAt'
    | 'name'
    | 'path'
    | 'richHTML'
    | 'totalLines'
    | 'updatedAt'
    | 'url'
    | BatchSpecWorkspaceFileKeySpecifier
)[]
export type BatchSpecWorkspaceFileFieldPolicy = {
    binary?: FieldPolicy<any> | FieldReadFunction<any>
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    modifiedAt?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    richHTML?: FieldPolicy<any> | FieldReadFunction<any>
    totalLines?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceFileConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | BatchSpecWorkspaceFileConnectionKeySpecifier
)[]
export type BatchSpecWorkspaceFileConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceOutputVariableKeySpecifier = (
    | 'name'
    | 'value'
    | BatchSpecWorkspaceOutputVariableKeySpecifier
)[]
export type BatchSpecWorkspaceOutputVariableFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceResolutionKeySpecifier = (
    | 'failureMessage'
    | 'finishedAt'
    | 'recentlyCompleted'
    | 'recentlyErrored'
    | 'startedAt'
    | 'state'
    | 'workspaces'
    | BatchSpecWorkspaceResolutionKeySpecifier
)[]
export type BatchSpecWorkspaceResolutionFieldPolicy = {
    failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    recentlyCompleted?: FieldPolicy<any> | FieldReadFunction<any>
    recentlyErrored?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    workspaces?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspacesStatsKeySpecifier = (
    | 'completed'
    | 'errored'
    | 'ignored'
    | 'processing'
    | 'queued'
    | BatchSpecWorkspacesStatsKeySpecifier
)[]
export type BatchSpecWorkspacesStatsFieldPolicy = {
    completed?: FieldPolicy<any> | FieldReadFunction<any>
    errored?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    processing?: FieldPolicy<any> | FieldReadFunction<any>
    queued?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceStagesKeySpecifier = (
    | 'setup'
    | 'srcExec'
    | 'teardown'
    | BatchSpecWorkspaceStagesKeySpecifier
)[]
export type BatchSpecWorkspaceStagesFieldPolicy = {
    setup?: FieldPolicy<any> | FieldReadFunction<any>
    srcExec?: FieldPolicy<any> | FieldReadFunction<any>
    teardown?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceStepKeySpecifier = (
    | 'cachedResultFound'
    | 'container'
    | 'diff'
    | 'diffStat'
    | 'environment'
    | 'exitCode'
    | 'finishedAt'
    | 'ifCondition'
    | 'number'
    | 'outputLines'
    | 'outputVariables'
    | 'run'
    | 'skipped'
    | 'startedAt'
    | BatchSpecWorkspaceStepKeySpecifier
)[]
export type BatchSpecWorkspaceStepFieldPolicy = {
    cachedResultFound?: FieldPolicy<any> | FieldReadFunction<any>
    container?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    environment?: FieldPolicy<any> | FieldReadFunction<any>
    exitCode?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    ifCondition?: FieldPolicy<any> | FieldReadFunction<any>
    number?: FieldPolicy<any> | FieldReadFunction<any>
    outputLines?: FieldPolicy<any> | FieldReadFunction<any>
    outputVariables?: FieldPolicy<any> | FieldReadFunction<any>
    run?: FieldPolicy<any> | FieldReadFunction<any>
    skipped?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceStepOutputLineConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | BatchSpecWorkspaceStepOutputLineConnectionKeySpecifier
)[]
export type BatchSpecWorkspaceStepOutputLineConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BehindAheadCountsKeySpecifier = ('ahead' | 'behind' | BehindAheadCountsKeySpecifier)[]
export type BehindAheadCountsFieldPolicy = {
    ahead?: FieldPolicy<any> | FieldReadFunction<any>
    behind?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BidirectionalPageInfoKeySpecifier = (
    | 'endCursor'
    | 'hasNextPage'
    | 'hasPreviousPage'
    | 'startCursor'
    | BidirectionalPageInfoKeySpecifier
)[]
export type BidirectionalPageInfoFieldPolicy = {
    endCursor?: FieldPolicy<any> | FieldReadFunction<any>
    hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>
    hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>
    startCursor?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BitbucketProjectPermissionJobKeySpecifier = (
    | 'ExternalServiceID'
    | 'FailureMessage'
    | 'FinishedAt'
    | 'InternalJobID'
    | 'NumFailures'
    | 'NumResets'
    | 'Permissions'
    | 'ProcessAfter'
    | 'ProjectKey'
    | 'QueuedAt'
    | 'StartedAt'
    | 'State'
    | 'Unrestricted'
    | BitbucketProjectPermissionJobKeySpecifier
)[]
export type BitbucketProjectPermissionJobFieldPolicy = {
    ExternalServiceID?: FieldPolicy<any> | FieldReadFunction<any>
    FailureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    FinishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    InternalJobID?: FieldPolicy<any> | FieldReadFunction<any>
    NumFailures?: FieldPolicy<any> | FieldReadFunction<any>
    NumResets?: FieldPolicy<any> | FieldReadFunction<any>
    Permissions?: FieldPolicy<any> | FieldReadFunction<any>
    ProcessAfter?: FieldPolicy<any> | FieldReadFunction<any>
    ProjectKey?: FieldPolicy<any> | FieldReadFunction<any>
    QueuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    StartedAt?: FieldPolicy<any> | FieldReadFunction<any>
    State?: FieldPolicy<any> | FieldReadFunction<any>
    Unrestricted?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BitbucketProjectPermissionJobsKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | BitbucketProjectPermissionJobsKeySpecifier
)[]
export type BitbucketProjectPermissionJobsFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BulkOperationKeySpecifier = (
    | 'changesetCount'
    | 'createdAt'
    | 'errors'
    | 'finishedAt'
    | 'id'
    | 'initiator'
    | 'progress'
    | 'state'
    | 'type'
    | BulkOperationKeySpecifier
)[]
export type BulkOperationFieldPolicy = {
    changesetCount?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    errors?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    initiator?: FieldPolicy<any> | FieldReadFunction<any>
    progress?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BulkOperationConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | BulkOperationConnectionKeySpecifier
)[]
export type BulkOperationConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetKeySpecifier = (
    | 'batchChanges'
    | 'createdAt'
    | 'id'
    | 'nextSyncAt'
    | 'state'
    | 'updatedAt'
    | ChangesetKeySpecifier
)[]
export type ChangesetFieldPolicy = {
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    nextSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetApplyPreviewConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'stats'
    | 'totalCount'
    | ChangesetApplyPreviewConnectionKeySpecifier
)[]
export type ChangesetApplyPreviewConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    stats?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetApplyPreviewConnectionStatsKeySpecifier = (
    | 'added'
    | 'archive'
    | 'close'
    | 'detach'
    | 'import'
    | 'modified'
    | 'publish'
    | 'publishDraft'
    | 'push'
    | 'reattach'
    | 'removed'
    | 'reopen'
    | 'sleep'
    | 'sync'
    | 'undraft'
    | 'update'
    | ChangesetApplyPreviewConnectionStatsKeySpecifier
)[]
export type ChangesetApplyPreviewConnectionStatsFieldPolicy = {
    added?: FieldPolicy<any> | FieldReadFunction<any>
    archive?: FieldPolicy<any> | FieldReadFunction<any>
    close?: FieldPolicy<any> | FieldReadFunction<any>
    detach?: FieldPolicy<any> | FieldReadFunction<any>
    import?: FieldPolicy<any> | FieldReadFunction<any>
    modified?: FieldPolicy<any> | FieldReadFunction<any>
    publish?: FieldPolicy<any> | FieldReadFunction<any>
    publishDraft?: FieldPolicy<any> | FieldReadFunction<any>
    push?: FieldPolicy<any> | FieldReadFunction<any>
    reattach?: FieldPolicy<any> | FieldReadFunction<any>
    removed?: FieldPolicy<any> | FieldReadFunction<any>
    reopen?: FieldPolicy<any> | FieldReadFunction<any>
    sleep?: FieldPolicy<any> | FieldReadFunction<any>
    sync?: FieldPolicy<any> | FieldReadFunction<any>
    undraft?: FieldPolicy<any> | FieldReadFunction<any>
    update?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | ChangesetConnectionKeySpecifier)[]
export type ChangesetConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetCountsKeySpecifier = (
    | 'closed'
    | 'date'
    | 'draft'
    | 'merged'
    | 'open'
    | 'openApproved'
    | 'openChangesRequested'
    | 'openPending'
    | 'total'
    | ChangesetCountsKeySpecifier
)[]
export type ChangesetCountsFieldPolicy = {
    closed?: FieldPolicy<any> | FieldReadFunction<any>
    date?: FieldPolicy<any> | FieldReadFunction<any>
    draft?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    open?: FieldPolicy<any> | FieldReadFunction<any>
    openApproved?: FieldPolicy<any> | FieldReadFunction<any>
    openChangesRequested?: FieldPolicy<any> | FieldReadFunction<any>
    openPending?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetEventKeySpecifier = ('changeset' | 'createdAt' | 'id' | ChangesetEventKeySpecifier)[]
export type ChangesetEventFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetEventConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ChangesetEventConnectionKeySpecifier
)[]
export type ChangesetEventConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetJobErrorKeySpecifier = ('changeset' | 'error' | ChangesetJobErrorKeySpecifier)[]
export type ChangesetJobErrorFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
    error?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetLabelKeySpecifier = ('color' | 'description' | 'text' | ChangesetLabelKeySpecifier)[]
export type ChangesetLabelFieldPolicy = {
    color?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetSpecKeySpecifier = ('expiresAt' | 'id' | 'type' | ChangesetSpecKeySpecifier)[]
export type ChangesetSpecFieldPolicy = {
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetSpecConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ChangesetSpecConnectionKeySpecifier
)[]
export type ChangesetSpecConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetSpecDeltaKeySpecifier = (
    | 'authorEmailChanged'
    | 'authorNameChanged'
    | 'baseRefChanged'
    | 'bodyChanged'
    | 'commitMessageChanged'
    | 'diffChanged'
    | 'titleChanged'
    | 'undraft'
    | ChangesetSpecDeltaKeySpecifier
)[]
export type ChangesetSpecDeltaFieldPolicy = {
    authorEmailChanged?: FieldPolicy<any> | FieldReadFunction<any>
    authorNameChanged?: FieldPolicy<any> | FieldReadFunction<any>
    baseRefChanged?: FieldPolicy<any> | FieldReadFunction<any>
    bodyChanged?: FieldPolicy<any> | FieldReadFunction<any>
    commitMessageChanged?: FieldPolicy<any> | FieldReadFunction<any>
    diffChanged?: FieldPolicy<any> | FieldReadFunction<any>
    titleChanged?: FieldPolicy<any> | FieldReadFunction<any>
    undraft?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetsStatsKeySpecifier = (
    | 'archived'
    | 'closed'
    | 'deleted'
    | 'draft'
    | 'failed'
    | 'merged'
    | 'open'
    | 'processing'
    | 'retrying'
    | 'scheduled'
    | 'total'
    | 'unpublished'
    | ChangesetsStatsKeySpecifier
)[]
export type ChangesetsStatsFieldPolicy = {
    archived?: FieldPolicy<any> | FieldReadFunction<any>
    closed?: FieldPolicy<any> | FieldReadFunction<any>
    deleted?: FieldPolicy<any> | FieldReadFunction<any>
    draft?: FieldPolicy<any> | FieldReadFunction<any>
    failed?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    open?: FieldPolicy<any> | FieldReadFunction<any>
    processing?: FieldPolicy<any> | FieldReadFunction<any>
    retrying?: FieldPolicy<any> | FieldReadFunction<any>
    scheduled?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
    unpublished?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CheckMirrorRepositoryConnectionResultKeySpecifier = (
    | 'error'
    | CheckMirrorRepositoryConnectionResultKeySpecifier
)[]
export type CheckMirrorRepositoryConnectionResultFieldPolicy = {
    error?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChunkMatchKeySpecifier = ('content' | 'contentStart' | 'ranges' | ChunkMatchKeySpecifier)[]
export type ChunkMatchFieldPolicy = {
    content?: FieldPolicy<any> | FieldReadFunction<any>
    contentStart?: FieldPolicy<any> | FieldReadFunction<any>
    ranges?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ClientConfigurationDetailsKeySpecifier = (
    | 'contentScriptUrls'
    | 'parentSourcegraph'
    | ClientConfigurationDetailsKeySpecifier
)[]
export type ClientConfigurationDetailsFieldPolicy = {
    contentScriptUrls?: FieldPolicy<any> | FieldReadFunction<any>
    parentSourcegraph?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CloningProgressKeySpecifier = ('message' | CloningProgressKeySpecifier)[]
export type CloningProgressFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelCommitKeySpecifier = (
    | 'abbreviatedOID'
    | 'id'
    | 'oid'
    | 'repository'
    | 'url'
    | CodeIntelCommitKeySpecifier
)[]
export type CodeIntelCommitFieldPolicy = {
    abbreviatedOID?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    oid?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelExternalRepositoryKeySpecifier = (
    | 'serviceID'
    | 'serviceType'
    | CodeIntelExternalRepositoryKeySpecifier
)[]
export type CodeIntelExternalRepositoryFieldPolicy = {
    serviceID?: FieldPolicy<any> | FieldReadFunction<any>
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelGitBlobKeySpecifier = (
    | 'commit'
    | 'content'
    | 'name'
    | 'path'
    | 'repository'
    | 'url'
    | CodeIntelGitBlobKeySpecifier
)[]
export type CodeIntelGitBlobFieldPolicy = {
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelGitObjectKeySpecifier = ('committedAt' | 'name' | 'rev' | CodeIntelGitObjectKeySpecifier)[]
export type CodeIntelGitObjectFieldPolicy = {
    committedAt?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    rev?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelGitTreeKeySpecifier = (
    | 'commit'
    | 'content'
    | 'name'
    | 'path'
    | 'repository'
    | 'url'
    | CodeIntelGitTreeKeySpecifier
)[]
export type CodeIntelGitTreeFieldPolicy = {
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelIndexerKeySpecifier = ('name' | 'url' | CodeIntelIndexerKeySpecifier)[]
export type CodeIntelIndexerFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceCommitGraphKeySpecifier = (
    | 'stale'
    | 'updatedAt'
    | CodeIntelligenceCommitGraphKeySpecifier
)[]
export type CodeIntelligenceCommitGraphFieldPolicy = {
    stale?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceConfigurationPolicyKeySpecifier = (
    | 'id'
    | 'indexCommitMaxAgeHours'
    | 'indexingEnabled'
    | 'indexIntermediateCommits'
    | 'name'
    | 'pattern'
    | 'protected'
    | 'repository'
    | 'repositoryPatterns'
    | 'retainIntermediateCommits'
    | 'retentionDurationHours'
    | 'retentionEnabled'
    | 'type'
    | CodeIntelligenceConfigurationPolicyKeySpecifier
)[]
export type CodeIntelligenceConfigurationPolicyFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    indexCommitMaxAgeHours?: FieldPolicy<any> | FieldReadFunction<any>
    indexingEnabled?: FieldPolicy<any> | FieldReadFunction<any>
    indexIntermediateCommits?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    pattern?: FieldPolicy<any> | FieldReadFunction<any>
    protected?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryPatterns?: FieldPolicy<any> | FieldReadFunction<any>
    retainIntermediateCommits?: FieldPolicy<any> | FieldReadFunction<any>
    retentionDurationHours?: FieldPolicy<any> | FieldReadFunction<any>
    retentionEnabled?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceConfigurationPolicyConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | CodeIntelligenceConfigurationPolicyConnectionKeySpecifier
)[]
export type CodeIntelligenceConfigurationPolicyConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceRangeKeySpecifier = (
    | 'definitions'
    | 'hover'
    | 'implementations'
    | 'range'
    | 'references'
    | CodeIntelligenceRangeKeySpecifier
)[]
export type CodeIntelligenceRangeFieldPolicy = {
    definitions?: FieldPolicy<any> | FieldReadFunction<any>
    hover?: FieldPolicy<any> | FieldReadFunction<any>
    implementations?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
    references?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceRangeConnectionKeySpecifier = ('nodes' | CodeIntelligenceRangeConnectionKeySpecifier)[]
export type CodeIntelligenceRangeConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceRetentionPolicyMatchKeySpecifier = (
    | 'configurationPolicy'
    | 'matches'
    | 'protectingCommits'
    | CodeIntelligenceRetentionPolicyMatchKeySpecifier
)[]
export type CodeIntelligenceRetentionPolicyMatchFieldPolicy = {
    configurationPolicy?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
    protectingCommits?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceRetentionPolicyMatchesConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | CodeIntelligenceRetentionPolicyMatchesConnectionKeySpecifier
)[]
export type CodeIntelligenceRetentionPolicyMatchesConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelRepositoryKeySpecifier = (
    | 'externalRepository'
    | 'id'
    | 'name'
    | 'url'
    | CodeIntelRepositoryKeySpecifier
)[]
export type CodeIntelRepositoryFieldPolicy = {
    externalRepository?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelRepositorySummaryKeySpecifier = (
    | 'availableIndexers'
    | 'lastIndexScan'
    | 'lastUploadRetentionScan'
    | 'recentIndexes'
    | 'recentUploads'
    | CodeIntelRepositorySummaryKeySpecifier
)[]
export type CodeIntelRepositorySummaryFieldPolicy = {
    availableIndexers?: FieldPolicy<any> | FieldReadFunction<any>
    lastIndexScan?: FieldPolicy<any> | FieldReadFunction<any>
    lastUploadRetentionScan?: FieldPolicy<any> | FieldReadFunction<any>
    recentIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    recentUploads?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelSupportKeySpecifier = ('preciseSupport' | 'searchBasedSupport' | CodeIntelSupportKeySpecifier)[]
export type CodeIntelSupportFieldPolicy = {
    preciseSupport?: FieldPolicy<any> | FieldReadFunction<any>
    searchBasedSupport?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeownersFileEntryKeySpecifier = (
    | 'codeownersFile'
    | 'description'
    | 'ruleLineMatch'
    | 'title'
    | CodeownersFileEntryKeySpecifier
)[]
export type CodeownersFileEntryFieldPolicy = {
    codeownersFile?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    ruleLineMatch?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CommitSearchResultKeySpecifier = (
    | 'commit'
    | 'detail'
    | 'diffPreview'
    | 'label'
    | 'matches'
    | 'messagePreview'
    | 'refs'
    | 'sourceRefs'
    | 'url'
    | CommitSearchResultKeySpecifier
)[]
export type CommitSearchResultFieldPolicy = {
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    detail?: FieldPolicy<any> | FieldReadFunction<any>
    diffPreview?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
    messagePreview?: FieldPolicy<any> | FieldReadFunction<any>
    refs?: FieldPolicy<any> | FieldReadFunction<any>
    sourceRefs?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeEnvironmentEntryKeySpecifier = (
    | 'range'
    | 'value'
    | 'variable'
    | ComputeEnvironmentEntryKeySpecifier
)[]
export type ComputeEnvironmentEntryFieldPolicy = {
    range?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
    variable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeMatchKeySpecifier = ('environment' | 'range' | 'value' | ComputeMatchKeySpecifier)[]
export type ComputeMatchFieldPolicy = {
    environment?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeMatchContextKeySpecifier = (
    | 'commit'
    | 'matches'
    | 'path'
    | 'repository'
    | ComputeMatchContextKeySpecifier
)[]
export type ComputeMatchContextFieldPolicy = {
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeTextKeySpecifier = ('commit' | 'kind' | 'path' | 'repository' | 'value' | ComputeTextKeySpecifier)[]
export type ComputeTextFieldPolicy = {
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConfigurationKeySpecifier = ('contents' | 'messages' | ConfigurationKeySpecifier)[]
export type ConfigurationFieldPolicy = {
    contents?: FieldPolicy<any> | FieldReadFunction<any>
    messages?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConfigurationCascadeKeySpecifier = ('merged' | 'subjects' | ConfigurationCascadeKeySpecifier)[]
export type ConfigurationCascadeFieldPolicy = {
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    subjects?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConnectionKeySpecifier = ('pageInfo' | 'totalCount' | ConnectionKeySpecifier)[]
export type ConnectionFieldPolicy = {
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConnectionPageInfoKeySpecifier = (
    | 'endCursor'
    | 'hasNextPage'
    | 'hasPreviousPage'
    | 'startCursor'
    | ConnectionPageInfoKeySpecifier
)[]
export type ConnectionPageInfoFieldPolicy = {
    endCursor?: FieldPolicy<any> | FieldReadFunction<any>
    hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>
    hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>
    startCursor?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreateAccessTokenResultKeySpecifier = ('id' | 'token' | CreateAccessTokenResultKeySpecifier)[]
export type CreateAccessTokenResultFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    token?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreateUserResultKeySpecifier = ('resetPasswordURL' | 'user' | CreateUserResultKeySpecifier)[]
export type CreateUserResultFieldPolicy = {
    resetPasswordURL?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DefaultSettingsKeySpecifier = (
    | 'configurationCascade'
    | 'id'
    | 'latestSettings'
    | 'settingsCascade'
    | 'settingsURL'
    | 'viewerCanAdminister'
    | DefaultSettingsKeySpecifier
)[]
export type DefaultSettingsFieldPolicy = {
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DiagnosticKeySpecifier = (
    | 'code'
    | 'location'
    | 'message'
    | 'severity'
    | 'source'
    | DiagnosticKeySpecifier
)[]
export type DiagnosticFieldPolicy = {
    code?: FieldPolicy<any> | FieldReadFunction<any>
    location?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    severity?: FieldPolicy<any> | FieldReadFunction<any>
    source?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DiagnosticConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | DiagnosticConnectionKeySpecifier
)[]
export type DiagnosticConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DiffKeySpecifier = ('range' | 'repository' | DiffKeySpecifier)[]
export type DiffFieldPolicy = {
    range?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DiffStatKeySpecifier = ('added' | 'deleted' | DiffStatKeySpecifier)[]
export type DiffStatFieldPolicy = {
    added?: FieldPolicy<any> | FieldReadFunction<any>
    deleted?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DotcomMutationKeySpecifier = (
    | 'archiveProductSubscription'
    | 'createProductSubscription'
    | 'generateProductLicenseForSubscription'
    | DotcomMutationKeySpecifier
)[]
export type DotcomMutationFieldPolicy = {
    archiveProductSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    createProductSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    generateProductLicenseForSubscription?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DotcomQueryKeySpecifier = (
    | 'productLicenses'
    | 'productSubscription'
    | 'productSubscriptions'
    | DotcomQueryKeySpecifier
)[]
export type DotcomQueryFieldPolicy = {
    productLicenses?: FieldPolicy<any> | FieldReadFunction<any>
    productSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    productSubscriptions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EmptyResponseKeySpecifier = ('alwaysNil' | EmptyResponseKeySpecifier)[]
export type EmptyResponseFieldPolicy = {
    alwaysNil?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ErrorKeySpecifier = ('extensions' | 'message' | 'path' | ErrorKeySpecifier)[]
export type ErrorFieldPolicy = {
    extensions?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ErrorExtensionsKeySpecifier = ('code' | ErrorExtensionsKeySpecifier)[]
export type ErrorExtensionsFieldPolicy = {
    code?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EvaluatedFeatureFlagKeySpecifier = ('name' | 'value' | EvaluatedFeatureFlagKeySpecifier)[]
export type EvaluatedFeatureFlagFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EventLogKeySpecifier = (
    | 'anonymousUserID'
    | 'argument'
    | 'name'
    | 'source'
    | 'timestamp'
    | 'url'
    | 'user'
    | 'version'
    | EventLogKeySpecifier
)[]
export type EventLogFieldPolicy = {
    anonymousUserID?: FieldPolicy<any> | FieldReadFunction<any>
    argument?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    source?: FieldPolicy<any> | FieldReadFunction<any>
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EventLogsConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | EventLogsConnectionKeySpecifier)[]
export type EventLogsConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutionLogEntryKeySpecifier = (
    | 'command'
    | 'durationMilliseconds'
    | 'exitCode'
    | 'key'
    | 'out'
    | 'startTime'
    | ExecutionLogEntryKeySpecifier
)[]
export type ExecutionLogEntryFieldPolicy = {
    command?: FieldPolicy<any> | FieldReadFunction<any>
    durationMilliseconds?: FieldPolicy<any> | FieldReadFunction<any>
    exitCode?: FieldPolicy<any> | FieldReadFunction<any>
    key?: FieldPolicy<any> | FieldReadFunction<any>
    out?: FieldPolicy<any> | FieldReadFunction<any>
    startTime?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutorKeySpecifier = (
    | 'active'
    | 'architecture'
    | 'compatibility'
    | 'dockerVersion'
    | 'executorVersion'
    | 'firstSeenAt'
    | 'gitVersion'
    | 'hostname'
    | 'id'
    | 'igniteVersion'
    | 'lastSeenAt'
    | 'os'
    | 'queueName'
    | 'srcCliVersion'
    | ExecutorKeySpecifier
)[]
export type ExecutorFieldPolicy = {
    active?: FieldPolicy<any> | FieldReadFunction<any>
    architecture?: FieldPolicy<any> | FieldReadFunction<any>
    compatibility?: FieldPolicy<any> | FieldReadFunction<any>
    dockerVersion?: FieldPolicy<any> | FieldReadFunction<any>
    executorVersion?: FieldPolicy<any> | FieldReadFunction<any>
    firstSeenAt?: FieldPolicy<any> | FieldReadFunction<any>
    gitVersion?: FieldPolicy<any> | FieldReadFunction<any>
    hostname?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    igniteVersion?: FieldPolicy<any> | FieldReadFunction<any>
    lastSeenAt?: FieldPolicy<any> | FieldReadFunction<any>
    os?: FieldPolicy<any> | FieldReadFunction<any>
    queueName?: FieldPolicy<any> | FieldReadFunction<any>
    srcCliVersion?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutorConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | ExecutorConnectionKeySpecifier)[]
export type ExecutorConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutorSecretKeySpecifier = (
    | 'accessLogs'
    | 'createdAt'
    | 'creator'
    | 'id'
    | 'key'
    | 'namespace'
    | 'overwritesGlobalSecret'
    | 'scope'
    | 'updatedAt'
    | ExecutorSecretKeySpecifier
)[]
export type ExecutorSecretFieldPolicy = {
    accessLogs?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    key?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    overwritesGlobalSecret?: FieldPolicy<any> | FieldReadFunction<any>
    scope?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutorSecretAccessLogKeySpecifier = (
    | 'createdAt'
    | 'executorSecret'
    | 'id'
    | 'machineUser'
    | 'user'
    | ExecutorSecretAccessLogKeySpecifier
)[]
export type ExecutorSecretAccessLogFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    executorSecret?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    machineUser?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutorSecretAccessLogConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ExecutorSecretAccessLogConnectionKeySpecifier
)[]
export type ExecutorSecretAccessLogConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutorSecretConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ExecutorSecretConnectionKeySpecifier
)[]
export type ExecutorSecretConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExhaustiveSearchAggregationResultKeySpecifier = (
    | 'groups'
    | 'mode'
    | 'otherGroupCount'
    | 'otherResultCount'
    | 'supportsPersistence'
    | ExhaustiveSearchAggregationResultKeySpecifier
)[]
export type ExhaustiveSearchAggregationResultFieldPolicy = {
    groups?: FieldPolicy<any> | FieldReadFunction<any>
    mode?: FieldPolicy<any> | FieldReadFunction<any>
    otherGroupCount?: FieldPolicy<any> | FieldReadFunction<any>
    otherResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    supportsPersistence?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExistingChangesetReferenceKeySpecifier = (
    | 'baseRepository'
    | 'externalID'
    | ExistingChangesetReferenceKeySpecifier
)[]
export type ExistingChangesetReferenceFieldPolicy = {
    baseRepository?: FieldPolicy<any> | FieldReadFunction<any>
    externalID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExtensionManifestKeySpecifier = ('jsonFields' | 'raw' | ExtensionManifestKeySpecifier)[]
export type ExtensionManifestFieldPolicy = {
    jsonFields?: FieldPolicy<any> | FieldReadFunction<any>
    raw?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExtensionRegistryKeySpecifier = ('extensions' | ExtensionRegistryKeySpecifier)[]
export type ExtensionRegistryFieldPolicy = {
    extensions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalAccountKeySpecifier = (
    | 'accountData'
    | 'accountID'
    | 'clientID'
    | 'createdAt'
    | 'id'
    | 'publicAccountData'
    | 'refreshURL'
    | 'serviceID'
    | 'serviceType'
    | 'updatedAt'
    | 'user'
    | ExternalAccountKeySpecifier
)[]
export type ExternalAccountFieldPolicy = {
    accountData?: FieldPolicy<any> | FieldReadFunction<any>
    accountID?: FieldPolicy<any> | FieldReadFunction<any>
    clientID?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    publicAccountData?: FieldPolicy<any> | FieldReadFunction<any>
    refreshURL?: FieldPolicy<any> | FieldReadFunction<any>
    serviceID?: FieldPolicy<any> | FieldReadFunction<any>
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalAccountConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ExternalAccountConnectionKeySpecifier
)[]
export type ExternalAccountConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalChangesetKeySpecifier = (
    | 'author'
    | 'batchChanges'
    | 'body'
    | 'checkState'
    | 'createdAt'
    | 'currentSpec'
    | 'diff'
    | 'diffStat'
    | 'error'
    | 'events'
    | 'externalID'
    | 'externalURL'
    | 'forkName'
    | 'forkNamespace'
    | 'id'
    | 'labels'
    | 'nextSyncAt'
    | 'repository'
    | 'reviewState'
    | 'scheduleEstimateAt'
    | 'state'
    | 'syncerError'
    | 'title'
    | 'updatedAt'
    | ExternalChangesetKeySpecifier
)[]
export type ExternalChangesetFieldPolicy = {
    author?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    checkState?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    currentSpec?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    error?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
    externalID?: FieldPolicy<any> | FieldReadFunction<any>
    externalURL?: FieldPolicy<any> | FieldReadFunction<any>
    forkName?: FieldPolicy<any> | FieldReadFunction<any>
    forkNamespace?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    labels?: FieldPolicy<any> | FieldReadFunction<any>
    nextSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    reviewState?: FieldPolicy<any> | FieldReadFunction<any>
    scheduleEstimateAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    syncerError?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalLinkKeySpecifier = ('serviceKind' | 'serviceType' | 'url' | ExternalLinkKeySpecifier)[]
export type ExternalLinkFieldPolicy = {
    serviceKind?: FieldPolicy<any> | FieldReadFunction<any>
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalRepositoryKeySpecifier = ('id' | 'serviceID' | 'serviceType' | ExternalRepositoryKeySpecifier)[]
export type ExternalRepositoryFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    serviceID?: FieldPolicy<any> | FieldReadFunction<any>
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceKeySpecifier = (
    | 'checkConnection'
    | 'config'
    | 'createdAt'
    | 'displayName'
    | 'hasConnectionCheck'
    | 'id'
    | 'kind'
    | 'lastSyncAt'
    | 'lastSyncError'
    | 'nextSyncAt'
    | 'repoCount'
    | 'supportsRepoExclusion'
    | 'syncJobs'
    | 'updatedAt'
    | 'warning'
    | 'webhookLogs'
    | 'webhookURL'
    | ExternalServiceKeySpecifier
)[]
export type ExternalServiceFieldPolicy = {
    checkConnection?: FieldPolicy<any> | FieldReadFunction<any>
    config?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    hasConnectionCheck?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    lastSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
    lastSyncError?: FieldPolicy<any> | FieldReadFunction<any>
    nextSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
    repoCount?: FieldPolicy<any> | FieldReadFunction<any>
    supportsRepoExclusion?: FieldPolicy<any> | FieldReadFunction<any>
    syncJobs?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    warning?: FieldPolicy<any> | FieldReadFunction<any>
    webhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
    webhookURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceAvailabilityUnknownKeySpecifier = (
    | 'implementationNote'
    | ExternalServiceAvailabilityUnknownKeySpecifier
)[]
export type ExternalServiceAvailabilityUnknownFieldPolicy = {
    implementationNote?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceAvailableKeySpecifier = ('lastCheckedAt' | ExternalServiceAvailableKeySpecifier)[]
export type ExternalServiceAvailableFieldPolicy = {
    lastCheckedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ExternalServiceConnectionKeySpecifier
)[]
export type ExternalServiceConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceSyncErrorKeySpecifier = (
    | 'externalService'
    | 'message'
    | ExternalServiceSyncErrorKeySpecifier
)[]
export type ExternalServiceSyncErrorFieldPolicy = {
    externalService?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceSyncJobKeySpecifier = (
    | 'failureMessage'
    | 'finishedAt'
    | 'id'
    | 'queuedAt'
    | 'reposAdded'
    | 'reposDeleted'
    | 'reposModified'
    | 'reposSynced'
    | 'reposUnmodified'
    | 'repoSyncErrors'
    | 'startedAt'
    | 'state'
    | ExternalServiceSyncJobKeySpecifier
)[]
export type ExternalServiceSyncJobFieldPolicy = {
    failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    reposAdded?: FieldPolicy<any> | FieldReadFunction<any>
    reposDeleted?: FieldPolicy<any> | FieldReadFunction<any>
    reposModified?: FieldPolicy<any> | FieldReadFunction<any>
    reposSynced?: FieldPolicy<any> | FieldReadFunction<any>
    reposUnmodified?: FieldPolicy<any> | FieldReadFunction<any>
    repoSyncErrors?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceSyncJobConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ExternalServiceSyncJobConnectionKeySpecifier
)[]
export type ExternalServiceSyncJobConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceUnavailableKeySpecifier = ('suspectedReason' | ExternalServiceUnavailableKeySpecifier)[]
export type ExternalServiceUnavailableFieldPolicy = {
    suspectedReason?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FeatureFlagBooleanKeySpecifier = ('name' | 'overrides' | 'value' | FeatureFlagBooleanKeySpecifier)[]
export type FeatureFlagBooleanFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    overrides?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FeatureFlagOverrideKeySpecifier = (
    | 'id'
    | 'namespace'
    | 'targetFlag'
    | 'value'
    | FeatureFlagOverrideKeySpecifier
)[]
export type FeatureFlagOverrideFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    targetFlag?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FeatureFlagRolloutKeySpecifier = (
    | 'name'
    | 'overrides'
    | 'rolloutBasisPoints'
    | FeatureFlagRolloutKeySpecifier
)[]
export type FeatureFlagRolloutFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    overrides?: FieldPolicy<any> | FieldReadFunction<any>
    rolloutBasisPoints?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileKeySpecifier = ('isDirectory' | 'name' | 'path' | 'repository' | 'url' | FileKeySpecifier)[]
export type FileFieldPolicy = {
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type File2KeySpecifier = (
    | 'binary'
    | 'byteSize'
    | 'canonicalURL'
    | 'content'
    | 'externalURLs'
    | 'highlight'
    | 'isDirectory'
    | 'name'
    | 'path'
    | 'richHTML'
    | 'totalLines'
    | 'url'
    | File2KeySpecifier
)[]
export type File2FieldPolicy = {
    binary?: FieldPolicy<any> | FieldReadFunction<any>
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    richHTML?: FieldPolicy<any> | FieldReadFunction<any>
    totalLines?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileBlockKeySpecifier = ('fileInput' | 'id' | FileBlockKeySpecifier)[]
export type FileBlockFieldPolicy = {
    fileInput?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileBlockInputKeySpecifier = (
    | 'filePath'
    | 'lineRange'
    | 'repositoryName'
    | 'revision'
    | FileBlockInputKeySpecifier
)[]
export type FileBlockInputFieldPolicy = {
    filePath?: FieldPolicy<any> | FieldReadFunction<any>
    lineRange?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryName?: FieldPolicy<any> | FieldReadFunction<any>
    revision?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileBlockLineRangeKeySpecifier = ('endLine' | 'startLine' | FileBlockLineRangeKeySpecifier)[]
export type FileBlockLineRangeFieldPolicy = {
    endLine?: FieldPolicy<any> | FieldReadFunction<any>
    startLine?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileDiffKeySpecifier = (
    | 'hunks'
    | 'internalID'
    | 'mostRelevantFile'
    | 'newFile'
    | 'newPath'
    | 'oldFile'
    | 'oldPath'
    | 'stat'
    | FileDiffKeySpecifier
)[]
export type FileDiffFieldPolicy = {
    hunks?: FieldPolicy<any> | FieldReadFunction<any>
    internalID?: FieldPolicy<any> | FieldReadFunction<any>
    mostRelevantFile?: FieldPolicy<any> | FieldReadFunction<any>
    newFile?: FieldPolicy<any> | FieldReadFunction<any>
    newPath?: FieldPolicy<any> | FieldReadFunction<any>
    oldFile?: FieldPolicy<any> | FieldReadFunction<any>
    oldPath?: FieldPolicy<any> | FieldReadFunction<any>
    stat?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileDiffConnectionKeySpecifier = (
    | 'diffStat'
    | 'nodes'
    | 'pageInfo'
    | 'rawDiff'
    | 'totalCount'
    | FileDiffConnectionKeySpecifier
)[]
export type FileDiffConnectionFieldPolicy = {
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    rawDiff?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileDiffHunkKeySpecifier = (
    | 'body'
    | 'highlight'
    | 'newRange'
    | 'oldNoNewlineAt'
    | 'oldRange'
    | 'section'
    | FileDiffHunkKeySpecifier
)[]
export type FileDiffHunkFieldPolicy = {
    body?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
    newRange?: FieldPolicy<any> | FieldReadFunction<any>
    oldNoNewlineAt?: FieldPolicy<any> | FieldReadFunction<any>
    oldRange?: FieldPolicy<any> | FieldReadFunction<any>
    section?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileDiffHunkRangeKeySpecifier = ('lines' | 'startLine' | FileDiffHunkRangeKeySpecifier)[]
export type FileDiffHunkRangeFieldPolicy = {
    lines?: FieldPolicy<any> | FieldReadFunction<any>
    startLine?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileMatchKeySpecifier = (
    | 'chunkMatches'
    | 'file'
    | 'limitHit'
    | 'lineMatches'
    | 'repository'
    | 'revSpec'
    | 'symbols'
    | FileMatchKeySpecifier
)[]
export type FileMatchFieldPolicy = {
    chunkMatches?: FieldPolicy<any> | FieldReadFunction<any>
    file?: FieldPolicy<any> | FieldReadFunction<any>
    limitHit?: FieldPolicy<any> | FieldReadFunction<any>
    lineMatches?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    revSpec?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ForkTargetKeySpecifier = ('namespace' | 'pushUser' | ForkTargetKeySpecifier)[]
export type ForkTargetFieldPolicy = {
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    pushUser?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GenericIncompleteDatapointAlertKeySpecifier = (
    | 'reason'
    | 'time'
    | GenericIncompleteDatapointAlertKeySpecifier
)[]
export type GenericIncompleteDatapointAlertFieldPolicy = {
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GenericSearchResultInterfaceKeySpecifier = (
    | 'detail'
    | 'label'
    | 'matches'
    | 'url'
    | GenericSearchResultInterfaceKeySpecifier
)[]
export type GenericSearchResultInterfaceFieldPolicy = {
    detail?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitBlobKeySpecifier = (
    | 'binary'
    | 'blame'
    | 'byteSize'
    | 'canonicalURL'
    | 'codeIntelSupport'
    | 'commit'
    | 'content'
    | 'externalURLs'
    | 'highlight'
    | 'isDirectory'
    | 'isSingleChild'
    | 'lfs'
    | 'localCodeIntel'
    | 'lsif'
    | 'name'
    | 'ownership'
    | 'path'
    | 'repository'
    | 'richHTML'
    | 'submodule'
    | 'symbol'
    | 'symbolInfo'
    | 'symbols'
    | 'totalLines'
    | 'url'
    | GitBlobKeySpecifier
)[]
export type GitBlobFieldPolicy = {
    binary?: FieldPolicy<any> | FieldReadFunction<any>
    blame?: FieldPolicy<any> | FieldReadFunction<any>
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelSupport?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    isSingleChild?: FieldPolicy<any> | FieldReadFunction<any>
    lfs?: FieldPolicy<any> | FieldReadFunction<any>
    localCodeIntel?: FieldPolicy<any> | FieldReadFunction<any>
    lsif?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    ownership?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    richHTML?: FieldPolicy<any> | FieldReadFunction<any>
    submodule?: FieldPolicy<any> | FieldReadFunction<any>
    symbol?: FieldPolicy<any> | FieldReadFunction<any>
    symbolInfo?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    totalLines?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitBlobLSIFDataKeySpecifier = (
    | 'definitions'
    | 'diagnostics'
    | 'hover'
    | 'implementations'
    | 'lsifUploads'
    | 'ranges'
    | 'references'
    | 'stencil'
    | GitBlobLSIFDataKeySpecifier
)[]
export type GitBlobLSIFDataFieldPolicy = {
    definitions?: FieldPolicy<any> | FieldReadFunction<any>
    diagnostics?: FieldPolicy<any> | FieldReadFunction<any>
    hover?: FieldPolicy<any> | FieldReadFunction<any>
    implementations?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
    ranges?: FieldPolicy<any> | FieldReadFunction<any>
    references?: FieldPolicy<any> | FieldReadFunction<any>
    stencil?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitBranchChangesetDescriptionKeySpecifier = (
    | 'baseRef'
    | 'baseRepository'
    | 'baseRev'
    | 'body'
    | 'commits'
    | 'diff'
    | 'diffStat'
    | 'headRef'
    | 'published'
    | 'title'
    | GitBranchChangesetDescriptionKeySpecifier
)[]
export type GitBranchChangesetDescriptionFieldPolicy = {
    baseRef?: FieldPolicy<any> | FieldReadFunction<any>
    baseRepository?: FieldPolicy<any> | FieldReadFunction<any>
    baseRev?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    commits?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    headRef?: FieldPolicy<any> | FieldReadFunction<any>
    published?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitCommitKeySpecifier = (
    | 'abbreviatedOID'
    | 'ancestors'
    | 'author'
    | 'behindAhead'
    | 'blob'
    | 'body'
    | 'canonicalURL'
    | 'committer'
    | 'diff'
    | 'externalURLs'
    | 'file'
    | 'fileNames'
    | 'id'
    | 'languages'
    | 'languageStatistics'
    | 'message'
    | 'oid'
    | 'parents'
    | 'path'
    | 'repository'
    | 'subject'
    | 'symbols'
    | 'tree'
    | 'url'
    | GitCommitKeySpecifier
)[]
export type GitCommitFieldPolicy = {
    abbreviatedOID?: FieldPolicy<any> | FieldReadFunction<any>
    ancestors?: FieldPolicy<any> | FieldReadFunction<any>
    author?: FieldPolicy<any> | FieldReadFunction<any>
    behindAhead?: FieldPolicy<any> | FieldReadFunction<any>
    blob?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    committer?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    file?: FieldPolicy<any> | FieldReadFunction<any>
    fileNames?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    languages?: FieldPolicy<any> | FieldReadFunction<any>
    languageStatistics?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    oid?: FieldPolicy<any> | FieldReadFunction<any>
    parents?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    subject?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    tree?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitCommitConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | GitCommitConnectionKeySpecifier)[]
export type GitCommitConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitCommitDescriptionKeySpecifier = (
    | 'author'
    | 'body'
    | 'diff'
    | 'message'
    | 'subject'
    | GitCommitDescriptionKeySpecifier
)[]
export type GitCommitDescriptionFieldPolicy = {
    author?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    subject?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitObjectKeySpecifier = ('abbreviatedOID' | 'commit' | 'oid' | 'type' | GitObjectKeySpecifier)[]
export type GitObjectFieldPolicy = {
    abbreviatedOID?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    oid?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitObjectFilterPreviewKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'totalCountYoungerThanThreshold'
    | GitObjectFilterPreviewKeySpecifier
)[]
export type GitObjectFilterPreviewFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    totalCountYoungerThanThreshold?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitRefKeySpecifier = (
    | 'abbrevName'
    | 'displayName'
    | 'id'
    | 'name'
    | 'prefix'
    | 'repository'
    | 'target'
    | 'type'
    | 'url'
    | GitRefKeySpecifier
)[]
export type GitRefFieldPolicy = {
    abbrevName?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    prefix?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    target?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitRefConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | GitRefConnectionKeySpecifier)[]
export type GitRefConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitRevisionRangeKeySpecifier = (
    | 'base'
    | 'baseRevSpec'
    | 'expr'
    | 'head'
    | 'headRevSpec'
    | 'mergeBase'
    | GitRevisionRangeKeySpecifier
)[]
export type GitRevisionRangeFieldPolicy = {
    base?: FieldPolicy<any> | FieldReadFunction<any>
    baseRevSpec?: FieldPolicy<any> | FieldReadFunction<any>
    expr?: FieldPolicy<any> | FieldReadFunction<any>
    head?: FieldPolicy<any> | FieldReadFunction<any>
    headRevSpec?: FieldPolicy<any> | FieldReadFunction<any>
    mergeBase?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitRevSpecExprKeySpecifier = ('expr' | 'object' | GitRevSpecExprKeySpecifier)[]
export type GitRevSpecExprFieldPolicy = {
    expr?: FieldPolicy<any> | FieldReadFunction<any>
    object?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreeKeySpecifier = (
    | 'canonicalURL'
    | 'codeIntelInfo'
    | 'commit'
    | 'directories'
    | 'entries'
    | 'externalURLs'
    | 'files'
    | 'isDirectory'
    | 'isRoot'
    | 'isSingleChild'
    | 'lsif'
    | 'name'
    | 'path'
    | 'rawZipArchiveURL'
    | 'repository'
    | 'submodule'
    | 'symbols'
    | 'url'
    | GitTreeKeySpecifier
)[]
export type GitTreeFieldPolicy = {
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelInfo?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    directories?: FieldPolicy<any> | FieldReadFunction<any>
    entries?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    files?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    isRoot?: FieldPolicy<any> | FieldReadFunction<any>
    isSingleChild?: FieldPolicy<any> | FieldReadFunction<any>
    lsif?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    rawZipArchiveURL?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    submodule?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreeCodeIntelInfoKeySpecifier = (
    | 'preciseSupport'
    | 'searchBasedSupport'
    | GitTreeCodeIntelInfoKeySpecifier
)[]
export type GitTreeCodeIntelInfoFieldPolicy = {
    preciseSupport?: FieldPolicy<any> | FieldReadFunction<any>
    searchBasedSupport?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreeLSIFDataKeySpecifier = ('diagnostics' | 'lsifUploads' | GitTreeLSIFDataKeySpecifier)[]
export type GitTreeLSIFDataFieldPolicy = {
    diagnostics?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreePreciseCoverageKeySpecifier = ('confidence' | 'support' | GitTreePreciseCoverageKeySpecifier)[]
export type GitTreePreciseCoverageFieldPolicy = {
    confidence?: FieldPolicy<any> | FieldReadFunction<any>
    support?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreeSearchBasedCoverageKeySpecifier = (
    | 'coveredPaths'
    | 'support'
    | GitTreeSearchBasedCoverageKeySpecifier
)[]
export type GitTreeSearchBasedCoverageFieldPolicy = {
    coveredPaths?: FieldPolicy<any> | FieldReadFunction<any>
    support?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitUpdatesDisabledKeySpecifier = ('message' | GitUpdatesDisabledKeySpecifier)[]
export type GitUpdatesDisabledFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GlobalChangesetsStatsKeySpecifier = (
    | 'closed'
    | 'draft'
    | 'merged'
    | 'open'
    | 'total'
    | 'unpublished'
    | GlobalChangesetsStatsKeySpecifier
)[]
export type GlobalChangesetsStatsFieldPolicy = {
    closed?: FieldPolicy<any> | FieldReadFunction<any>
    draft?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    open?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
    unpublished?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenApplyPreviewTargetsAttachKeySpecifier = (
    | 'changesetSpec'
    | HiddenApplyPreviewTargetsAttachKeySpecifier
)[]
export type HiddenApplyPreviewTargetsAttachFieldPolicy = {
    changesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenApplyPreviewTargetsDetachKeySpecifier = ('changeset' | HiddenApplyPreviewTargetsDetachKeySpecifier)[]
export type HiddenApplyPreviewTargetsDetachFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenApplyPreviewTargetsUpdateKeySpecifier = (
    | 'changeset'
    | 'changesetSpec'
    | HiddenApplyPreviewTargetsUpdateKeySpecifier
)[]
export type HiddenApplyPreviewTargetsUpdateFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
    changesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenBatchSpecWorkspaceKeySpecifier = (
    | 'batchSpec'
    | 'cachedResultFound'
    | 'diffStat'
    | 'finishedAt'
    | 'id'
    | 'ignored'
    | 'onlyFetchWorkspace'
    | 'placeInGlobalQueue'
    | 'placeInQueue'
    | 'queuedAt'
    | 'startedAt'
    | 'state'
    | 'stepCacheResultCount'
    | 'unsupported'
    | HiddenBatchSpecWorkspaceKeySpecifier
)[]
export type HiddenBatchSpecWorkspaceFieldPolicy = {
    batchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    cachedResultFound?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    onlyFetchWorkspace?: FieldPolicy<any> | FieldReadFunction<any>
    placeInGlobalQueue?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    stepCacheResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    unsupported?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenChangesetApplyPreviewKeySpecifier = (
    | 'delta'
    | 'operations'
    | 'targets'
    | HiddenChangesetApplyPreviewKeySpecifier
)[]
export type HiddenChangesetApplyPreviewFieldPolicy = {
    delta?: FieldPolicy<any> | FieldReadFunction<any>
    operations?: FieldPolicy<any> | FieldReadFunction<any>
    targets?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenChangesetSpecKeySpecifier = ('expiresAt' | 'id' | 'type' | HiddenChangesetSpecKeySpecifier)[]
export type HiddenChangesetSpecFieldPolicy = {
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenExternalChangesetKeySpecifier = (
    | 'batchChanges'
    | 'createdAt'
    | 'id'
    | 'nextSyncAt'
    | 'state'
    | 'updatedAt'
    | HiddenExternalChangesetKeySpecifier
)[]
export type HiddenExternalChangesetFieldPolicy = {
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    nextSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightKeySpecifier = ('character' | 'length' | 'line' | HighlightKeySpecifier)[]
export type HighlightFieldPolicy = {
    character?: FieldPolicy<any> | FieldReadFunction<any>
    length?: FieldPolicy<any> | FieldReadFunction<any>
    line?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightedDiffHunkBodyKeySpecifier = ('aborted' | 'lines' | HighlightedDiffHunkBodyKeySpecifier)[]
export type HighlightedDiffHunkBodyFieldPolicy = {
    aborted?: FieldPolicy<any> | FieldReadFunction<any>
    lines?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightedDiffHunkLineKeySpecifier = ('html' | 'kind' | HighlightedDiffHunkLineKeySpecifier)[]
export type HighlightedDiffHunkLineFieldPolicy = {
    html?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightedFileKeySpecifier = ('aborted' | 'html' | 'lineRanges' | 'lsif' | HighlightedFileKeySpecifier)[]
export type HighlightedFileFieldPolicy = {
    aborted?: FieldPolicy<any> | FieldReadFunction<any>
    html?: FieldPolicy<any> | FieldReadFunction<any>
    lineRanges?: FieldPolicy<any> | FieldReadFunction<any>
    lsif?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightedStringKeySpecifier = ('highlights' | 'value' | HighlightedStringKeySpecifier)[]
export type HighlightedStringFieldPolicy = {
    highlights?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HoverKeySpecifier = ('markdown' | 'range' | HoverKeySpecifier)[]
export type HoverFieldPolicy = {
    markdown?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HTTPHeaderKeySpecifier = ('name' | 'values' | HTTPHeaderKeySpecifier)[]
export type HTTPHeaderFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    values?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HunkKeySpecifier = (
    | 'author'
    | 'commit'
    | 'endByte'
    | 'endLine'
    | 'filename'
    | 'message'
    | 'rev'
    | 'startByte'
    | 'startLine'
    | HunkKeySpecifier
)[]
export type HunkFieldPolicy = {
    author?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    endByte?: FieldPolicy<any> | FieldReadFunction<any>
    endLine?: FieldPolicy<any> | FieldReadFunction<any>
    filename?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    rev?: FieldPolicy<any> | FieldReadFunction<any>
    startByte?: FieldPolicy<any> | FieldReadFunction<any>
    startLine?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IncompleteDatapointAlertKeySpecifier = ('time' | IncompleteDatapointAlertKeySpecifier)[]
export type IncompleteDatapointAlertFieldPolicy = {
    time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IndexConfigurationKeySpecifier = (
    | 'configuration'
    | 'inferredConfiguration'
    | IndexConfigurationKeySpecifier
)[]
export type IndexConfigurationFieldPolicy = {
    configuration?: FieldPolicy<any> | FieldReadFunction<any>
    inferredConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IndexingProgressKeySpecifier = ('indexed' | 'notIndexed' | IndexingProgressKeySpecifier)[]
export type IndexingProgressFieldPolicy = {
    indexed?: FieldPolicy<any> | FieldReadFunction<any>
    notIndexed?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IndexStepKeySpecifier = ('indexerArgs' | 'logEntry' | 'outfile' | IndexStepKeySpecifier)[]
export type IndexStepFieldPolicy = {
    indexerArgs?: FieldPolicy<any> | FieldReadFunction<any>
    logEntry?: FieldPolicy<any> | FieldReadFunction<any>
    outfile?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IndexStepsKeySpecifier = ('index' | 'preIndex' | 'setup' | 'teardown' | 'upload' | IndexStepsKeySpecifier)[]
export type IndexStepsFieldPolicy = {
    index?: FieldPolicy<any> | FieldReadFunction<any>
    preIndex?: FieldPolicy<any> | FieldReadFunction<any>
    setup?: FieldPolicy<any> | FieldReadFunction<any>
    teardown?: FieldPolicy<any> | FieldReadFunction<any>
    upload?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InferredAvailableIndexersKeySpecifier = (
    | 'index'
    | 'roots'
    | 'url'
    | InferredAvailableIndexersKeySpecifier
)[]
export type InferredAvailableIndexersFieldPolicy = {
    index?: FieldPolicy<any> | FieldReadFunction<any>
    roots?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightKeySpecifier = ('description' | 'id' | 'series' | 'title' | InsightKeySpecifier)[]
export type InsightFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    series?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightBackfillQueueItemKeySpecifier = (
    | 'backfillQueueStatus'
    | 'creator'
    | 'id'
    | 'insightViewTitle'
    | 'seriesLabel'
    | 'seriesSearchQuery'
    | InsightBackfillQueueItemKeySpecifier
)[]
export type InsightBackfillQueueItemFieldPolicy = {
    backfillQueueStatus?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    insightViewTitle?: FieldPolicy<any> | FieldReadFunction<any>
    seriesLabel?: FieldPolicy<any> | FieldReadFunction<any>
    seriesSearchQuery?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightBackfillQueueItemConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | InsightBackfillQueueItemConnectionKeySpecifier
)[]
export type InsightBackfillQueueItemConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightDataPointKeySpecifier = ('dateTime' | 'diffQuery' | 'value' | InsightDataPointKeySpecifier)[]
export type InsightDataPointFieldPolicy = {
    dateTime?: FieldPolicy<any> | FieldReadFunction<any>
    diffQuery?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightIntervalTimeScopeKeySpecifier = ('unit' | 'value' | InsightIntervalTimeScopeKeySpecifier)[]
export type InsightIntervalTimeScopeFieldPolicy = {
    unit?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightRepositoryScopeKeySpecifier = ('repositories' | InsightRepositoryScopeKeySpecifier)[]
export type InsightRepositoryScopeFieldPolicy = {
    repositories?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsDashboardKeySpecifier = ('grants' | 'id' | 'title' | 'views' | InsightsDashboardKeySpecifier)[]
export type InsightsDashboardFieldPolicy = {
    grants?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
    views?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsDashboardConnectionKeySpecifier = ('nodes' | 'pageInfo' | InsightsDashboardConnectionKeySpecifier)[]
export type InsightsDashboardConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsDashboardPayloadKeySpecifier = ('dashboard' | InsightsDashboardPayloadKeySpecifier)[]
export type InsightsDashboardPayloadFieldPolicy = {
    dashboard?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightSeriesMetadataKeySpecifier = ('enabled' | 'query' | 'seriesId' | InsightSeriesMetadataKeySpecifier)[]
export type InsightSeriesMetadataFieldPolicy = {
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightSeriesMetadataPayloadKeySpecifier = ('series' | InsightSeriesMetadataPayloadKeySpecifier)[]
export type InsightSeriesMetadataPayloadFieldPolicy = {
    series?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightSeriesQueryStatusKeySpecifier = (
    | 'completed'
    | 'enabled'
    | 'errored'
    | 'failed'
    | 'processing'
    | 'query'
    | 'queued'
    | 'seriesId'
    | InsightSeriesQueryStatusKeySpecifier
)[]
export type InsightSeriesQueryStatusFieldPolicy = {
    completed?: FieldPolicy<any> | FieldReadFunction<any>
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    errored?: FieldPolicy<any> | FieldReadFunction<any>
    failed?: FieldPolicy<any> | FieldReadFunction<any>
    processing?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    queued?: FieldPolicy<any> | FieldReadFunction<any>
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightSeriesStatusKeySpecifier = (
    | 'backfillQueuedAt'
    | 'completedJobs'
    | 'failedJobs'
    | 'incompleteDatapoints'
    | 'isLoadingData'
    | 'pendingJobs'
    | 'totalPoints'
    | InsightSeriesStatusKeySpecifier
)[]
export type InsightSeriesStatusFieldPolicy = {
    backfillQueuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    completedJobs?: FieldPolicy<any> | FieldReadFunction<any>
    failedJobs?: FieldPolicy<any> | FieldReadFunction<any>
    incompleteDatapoints?: FieldPolicy<any> | FieldReadFunction<any>
    isLoadingData?: FieldPolicy<any> | FieldReadFunction<any>
    pendingJobs?: FieldPolicy<any> | FieldReadFunction<any>
    totalPoints?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsPermissionGrantsKeySpecifier = (
    | 'global'
    | 'organizations'
    | 'users'
    | InsightsPermissionGrantsKeySpecifier
)[]
export type InsightsPermissionGrantsFieldPolicy = {
    global?: FieldPolicy<any> | FieldReadFunction<any>
    organizations?: FieldPolicy<any> | FieldReadFunction<any>
    users?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsSeriesKeySpecifier = ('label' | 'points' | 'seriesId' | 'status' | InsightsSeriesKeySpecifier)[]
export type InsightsSeriesFieldPolicy = {
    label?: FieldPolicy<any> | FieldReadFunction<any>
    points?: FieldPolicy<any> | FieldReadFunction<any>
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewKeySpecifier = (
    | 'appliedFilters'
    | 'appliedSeriesDisplayOptions'
    | 'dashboardReferenceCount'
    | 'dashboards'
    | 'dataSeries'
    | 'dataSeriesDefinitions'
    | 'defaultFilters'
    | 'defaultSeriesDisplayOptions'
    | 'id'
    | 'isFrozen'
    | 'presentation'
    | 'repositoryDefinition'
    | 'seriesCount'
    | 'timeScope'
    | InsightViewKeySpecifier
)[]
export type InsightViewFieldPolicy = {
    appliedFilters?: FieldPolicy<any> | FieldReadFunction<any>
    appliedSeriesDisplayOptions?: FieldPolicy<any> | FieldReadFunction<any>
    dashboardReferenceCount?: FieldPolicy<any> | FieldReadFunction<any>
    dashboards?: FieldPolicy<any> | FieldReadFunction<any>
    dataSeries?: FieldPolicy<any> | FieldReadFunction<any>
    dataSeriesDefinitions?: FieldPolicy<any> | FieldReadFunction<any>
    defaultFilters?: FieldPolicy<any> | FieldReadFunction<any>
    defaultSeriesDisplayOptions?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    isFrozen?: FieldPolicy<any> | FieldReadFunction<any>
    presentation?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryDefinition?: FieldPolicy<any> | FieldReadFunction<any>
    seriesCount?: FieldPolicy<any> | FieldReadFunction<any>
    timeScope?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | InsightViewConnectionKeySpecifier
)[]
export type InsightViewConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewDebugKeySpecifier = ('raw' | InsightViewDebugKeySpecifier)[]
export type InsightViewDebugFieldPolicy = {
    raw?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewFiltersKeySpecifier = (
    | 'excludeRepoRegex'
    | 'includeRepoRegex'
    | 'searchContexts'
    | InsightViewFiltersKeySpecifier
)[]
export type InsightViewFiltersFieldPolicy = {
    excludeRepoRegex?: FieldPolicy<any> | FieldReadFunction<any>
    includeRepoRegex?: FieldPolicy<any> | FieldReadFunction<any>
    searchContexts?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewPayloadKeySpecifier = ('view' | InsightViewPayloadKeySpecifier)[]
export type InsightViewPayloadFieldPolicy = {
    view?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InviteUserToOrganizationResultKeySpecifier = (
    | 'invitationURL'
    | 'sentInvitationEmail'
    | InviteUserToOrganizationResultKeySpecifier
)[]
export type InviteUserToOrganizationResultFieldPolicy = {
    invitationURL?: FieldPolicy<any> | FieldReadFunction<any>
    sentInvitationEmail?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KeyValuePairKeySpecifier = ('key' | 'value' | KeyValuePairKeySpecifier)[]
export type KeyValuePairFieldPolicy = {
    key?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LanguageKeySpecifier = ('name' | LanguageKeySpecifier)[]
export type LanguageFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LanguageStatisticsKeySpecifier = ('name' | 'totalBytes' | 'totalLines' | LanguageStatisticsKeySpecifier)[]
export type LanguageStatisticsFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    totalBytes?: FieldPolicy<any> | FieldReadFunction<any>
    totalLines?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LFSKeySpecifier = ('byteSize' | LFSKeySpecifier)[]
export type LFSFieldPolicy = {
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LineChartDataSeriesPresentationKeySpecifier = (
    | 'color'
    | 'label'
    | 'seriesId'
    | LineChartDataSeriesPresentationKeySpecifier
)[]
export type LineChartDataSeriesPresentationFieldPolicy = {
    color?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LineChartInsightViewPresentationKeySpecifier = (
    | 'seriesPresentation'
    | 'title'
    | LineChartInsightViewPresentationKeySpecifier
)[]
export type LineChartInsightViewPresentationFieldPolicy = {
    seriesPresentation?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LineMatchKeySpecifier = (
    | 'limitHit'
    | 'lineNumber'
    | 'offsetAndLengths'
    | 'preview'
    | LineMatchKeySpecifier
)[]
export type LineMatchFieldPolicy = {
    limitHit?: FieldPolicy<any> | FieldReadFunction<any>
    lineNumber?: FieldPolicy<any> | FieldReadFunction<any>
    offsetAndLengths?: FieldPolicy<any> | FieldReadFunction<any>
    preview?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LineRangeKeySpecifier = ('character' | 'length' | 'line' | LineRangeKeySpecifier)[]
export type LineRangeFieldPolicy = {
    character?: FieldPolicy<any> | FieldReadFunction<any>
    length?: FieldPolicy<any> | FieldReadFunction<any>
    line?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LocationKeySpecifier = ('canonicalURL' | 'range' | 'resource' | 'url' | LocationKeySpecifier)[]
export type LocationFieldPolicy = {
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
    resource?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LocationConnectionKeySpecifier = ('nodes' | 'pageInfo' | LocationConnectionKeySpecifier)[]
export type LocationConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFIndexKeySpecifier = (
    | 'associatedUpload'
    | 'failure'
    | 'finishedAt'
    | 'id'
    | 'indexer'
    | 'inputCommit'
    | 'inputIndexer'
    | 'inputRoot'
    | 'placeInQueue'
    | 'projectRoot'
    | 'queuedAt'
    | 'shouldReindex'
    | 'startedAt'
    | 'state'
    | 'steps'
    | 'tags'
    | LSIFIndexKeySpecifier
)[]
export type LSIFIndexFieldPolicy = {
    associatedUpload?: FieldPolicy<any> | FieldReadFunction<any>
    failure?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    inputCommit?: FieldPolicy<any> | FieldReadFunction<any>
    inputIndexer?: FieldPolicy<any> | FieldReadFunction<any>
    inputRoot?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    projectRoot?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    shouldReindex?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    steps?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFIndexConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | LSIFIndexConnectionKeySpecifier)[]
export type LSIFIndexConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFIndexesWithRepositoryNamespaceKeySpecifier = (
    | 'indexer'
    | 'indexes'
    | 'root'
    | LSIFIndexesWithRepositoryNamespaceKeySpecifier
)[]
export type LSIFIndexesWithRepositoryNamespaceFieldPolicy = {
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    indexes?: FieldPolicy<any> | FieldReadFunction<any>
    root?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadKeySpecifier = (
    | 'associatedIndex'
    | 'auditLogs'
    | 'documentPaths'
    | 'failure'
    | 'finishedAt'
    | 'id'
    | 'indexer'
    | 'inputCommit'
    | 'inputIndexer'
    | 'inputRoot'
    | 'isLatestForRepo'
    | 'placeInQueue'
    | 'projectRoot'
    | 'retentionPolicyOverview'
    | 'startedAt'
    | 'state'
    | 'tags'
    | 'uploadedAt'
    | LSIFUploadKeySpecifier
)[]
export type LSIFUploadFieldPolicy = {
    associatedIndex?: FieldPolicy<any> | FieldReadFunction<any>
    auditLogs?: FieldPolicy<any> | FieldReadFunction<any>
    documentPaths?: FieldPolicy<any> | FieldReadFunction<any>
    failure?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    inputCommit?: FieldPolicy<any> | FieldReadFunction<any>
    inputIndexer?: FieldPolicy<any> | FieldReadFunction<any>
    inputRoot?: FieldPolicy<any> | FieldReadFunction<any>
    isLatestForRepo?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    projectRoot?: FieldPolicy<any> | FieldReadFunction<any>
    retentionPolicyOverview?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    uploadedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadAuditLogKeySpecifier = (
    | 'changedColumns'
    | 'inputCommit'
    | 'inputIndexer'
    | 'inputRoot'
    | 'logTimestamp'
    | 'operation'
    | 'reason'
    | 'uploadDeletedAt'
    | 'uploadedAt'
    | 'uploadId'
    | LSIFUploadAuditLogKeySpecifier
)[]
export type LSIFUploadAuditLogFieldPolicy = {
    changedColumns?: FieldPolicy<any> | FieldReadFunction<any>
    inputCommit?: FieldPolicy<any> | FieldReadFunction<any>
    inputIndexer?: FieldPolicy<any> | FieldReadFunction<any>
    inputRoot?: FieldPolicy<any> | FieldReadFunction<any>
    logTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
    operation?: FieldPolicy<any> | FieldReadFunction<any>
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    uploadDeletedAt?: FieldPolicy<any> | FieldReadFunction<any>
    uploadedAt?: FieldPolicy<any> | FieldReadFunction<any>
    uploadId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | LSIFUploadConnectionKeySpecifier
)[]
export type LSIFUploadConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadDocumentPathsConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | LSIFUploadDocumentPathsConnectionKeySpecifier
)[]
export type LSIFUploadDocumentPathsConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadsWithRepositoryNamespaceKeySpecifier = (
    | 'indexer'
    | 'root'
    | 'uploads'
    | LSIFUploadsWithRepositoryNamespaceKeySpecifier
)[]
export type LSIFUploadsWithRepositoryNamespaceFieldPolicy = {
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    root?: FieldPolicy<any> | FieldReadFunction<any>
    uploads?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MarkdownKeySpecifier = ('html' | 'text' | MarkdownKeySpecifier)[]
export type MarkdownFieldPolicy = {
    html?: FieldPolicy<any> | FieldReadFunction<any>
    text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MarkdownBlockKeySpecifier = ('id' | 'markdownInput' | MarkdownBlockKeySpecifier)[]
export type MarkdownBlockFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    markdownInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MirrorRepositoryInfoKeySpecifier = (
    | 'byteSize'
    | 'cloned'
    | 'cloneInProgress'
    | 'cloneProgress'
    | 'corruptionLogs'
    | 'isCorrupted'
    | 'lastError'
    | 'remoteURL'
    | 'shard'
    | 'updatedAt'
    | 'updateQueue'
    | 'updateSchedule'
    | MirrorRepositoryInfoKeySpecifier
)[]
export type MirrorRepositoryInfoFieldPolicy = {
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
    cloned?: FieldPolicy<any> | FieldReadFunction<any>
    cloneInProgress?: FieldPolicy<any> | FieldReadFunction<any>
    cloneProgress?: FieldPolicy<any> | FieldReadFunction<any>
    corruptionLogs?: FieldPolicy<any> | FieldReadFunction<any>
    isCorrupted?: FieldPolicy<any> | FieldReadFunction<any>
    lastError?: FieldPolicy<any> | FieldReadFunction<any>
    remoteURL?: FieldPolicy<any> | FieldReadFunction<any>
    shard?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    updateQueue?: FieldPolicy<any> | FieldReadFunction<any>
    updateSchedule?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorKeySpecifier = (
    | 'actions'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'enabled'
    | 'id'
    | 'owner'
    | 'trigger'
    | MonitorKeySpecifier
)[]
export type MonitorFieldPolicy = {
    actions?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    createdBy?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    owner?: FieldPolicy<any> | FieldReadFunction<any>
    trigger?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorActionConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | MonitorActionConnectionKeySpecifier
)[]
export type MonitorActionConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorActionEmailRecipientsConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | MonitorActionEmailRecipientsConnectionKeySpecifier
)[]
export type MonitorActionEmailRecipientsConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorActionEventKeySpecifier = (
    | 'id'
    | 'message'
    | 'status'
    | 'timestamp'
    | MonitorActionEventKeySpecifier
)[]
export type MonitorActionEventFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorActionEventConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | MonitorActionEventConnectionKeySpecifier
)[]
export type MonitorActionEventConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | MonitorConnectionKeySpecifier)[]
export type MonitorConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorEmailKeySpecifier = (
    | 'enabled'
    | 'events'
    | 'header'
    | 'id'
    | 'includeResults'
    | 'priority'
    | 'recipients'
    | MonitorEmailKeySpecifier
)[]
export type MonitorEmailFieldPolicy = {
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
    header?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    includeResults?: FieldPolicy<any> | FieldReadFunction<any>
    priority?: FieldPolicy<any> | FieldReadFunction<any>
    recipients?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitoringAlertKeySpecifier = (
    | 'average'
    | 'name'
    | 'owner'
    | 'serviceName'
    | 'timestamp'
    | MonitoringAlertKeySpecifier
)[]
export type MonitoringAlertFieldPolicy = {
    average?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    owner?: FieldPolicy<any> | FieldReadFunction<any>
    serviceName?: FieldPolicy<any> | FieldReadFunction<any>
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitoringStatisticsKeySpecifier = ('alerts' | MonitoringStatisticsKeySpecifier)[]
export type MonitoringStatisticsFieldPolicy = {
    alerts?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorQueryKeySpecifier = ('events' | 'id' | 'query' | MonitorQueryKeySpecifier)[]
export type MonitorQueryFieldPolicy = {
    events?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorSlackWebhookKeySpecifier = (
    | 'enabled'
    | 'events'
    | 'id'
    | 'includeResults'
    | 'url'
    | MonitorSlackWebhookKeySpecifier
)[]
export type MonitorSlackWebhookFieldPolicy = {
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    includeResults?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorTriggerEventKeySpecifier = (
    | 'actions'
    | 'id'
    | 'message'
    | 'query'
    | 'resultCount'
    | 'status'
    | 'timestamp'
    | MonitorTriggerEventKeySpecifier
)[]
export type MonitorTriggerEventFieldPolicy = {
    actions?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    resultCount?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorTriggerEventConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | MonitorTriggerEventConnectionKeySpecifier
)[]
export type MonitorTriggerEventConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorWebhookKeySpecifier = (
    | 'enabled'
    | 'events'
    | 'id'
    | 'includeResults'
    | 'url'
    | MonitorWebhookKeySpecifier
)[]
export type MonitorWebhookFieldPolicy = {
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    includeResults?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MutationKeySpecifier = (
    | 'addExternalAccount'
    | 'addExternalService'
    | 'addInsightViewToDashboard'
    | 'addPhabricatorRepo'
    | 'addRepoKeyValuePair'
    | 'addTeamMembers'
    | 'addUserEmail'
    | 'addUserToOrganization'
    | 'applyBatchChange'
    | 'cancelBatchSpecExecution'
    | 'cancelBatchSpecWorkspaceExecution'
    | 'cancelExternalServiceSync'
    | 'checkMirrorRepositoryConnection'
    | 'closeBatchChange'
    | 'closeChangesets'
    | 'configurationMutation'
    | 'createAccessToken'
    | 'createBatchChange'
    | 'createBatchChangesCredential'
    | 'createBatchSpec'
    | 'createBatchSpecFromRaw'
    | 'createChangesetComments'
    | 'createChangesetSpec'
    | 'createChangesetSpecs'
    | 'createCodeIntelligenceConfigurationPolicy'
    | 'createCodeMonitor'
    | 'createEmptyBatchChange'
    | 'createExecutorSecret'
    | 'createFeatureFlag'
    | 'createFeatureFlagOverride'
    | 'createInsightsDashboard'
    | 'createLineChartSearchInsight'
    | 'createNotebook'
    | 'createNotebookStar'
    | 'createOrganization'
    | 'createOutboundWebhook'
    | 'createPassword'
    | 'createPieChartSearchInsight'
    | 'createRole'
    | 'createSavedSearch'
    | 'createSearchContext'
    | 'createSearchContextStar'
    | 'createTeam'
    | 'createUser'
    | 'createWebhook'
    | 'deleteAccessToken'
    | 'deleteBatchChange'
    | 'deleteBatchChangesCredential'
    | 'deleteBatchSpec'
    | 'deleteCodeIntelligenceConfigurationPolicy'
    | 'deleteCodeMonitor'
    | 'deleteExecutorSecret'
    | 'deleteExternalAccount'
    | 'deleteExternalService'
    | 'deleteFeatureFlag'
    | 'deleteFeatureFlagOverride'
    | 'deleteInsightsDashboard'
    | 'deleteInsightView'
    | 'deleteLSIFIndex'
    | 'deleteLSIFIndexes'
    | 'deleteLSIFUpload'
    | 'deleteLSIFUploads'
    | 'deleteNotebook'
    | 'deleteNotebookStar'
    | 'deleteOrganization'
    | 'deleteOutboundWebhook'
    | 'deletePreciseIndex'
    | 'deletePreciseIndexes'
    | 'deleteRepoKeyValuePair'
    | 'deleteRepositoryFromDisk'
    | 'deleteRole'
    | 'deleteSavedSearch'
    | 'deleteSearchContext'
    | 'deleteSearchContextStar'
    | 'deleteTeam'
    | 'deleteUser'
    | 'deleteUsers'
    | 'deleteWebhook'
    | 'detachChangesets'
    | 'dotcom'
    | 'editTemporarySettings'
    | 'enqueueBatchSpecWorkspaceExecution'
    | 'excludeRepoFromExternalServices'
    | 'executeBatchSpec'
    | 'invalidateSessionsByID'
    | 'invalidateSessionsByIDs'
    | 'inviteEmailToSourcegraph'
    | 'inviteUserToOrganization'
    | 'logEvent'
    | 'logEvents'
    | 'logUserEvent'
    | 'mergeChangesets'
    | 'moveBatchChange'
    | 'moveInsightSeriesBackfillToBackOfQueue'
    | 'moveInsightSeriesBackfillToFrontOfQueue'
    | 'overwriteTemporarySettings'
    | 'publishChangesets'
    | 'queueAutoIndexJobsForRepo'
    | 'randomizeUserPassword'
    | 'recloneRepository'
    | 'recoverUsers'
    | 'reenqueueChangeset'
    | 'reenqueueChangesets'
    | 'reindexLSIFIndex'
    | 'reindexLSIFIndexes'
    | 'reindexPreciseIndex'
    | 'reindexPreciseIndexes'
    | 'reindexRepository'
    | 'reloadSite'
    | 'removeInsightViewFromDashboard'
    | 'removeTeamMembers'
    | 'removeUserEmail'
    | 'removeUserFromOrganization'
    | 'replaceBatchSpecInput'
    | 'requestLanguageSupport'
    | 'resendOrganizationInvitationNotification'
    | 'resendVerificationEmail'
    | 'resetTriggerQueryTimestamps'
    | 'resolvePhabricatorDiff'
    | 'respondToOrganizationInvitation'
    | 'retryBatchSpecExecution'
    | 'retryBatchSpecWorkspaceExecution'
    | 'retryInsightSeriesBackfill'
    | 'revokeOrganizationInvitation'
    | 'saveInsightAsNewView'
    | 'scheduleRepositoryPermissionsSync'
    | 'scheduleUserPermissionsSync'
    | 'sendTestEmail'
    | 'setDefaultSearchContext'
    | 'setMigrationDirection'
    | 'setRepositoryPermissionsForBitbucketProject'
    | 'setRepositoryPermissionsForUsers'
    | 'setRepositoryPermissionsUnrestricted'
    | 'setSearchable'
    | 'setSubRepositoryPermissionsForUsers'
    | 'setTag'
    | 'setTeamMembers'
    | 'settingsMutation'
    | 'setTosAccepted'
    | 'setUserEmailPrimary'
    | 'setUserEmailVerified'
    | 'setUserIsSiteAdmin'
    | 'submitHappinessFeedback'
    | 'submitSurvey'
    | 'syncChangeset'
    | 'syncExternalService'
    | 'toggleBatchSpecAutoApply'
    | 'toggleCodeMonitor'
    | 'triggerObservabilityTestAlert'
    | 'triggerTestEmailAction'
    | 'triggerTestSlackWebhookAction'
    | 'triggerTestWebhookAction'
    | 'updateCodeIntelligenceConfigurationPolicy'
    | 'updateCodeIntelligenceInferenceScript'
    | 'updateCodeMonitor'
    | 'updateExecutorSecret'
    | 'updateExternalService'
    | 'updateFeatureFlag'
    | 'updateFeatureFlagOverride'
    | 'updateInsightsDashboard'
    | 'updateInsightSeries'
    | 'updateLineChartSearchInsight'
    | 'updateMirrorRepository'
    | 'updateNotebook'
    | 'updateOrganization'
    | 'updateOutboundWebhook'
    | 'updatePassword'
    | 'updatePieChartSearchInsight'
    | 'updateRepoKeyValuePair'
    | 'updateRepositoryIndexConfiguration'
    | 'updateSavedSearch'
    | 'updateSearchContext'
    | 'updateSiteConfiguration'
    | 'updateTeam'
    | 'updateUser'
    | 'updateWebhook'
    | 'upsertBatchSpecInput'
    | 'upsertEmptyBatchChange'
    | MutationKeySpecifier
)[]
export type MutationFieldPolicy = {
    addExternalAccount?: FieldPolicy<any> | FieldReadFunction<any>
    addExternalService?: FieldPolicy<any> | FieldReadFunction<any>
    addInsightViewToDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    addPhabricatorRepo?: FieldPolicy<any> | FieldReadFunction<any>
    addRepoKeyValuePair?: FieldPolicy<any> | FieldReadFunction<any>
    addTeamMembers?: FieldPolicy<any> | FieldReadFunction<any>
    addUserEmail?: FieldPolicy<any> | FieldReadFunction<any>
    addUserToOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    applyBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    cancelBatchSpecExecution?: FieldPolicy<any> | FieldReadFunction<any>
    cancelBatchSpecWorkspaceExecution?: FieldPolicy<any> | FieldReadFunction<any>
    cancelExternalServiceSync?: FieldPolicy<any> | FieldReadFunction<any>
    checkMirrorRepositoryConnection?: FieldPolicy<any> | FieldReadFunction<any>
    closeBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    closeChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    configurationMutation?: FieldPolicy<any> | FieldReadFunction<any>
    createAccessToken?: FieldPolicy<any> | FieldReadFunction<any>
    createBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    createBatchChangesCredential?: FieldPolicy<any> | FieldReadFunction<any>
    createBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    createBatchSpecFromRaw?: FieldPolicy<any> | FieldReadFunction<any>
    createChangesetComments?: FieldPolicy<any> | FieldReadFunction<any>
    createChangesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
    createChangesetSpecs?: FieldPolicy<any> | FieldReadFunction<any>
    createCodeIntelligenceConfigurationPolicy?: FieldPolicy<any> | FieldReadFunction<any>
    createCodeMonitor?: FieldPolicy<any> | FieldReadFunction<any>
    createEmptyBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    createExecutorSecret?: FieldPolicy<any> | FieldReadFunction<any>
    createFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    createFeatureFlagOverride?: FieldPolicy<any> | FieldReadFunction<any>
    createInsightsDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    createLineChartSearchInsight?: FieldPolicy<any> | FieldReadFunction<any>
    createNotebook?: FieldPolicy<any> | FieldReadFunction<any>
    createNotebookStar?: FieldPolicy<any> | FieldReadFunction<any>
    createOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    createOutboundWebhook?: FieldPolicy<any> | FieldReadFunction<any>
    createPassword?: FieldPolicy<any> | FieldReadFunction<any>
    createPieChartSearchInsight?: FieldPolicy<any> | FieldReadFunction<any>
    createRole?: FieldPolicy<any> | FieldReadFunction<any>
    createSavedSearch?: FieldPolicy<any> | FieldReadFunction<any>
    createSearchContext?: FieldPolicy<any> | FieldReadFunction<any>
    createSearchContextStar?: FieldPolicy<any> | FieldReadFunction<any>
    createTeam?: FieldPolicy<any> | FieldReadFunction<any>
    createUser?: FieldPolicy<any> | FieldReadFunction<any>
    createWebhook?: FieldPolicy<any> | FieldReadFunction<any>
    deleteAccessToken?: FieldPolicy<any> | FieldReadFunction<any>
    deleteBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    deleteBatchChangesCredential?: FieldPolicy<any> | FieldReadFunction<any>
    deleteBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    deleteCodeIntelligenceConfigurationPolicy?: FieldPolicy<any> | FieldReadFunction<any>
    deleteCodeMonitor?: FieldPolicy<any> | FieldReadFunction<any>
    deleteExecutorSecret?: FieldPolicy<any> | FieldReadFunction<any>
    deleteExternalAccount?: FieldPolicy<any> | FieldReadFunction<any>
    deleteExternalService?: FieldPolicy<any> | FieldReadFunction<any>
    deleteFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    deleteFeatureFlagOverride?: FieldPolicy<any> | FieldReadFunction<any>
    deleteInsightsDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    deleteInsightView?: FieldPolicy<any> | FieldReadFunction<any>
    deleteLSIFIndex?: FieldPolicy<any> | FieldReadFunction<any>
    deleteLSIFIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    deleteLSIFUpload?: FieldPolicy<any> | FieldReadFunction<any>
    deleteLSIFUploads?: FieldPolicy<any> | FieldReadFunction<any>
    deleteNotebook?: FieldPolicy<any> | FieldReadFunction<any>
    deleteNotebookStar?: FieldPolicy<any> | FieldReadFunction<any>
    deleteOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    deleteOutboundWebhook?: FieldPolicy<any> | FieldReadFunction<any>
    deletePreciseIndex?: FieldPolicy<any> | FieldReadFunction<any>
    deletePreciseIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    deleteRepoKeyValuePair?: FieldPolicy<any> | FieldReadFunction<any>
    deleteRepositoryFromDisk?: FieldPolicy<any> | FieldReadFunction<any>
    deleteRole?: FieldPolicy<any> | FieldReadFunction<any>
    deleteSavedSearch?: FieldPolicy<any> | FieldReadFunction<any>
    deleteSearchContext?: FieldPolicy<any> | FieldReadFunction<any>
    deleteSearchContextStar?: FieldPolicy<any> | FieldReadFunction<any>
    deleteTeam?: FieldPolicy<any> | FieldReadFunction<any>
    deleteUser?: FieldPolicy<any> | FieldReadFunction<any>
    deleteUsers?: FieldPolicy<any> | FieldReadFunction<any>
    deleteWebhook?: FieldPolicy<any> | FieldReadFunction<any>
    detachChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    dotcom?: FieldPolicy<any> | FieldReadFunction<any>
    editTemporarySettings?: FieldPolicy<any> | FieldReadFunction<any>
    enqueueBatchSpecWorkspaceExecution?: FieldPolicy<any> | FieldReadFunction<any>
    excludeRepoFromExternalServices?: FieldPolicy<any> | FieldReadFunction<any>
    executeBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    invalidateSessionsByID?: FieldPolicy<any> | FieldReadFunction<any>
    invalidateSessionsByIDs?: FieldPolicy<any> | FieldReadFunction<any>
    inviteEmailToSourcegraph?: FieldPolicy<any> | FieldReadFunction<any>
    inviteUserToOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    logEvent?: FieldPolicy<any> | FieldReadFunction<any>
    logEvents?: FieldPolicy<any> | FieldReadFunction<any>
    logUserEvent?: FieldPolicy<any> | FieldReadFunction<any>
    mergeChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    moveBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    moveInsightSeriesBackfillToBackOfQueue?: FieldPolicy<any> | FieldReadFunction<any>
    moveInsightSeriesBackfillToFrontOfQueue?: FieldPolicy<any> | FieldReadFunction<any>
    overwriteTemporarySettings?: FieldPolicy<any> | FieldReadFunction<any>
    publishChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    queueAutoIndexJobsForRepo?: FieldPolicy<any> | FieldReadFunction<any>
    randomizeUserPassword?: FieldPolicy<any> | FieldReadFunction<any>
    recloneRepository?: FieldPolicy<any> | FieldReadFunction<any>
    recoverUsers?: FieldPolicy<any> | FieldReadFunction<any>
    reenqueueChangeset?: FieldPolicy<any> | FieldReadFunction<any>
    reenqueueChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    reindexLSIFIndex?: FieldPolicy<any> | FieldReadFunction<any>
    reindexLSIFIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    reindexPreciseIndex?: FieldPolicy<any> | FieldReadFunction<any>
    reindexPreciseIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    reindexRepository?: FieldPolicy<any> | FieldReadFunction<any>
    reloadSite?: FieldPolicy<any> | FieldReadFunction<any>
    removeInsightViewFromDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    removeTeamMembers?: FieldPolicy<any> | FieldReadFunction<any>
    removeUserEmail?: FieldPolicy<any> | FieldReadFunction<any>
    removeUserFromOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    replaceBatchSpecInput?: FieldPolicy<any> | FieldReadFunction<any>
    requestLanguageSupport?: FieldPolicy<any> | FieldReadFunction<any>
    resendOrganizationInvitationNotification?: FieldPolicy<any> | FieldReadFunction<any>
    resendVerificationEmail?: FieldPolicy<any> | FieldReadFunction<any>
    resetTriggerQueryTimestamps?: FieldPolicy<any> | FieldReadFunction<any>
    resolvePhabricatorDiff?: FieldPolicy<any> | FieldReadFunction<any>
    respondToOrganizationInvitation?: FieldPolicy<any> | FieldReadFunction<any>
    retryBatchSpecExecution?: FieldPolicy<any> | FieldReadFunction<any>
    retryBatchSpecWorkspaceExecution?: FieldPolicy<any> | FieldReadFunction<any>
    retryInsightSeriesBackfill?: FieldPolicy<any> | FieldReadFunction<any>
    revokeOrganizationInvitation?: FieldPolicy<any> | FieldReadFunction<any>
    saveInsightAsNewView?: FieldPolicy<any> | FieldReadFunction<any>
    scheduleRepositoryPermissionsSync?: FieldPolicy<any> | FieldReadFunction<any>
    scheduleUserPermissionsSync?: FieldPolicy<any> | FieldReadFunction<any>
    sendTestEmail?: FieldPolicy<any> | FieldReadFunction<any>
    setDefaultSearchContext?: FieldPolicy<any> | FieldReadFunction<any>
    setMigrationDirection?: FieldPolicy<any> | FieldReadFunction<any>
    setRepositoryPermissionsForBitbucketProject?: FieldPolicy<any> | FieldReadFunction<any>
    setRepositoryPermissionsForUsers?: FieldPolicy<any> | FieldReadFunction<any>
    setRepositoryPermissionsUnrestricted?: FieldPolicy<any> | FieldReadFunction<any>
    setSearchable?: FieldPolicy<any> | FieldReadFunction<any>
    setSubRepositoryPermissionsForUsers?: FieldPolicy<any> | FieldReadFunction<any>
    setTag?: FieldPolicy<any> | FieldReadFunction<any>
    setTeamMembers?: FieldPolicy<any> | FieldReadFunction<any>
    settingsMutation?: FieldPolicy<any> | FieldReadFunction<any>
    setTosAccepted?: FieldPolicy<any> | FieldReadFunction<any>
    setUserEmailPrimary?: FieldPolicy<any> | FieldReadFunction<any>
    setUserEmailVerified?: FieldPolicy<any> | FieldReadFunction<any>
    setUserIsSiteAdmin?: FieldPolicy<any> | FieldReadFunction<any>
    submitHappinessFeedback?: FieldPolicy<any> | FieldReadFunction<any>
    submitSurvey?: FieldPolicy<any> | FieldReadFunction<any>
    syncChangeset?: FieldPolicy<any> | FieldReadFunction<any>
    syncExternalService?: FieldPolicy<any> | FieldReadFunction<any>
    toggleBatchSpecAutoApply?: FieldPolicy<any> | FieldReadFunction<any>
    toggleCodeMonitor?: FieldPolicy<any> | FieldReadFunction<any>
    triggerObservabilityTestAlert?: FieldPolicy<any> | FieldReadFunction<any>
    triggerTestEmailAction?: FieldPolicy<any> | FieldReadFunction<any>
    triggerTestSlackWebhookAction?: FieldPolicy<any> | FieldReadFunction<any>
    triggerTestWebhookAction?: FieldPolicy<any> | FieldReadFunction<any>
    updateCodeIntelligenceConfigurationPolicy?: FieldPolicy<any> | FieldReadFunction<any>
    updateCodeIntelligenceInferenceScript?: FieldPolicy<any> | FieldReadFunction<any>
    updateCodeMonitor?: FieldPolicy<any> | FieldReadFunction<any>
    updateExecutorSecret?: FieldPolicy<any> | FieldReadFunction<any>
    updateExternalService?: FieldPolicy<any> | FieldReadFunction<any>
    updateFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    updateFeatureFlagOverride?: FieldPolicy<any> | FieldReadFunction<any>
    updateInsightsDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    updateInsightSeries?: FieldPolicy<any> | FieldReadFunction<any>
    updateLineChartSearchInsight?: FieldPolicy<any> | FieldReadFunction<any>
    updateMirrorRepository?: FieldPolicy<any> | FieldReadFunction<any>
    updateNotebook?: FieldPolicy<any> | FieldReadFunction<any>
    updateOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    updateOutboundWebhook?: FieldPolicy<any> | FieldReadFunction<any>
    updatePassword?: FieldPolicy<any> | FieldReadFunction<any>
    updatePieChartSearchInsight?: FieldPolicy<any> | FieldReadFunction<any>
    updateRepoKeyValuePair?: FieldPolicy<any> | FieldReadFunction<any>
    updateRepositoryIndexConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    updateSavedSearch?: FieldPolicy<any> | FieldReadFunction<any>
    updateSearchContext?: FieldPolicy<any> | FieldReadFunction<any>
    updateSiteConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    updateTeam?: FieldPolicy<any> | FieldReadFunction<any>
    updateUser?: FieldPolicy<any> | FieldReadFunction<any>
    updateWebhook?: FieldPolicy<any> | FieldReadFunction<any>
    upsertBatchSpecInput?: FieldPolicy<any> | FieldReadFunction<any>
    upsertEmptyBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NamespaceKeySpecifier = ('id' | 'namespaceName' | 'url' | NamespaceKeySpecifier)[]
export type NamespaceFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    namespaceName?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NewRepositoryConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | NewRepositoryConnectionKeySpecifier
)[]
export type NewRepositoryConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NewUsersConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | NewUsersConnectionKeySpecifier)[]
export type NewUsersConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NodeKeySpecifier = ('id' | NodeKeySpecifier)[]
export type NodeFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NonExhaustiveSearchAggregationResultKeySpecifier = (
    | 'approximateOtherGroupCount'
    | 'groups'
    | 'mode'
    | 'otherResultCount'
    | 'supportsPersistence'
    | NonExhaustiveSearchAggregationResultKeySpecifier
)[]
export type NonExhaustiveSearchAggregationResultFieldPolicy = {
    approximateOtherGroupCount?: FieldPolicy<any> | FieldReadFunction<any>
    groups?: FieldPolicy<any> | FieldReadFunction<any>
    mode?: FieldPolicy<any> | FieldReadFunction<any>
    otherResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    supportsPersistence?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotebookKeySpecifier = (
    | 'blocks'
    | 'createdAt'
    | 'creator'
    | 'id'
    | 'namespace'
    | 'public'
    | 'stars'
    | 'title'
    | 'updatedAt'
    | 'updater'
    | 'viewerCanManage'
    | 'viewerHasStarred'
    | NotebookKeySpecifier
)[]
export type NotebookFieldPolicy = {
    blocks?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    public?: FieldPolicy<any> | FieldReadFunction<any>
    stars?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    updater?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanManage?: FieldPolicy<any> | FieldReadFunction<any>
    viewerHasStarred?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotebookConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | NotebookConnectionKeySpecifier)[]
export type NotebookConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotebookStarKeySpecifier = ('createdAt' | 'user' | NotebookStarKeySpecifier)[]
export type NotebookStarFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotebookStarConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | NotebookStarConnectionKeySpecifier
)[]
export type NotebookStarConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrgKeySpecifier = (
    | 'batchChanges'
    | 'configurationCascade'
    | 'createdAt'
    | 'displayName'
    | 'executorSecrets'
    | 'id'
    | 'latestSettings'
    | 'members'
    | 'name'
    | 'namespaceName'
    | 'settingsCascade'
    | 'settingsURL'
    | 'url'
    | 'viewerCanAdminister'
    | 'viewerIsMember'
    | 'viewerPendingInvitation'
    | OrgKeySpecifier
)[]
export type OrgFieldPolicy = {
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    executorSecrets?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    members?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    namespaceName?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    viewerIsMember?: FieldPolicy<any> | FieldReadFunction<any>
    viewerPendingInvitation?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrganizationInvitationKeySpecifier = (
    | 'createdAt'
    | 'expiresAt'
    | 'id'
    | 'isVerifiedEmail'
    | 'notifiedAt'
    | 'organization'
    | 'recipient'
    | 'recipientEmail'
    | 'respondedAt'
    | 'respondURL'
    | 'responseType'
    | 'revokedAt'
    | 'sender'
    | OrganizationInvitationKeySpecifier
)[]
export type OrganizationInvitationFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    isVerifiedEmail?: FieldPolicy<any> | FieldReadFunction<any>
    notifiedAt?: FieldPolicy<any> | FieldReadFunction<any>
    organization?: FieldPolicy<any> | FieldReadFunction<any>
    recipient?: FieldPolicy<any> | FieldReadFunction<any>
    recipientEmail?: FieldPolicy<any> | FieldReadFunction<any>
    respondedAt?: FieldPolicy<any> | FieldReadFunction<any>
    respondURL?: FieldPolicy<any> | FieldReadFunction<any>
    responseType?: FieldPolicy<any> | FieldReadFunction<any>
    revokedAt?: FieldPolicy<any> | FieldReadFunction<any>
    sender?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrganizationMembershipKeySpecifier = (
    | 'createdAt'
    | 'organization'
    | 'updatedAt'
    | 'user'
    | OrganizationMembershipKeySpecifier
)[]
export type OrganizationMembershipFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    organization?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrganizationMembershipConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | OrganizationMembershipConnectionKeySpecifier
)[]
export type OrganizationMembershipConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrgConnectionKeySpecifier = ('nodes' | 'totalCount' | OrgConnectionKeySpecifier)[]
export type OrgConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrgMembersSummaryKeySpecifier = ('id' | 'invitesCount' | 'membersCount' | OrgMembersSummaryKeySpecifier)[]
export type OrgMembersSummaryFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    invitesCount?: FieldPolicy<any> | FieldReadFunction<any>
    membersCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundRequestKeySpecifier = (
    | 'callStack'
    | 'creationStackFrame'
    | 'durationMs'
    | 'errorMessage'
    | 'id'
    | 'method'
    | 'requestBody'
    | 'requestHeaders'
    | 'responseHeaders'
    | 'startedAt'
    | 'statusCode'
    | 'url'
    | OutboundRequestKeySpecifier
)[]
export type OutboundRequestFieldPolicy = {
    callStack?: FieldPolicy<any> | FieldReadFunction<any>
    creationStackFrame?: FieldPolicy<any> | FieldReadFunction<any>
    durationMs?: FieldPolicy<any> | FieldReadFunction<any>
    errorMessage?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    method?: FieldPolicy<any> | FieldReadFunction<any>
    requestBody?: FieldPolicy<any> | FieldReadFunction<any>
    requestHeaders?: FieldPolicy<any> | FieldReadFunction<any>
    responseHeaders?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    statusCode?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundRequestConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | OutboundRequestConnectionKeySpecifier
)[]
export type OutboundRequestConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundWebhookKeySpecifier = (
    | 'eventTypes'
    | 'id'
    | 'logs'
    | 'stats'
    | 'url'
    | OutboundWebhookKeySpecifier
)[]
export type OutboundWebhookFieldPolicy = {
    eventTypes?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    logs?: FieldPolicy<any> | FieldReadFunction<any>
    stats?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundWebhookConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | OutboundWebhookConnectionKeySpecifier
)[]
export type OutboundWebhookConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundWebhookEventTypeKeySpecifier = ('description' | 'key' | OutboundWebhookEventTypeKeySpecifier)[]
export type OutboundWebhookEventTypeFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>
    key?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundWebhookJobKeySpecifier = (
    | 'eventType'
    | 'id'
    | 'payload'
    | 'scope'
    | OutboundWebhookJobKeySpecifier
)[]
export type OutboundWebhookJobFieldPolicy = {
    eventType?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    payload?: FieldPolicy<any> | FieldReadFunction<any>
    scope?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundWebhookLogKeySpecifier = (
    | 'error'
    | 'id'
    | 'job'
    | 'request'
    | 'response'
    | 'sentAt'
    | 'statusCode'
    | OutboundWebhookLogKeySpecifier
)[]
export type OutboundWebhookLogFieldPolicy = {
    error?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    job?: FieldPolicy<any> | FieldReadFunction<any>
    request?: FieldPolicy<any> | FieldReadFunction<any>
    response?: FieldPolicy<any> | FieldReadFunction<any>
    sentAt?: FieldPolicy<any> | FieldReadFunction<any>
    statusCode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundWebhookLogConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | OutboundWebhookLogConnectionKeySpecifier
)[]
export type OutboundWebhookLogConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundWebhookLogStatsKeySpecifier = ('errored' | 'total' | OutboundWebhookLogStatsKeySpecifier)[]
export type OutboundWebhookLogStatsFieldPolicy = {
    errored?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutboundWebhookScopedEventTypeKeySpecifier = (
    | 'eventType'
    | 'scope'
    | OutboundWebhookScopedEventTypeKeySpecifier
)[]
export type OutboundWebhookScopedEventTypeFieldPolicy = {
    eventType?: FieldPolicy<any> | FieldReadFunction<any>
    scope?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutOfBandMigrationKeySpecifier = (
    | 'applyReverse'
    | 'component'
    | 'created'
    | 'deprecated'
    | 'description'
    | 'errors'
    | 'id'
    | 'introduced'
    | 'lastUpdated'
    | 'nonDestructive'
    | 'progress'
    | 'team'
    | OutOfBandMigrationKeySpecifier
)[]
export type OutOfBandMigrationFieldPolicy = {
    applyReverse?: FieldPolicy<any> | FieldReadFunction<any>
    component?: FieldPolicy<any> | FieldReadFunction<any>
    created?: FieldPolicy<any> | FieldReadFunction<any>
    deprecated?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    errors?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    introduced?: FieldPolicy<any> | FieldReadFunction<any>
    lastUpdated?: FieldPolicy<any> | FieldReadFunction<any>
    nonDestructive?: FieldPolicy<any> | FieldReadFunction<any>
    progress?: FieldPolicy<any> | FieldReadFunction<any>
    team?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutOfBandMigrationErrorKeySpecifier = ('created' | 'message' | OutOfBandMigrationErrorKeySpecifier)[]
export type OutOfBandMigrationErrorFieldPolicy = {
    created?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OwnableKeySpecifier = ('ownership' | OwnableKeySpecifier)[]
export type OwnableFieldPolicy = {
    ownership?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OwnershipKeySpecifier = ('owner' | 'reasons' | OwnershipKeySpecifier)[]
export type OwnershipFieldPolicy = {
    owner?: FieldPolicy<any> | FieldReadFunction<any>
    reasons?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OwnershipConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | OwnershipConnectionKeySpecifier)[]
export type OwnershipConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PackageRepoReferenceKeySpecifier = (
    | 'id'
    | 'name'
    | 'repository'
    | 'scheme'
    | 'versions'
    | PackageRepoReferenceKeySpecifier
)[]
export type PackageRepoReferenceFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    scheme?: FieldPolicy<any> | FieldReadFunction<any>
    versions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PackageRepoReferenceConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | PackageRepoReferenceConnectionKeySpecifier
)[]
export type PackageRepoReferenceConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PackageRepoReferenceVersionKeySpecifier = (
    | 'id'
    | 'packageRepoReferenceID'
    | 'version'
    | PackageRepoReferenceVersionKeySpecifier
)[]
export type PackageRepoReferenceVersionFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    packageRepoReferenceID?: FieldPolicy<any> | FieldReadFunction<any>
    version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | PageInfoKeySpecifier)[]
export type PageInfoFieldPolicy = {
    endCursor?: FieldPolicy<any> | FieldReadFunction<any>
    hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ParentSourcegraphDetailsKeySpecifier = ('url' | ParentSourcegraphDetailsKeySpecifier)[]
export type ParentSourcegraphDetailsFieldPolicy = {
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PermissionKeySpecifier = (
    | 'action'
    | 'createdAt'
    | 'displayName'
    | 'id'
    | 'namespace'
    | PermissionKeySpecifier
)[]
export type PermissionFieldPolicy = {
    action?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PermissionConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | PermissionConnectionKeySpecifier
)[]
export type PermissionConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PermissionsInfoKeySpecifier = (
    | 'permissions'
    | 'syncedAt'
    | 'unrestricted'
    | 'updatedAt'
    | PermissionsInfoKeySpecifier
)[]
export type PermissionsInfoFieldPolicy = {
    permissions?: FieldPolicy<any> | FieldReadFunction<any>
    syncedAt?: FieldPolicy<any> | FieldReadFunction<any>
    unrestricted?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PermissionsProviderStateKeySpecifier = (
    | 'id'
    | 'message'
    | 'status'
    | 'type'
    | PermissionsProviderStateKeySpecifier
)[]
export type PermissionsProviderStateFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PermissionsSyncJobKeySpecifier = (
    | 'completedAt'
    | 'id'
    | 'jobID'
    | 'message'
    | 'providers'
    | 'status'
    | 'type'
    | PermissionsSyncJobKeySpecifier
)[]
export type PermissionsSyncJobFieldPolicy = {
    completedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    jobID?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    providers?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PermissionsSyncJobsConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | PermissionsSyncJobsConnectionKeySpecifier
)[]
export type PermissionsSyncJobsConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PersonKeySpecifier = ('avatarURL' | 'displayName' | 'email' | 'name' | 'user' | PersonKeySpecifier)[]
export type PersonFieldPolicy = {
    avatarURL?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    email?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PhabricatorRepoKeySpecifier = ('callsign' | 'name' | 'uri' | 'url' | PhabricatorRepoKeySpecifier)[]
export type PhabricatorRepoFieldPolicy = {
    callsign?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    uri?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PieChartInsightViewPresentationKeySpecifier = (
    | 'otherThreshold'
    | 'title'
    | PieChartInsightViewPresentationKeySpecifier
)[]
export type PieChartInsightViewPresentationFieldPolicy = {
    otherThreshold?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PositionKeySpecifier = ('character' | 'line' | PositionKeySpecifier)[]
export type PositionFieldPolicy = {
    character?: FieldPolicy<any> | FieldReadFunction<any>
    line?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PreciseCodeIntelSupportKeySpecifier = ('indexers' | 'supportLevel' | PreciseCodeIntelSupportKeySpecifier)[]
export type PreciseCodeIntelSupportFieldPolicy = {
    indexers?: FieldPolicy<any> | FieldReadFunction<any>
    supportLevel?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PreciseIndexKeySpecifier = (
    | 'auditLogs'
    | 'failure'
    | 'id'
    | 'indexer'
    | 'indexingFinishedAt'
    | 'indexingStartedAt'
    | 'inputCommit'
    | 'inputIndexer'
    | 'inputRoot'
    | 'isLatestForRepo'
    | 'placeInQueue'
    | 'processingFinishedAt'
    | 'processingStartedAt'
    | 'projectRoot'
    | 'queuedAt'
    | 'retentionPolicyOverview'
    | 'shouldReindex'
    | 'state'
    | 'steps'
    | 'tags'
    | 'uploadedAt'
    | PreciseIndexKeySpecifier
)[]
export type PreciseIndexFieldPolicy = {
    auditLogs?: FieldPolicy<any> | FieldReadFunction<any>
    failure?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    indexingFinishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    indexingStartedAt?: FieldPolicy<any> | FieldReadFunction<any>
    inputCommit?: FieldPolicy<any> | FieldReadFunction<any>
    inputIndexer?: FieldPolicy<any> | FieldReadFunction<any>
    inputRoot?: FieldPolicy<any> | FieldReadFunction<any>
    isLatestForRepo?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    processingFinishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    processingStartedAt?: FieldPolicy<any> | FieldReadFunction<any>
    projectRoot?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    retentionPolicyOverview?: FieldPolicy<any> | FieldReadFunction<any>
    shouldReindex?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    steps?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    uploadedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PreciseIndexConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | PreciseIndexConnectionKeySpecifier
)[]
export type PreciseIndexConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PreIndexStepKeySpecifier = ('commands' | 'image' | 'logEntry' | 'root' | PreIndexStepKeySpecifier)[]
export type PreIndexStepFieldPolicy = {
    commands?: FieldPolicy<any> | FieldReadFunction<any>
    image?: FieldPolicy<any> | FieldReadFunction<any>
    logEntry?: FieldPolicy<any> | FieldReadFunction<any>
    root?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PreviewRepositoryComparisonKeySpecifier = (
    | 'baseRepository'
    | 'fileDiffs'
    | PreviewRepositoryComparisonKeySpecifier
)[]
export type PreviewRepositoryComparisonFieldPolicy = {
    baseRepository?: FieldPolicy<any> | FieldReadFunction<any>
    fileDiffs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductLicenseKeySpecifier = (
    | 'createdAt'
    | 'id'
    | 'info'
    | 'licenseKey'
    | 'subscription'
    | ProductLicenseKeySpecifier
)[]
export type ProductLicenseFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    info?: FieldPolicy<any> | FieldReadFunction<any>
    licenseKey?: FieldPolicy<any> | FieldReadFunction<any>
    subscription?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductLicenseConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ProductLicenseConnectionKeySpecifier
)[]
export type ProductLicenseConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductLicenseInfoKeySpecifier = (
    | 'expiresAt'
    | 'productNameWithBrand'
    | 'tags'
    | 'userCount'
    | ProductLicenseInfoKeySpecifier
)[]
export type ProductLicenseInfoFieldPolicy = {
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    productNameWithBrand?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    userCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionKeySpecifier = (
    | 'account'
    | 'activeLicense'
    | 'createdAt'
    | 'id'
    | 'isArchived'
    | 'name'
    | 'productLicenses'
    | 'url'
    | 'urlForSiteAdmin'
    | 'uuid'
    | ProductSubscriptionKeySpecifier
)[]
export type ProductSubscriptionFieldPolicy = {
    account?: FieldPolicy<any> | FieldReadFunction<any>
    activeLicense?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    isArchived?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    productLicenses?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    urlForSiteAdmin?: FieldPolicy<any> | FieldReadFunction<any>
    uuid?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | ProductSubscriptionConnectionKeySpecifier
)[]
export type ProductSubscriptionConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionStatusKeySpecifier = (
    | 'actualUserCount'
    | 'actualUserCountDate'
    | 'license'
    | 'maximumAllowedUserCount'
    | 'noLicenseWarningUserCount'
    | 'productNameWithBrand'
    | ProductSubscriptionStatusKeySpecifier
)[]
export type ProductSubscriptionStatusFieldPolicy = {
    actualUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    actualUserCountDate?: FieldPolicy<any> | FieldReadFunction<any>
    license?: FieldPolicy<any> | FieldReadFunction<any>
    maximumAllowedUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    noLicenseWarningUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    productNameWithBrand?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PublicExternalAccountDataKeySpecifier = (
    | 'displayName'
    | 'login'
    | 'url'
    | PublicExternalAccountDataKeySpecifier
)[]
export type PublicExternalAccountDataFieldPolicy = {
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    login?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryKeySpecifier = (
    | 'areExecutorsConfigured'
    | 'authorizedUserRepositories'
    | 'authzProviderTypes'
    | 'autocompleteMembersSearch'
    | 'availableBulkOperations'
    | 'backgroundJobs'
    | 'batchChange'
    | 'batchChanges'
    | 'batchChangesCodeHosts'
    | 'batchSpecs'
    | 'bitbucketProjectPermissionJobs'
    | 'checkBatchChangesCredential'
    | 'clientConfiguration'
    | 'codeHostSyncDue'
    | 'codeIntelligenceConfigurationPolicies'
    | 'codeIntelligenceInferenceScript'
    | 'compute'
    | 'currentUser'
    | 'defaultSearchContext'
    | 'dotcom'
    | 'enterpriseLicenseHasFeature'
    | 'evaluatedFeatureFlags'
    | 'evaluateFeatureFlag'
    | 'executors'
    | 'executorSecrets'
    | 'extensionRegistry'
    | 'externalServices'
    | 'featureFlag'
    | 'featureFlags'
    | 'globalChangesetsStats'
    | 'highlightCode'
    | 'insightAdminBackfillQueue'
    | 'insightsDashboards'
    | 'insightSeriesQueryStatus'
    | 'insightViewDebug'
    | 'insightViews'
    | 'invitationByToken'
    | 'isSearchContextAvailable'
    | 'lsifIndexes'
    | 'lsifUploads'
    | 'maxUnlicensedChangesets'
    | 'namespace'
    | 'namespaceByName'
    | 'node'
    | 'notebooks'
    | 'organization'
    | 'organizationFeatureFlagOverrides'
    | 'organizationFeatureFlagValue'
    | 'organizations'
    | 'orgMembersSummary'
    | 'outboundRequests'
    | 'outboundWebhookEventTypes'
    | 'outboundWebhooks'
    | 'outOfBandMigrations'
    | 'packageRepoReferences'
    | 'parseSearchQuery'
    | 'pendingInvitations'
    | 'permissions'
    | 'permissionsSyncJobs'
    | 'phabricatorRepo'
    | 'preciseIndexes'
    | 'previewRepositoriesFromQuery'
    | 'previewRepositoryFilter'
    | 'renderMarkdown'
    | 'repositories'
    | 'repository'
    | 'repositoryRedirect'
    | 'repositoryStats'
    | 'requestedLanguageSupport'
    | 'resolveWorkspacesForBatchSpec'
    | 'roles'
    | 'root'
    | 'savedSearches'
    | 'search'
    | 'searchContextBySpec'
    | 'searchContexts'
    | 'searchInsightLivePreview'
    | 'searchInsightPreview'
    | 'searchQueryAggregate'
    | 'settingsSubject'
    | 'site'
    | 'slowRequests'
    | 'statusMessages'
    | 'surveyResponses'
    | 'team'
    | 'teams'
    | 'temporarySettings'
    | 'user'
    | 'users'
    | 'usersWithPendingPermissions'
    | 'validateScopedInsightQuery'
    | 'viewerConfiguration'
    | 'viewerSettings'
    | 'webhookLogs'
    | 'webhooks'
    | QueryKeySpecifier
)[]
export type QueryFieldPolicy = {
    areExecutorsConfigured?: FieldPolicy<any> | FieldReadFunction<any>
    authorizedUserRepositories?: FieldPolicy<any> | FieldReadFunction<any>
    authzProviderTypes?: FieldPolicy<any> | FieldReadFunction<any>
    autocompleteMembersSearch?: FieldPolicy<any> | FieldReadFunction<any>
    availableBulkOperations?: FieldPolicy<any> | FieldReadFunction<any>
    backgroundJobs?: FieldPolicy<any> | FieldReadFunction<any>
    batchChange?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    batchChangesCodeHosts?: FieldPolicy<any> | FieldReadFunction<any>
    batchSpecs?: FieldPolicy<any> | FieldReadFunction<any>
    bitbucketProjectPermissionJobs?: FieldPolicy<any> | FieldReadFunction<any>
    checkBatchChangesCredential?: FieldPolicy<any> | FieldReadFunction<any>
    clientConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    codeHostSyncDue?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelligenceConfigurationPolicies?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelligenceInferenceScript?: FieldPolicy<any> | FieldReadFunction<any>
    compute?: FieldPolicy<any> | FieldReadFunction<any>
    currentUser?: FieldPolicy<any> | FieldReadFunction<any>
    defaultSearchContext?: FieldPolicy<any> | FieldReadFunction<any>
    dotcom?: FieldPolicy<any> | FieldReadFunction<any>
    enterpriseLicenseHasFeature?: FieldPolicy<any> | FieldReadFunction<any>
    evaluatedFeatureFlags?: FieldPolicy<any> | FieldReadFunction<any>
    evaluateFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    executors?: FieldPolicy<any> | FieldReadFunction<any>
    executorSecrets?: FieldPolicy<any> | FieldReadFunction<any>
    extensionRegistry?: FieldPolicy<any> | FieldReadFunction<any>
    externalServices?: FieldPolicy<any> | FieldReadFunction<any>
    featureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    featureFlags?: FieldPolicy<any> | FieldReadFunction<any>
    globalChangesetsStats?: FieldPolicy<any> | FieldReadFunction<any>
    highlightCode?: FieldPolicy<any> | FieldReadFunction<any>
    insightAdminBackfillQueue?: FieldPolicy<any> | FieldReadFunction<any>
    insightsDashboards?: FieldPolicy<any> | FieldReadFunction<any>
    insightSeriesQueryStatus?: FieldPolicy<any> | FieldReadFunction<any>
    insightViewDebug?: FieldPolicy<any> | FieldReadFunction<any>
    insightViews?: FieldPolicy<any> | FieldReadFunction<any>
    invitationByToken?: FieldPolicy<any> | FieldReadFunction<any>
    isSearchContextAvailable?: FieldPolicy<any> | FieldReadFunction<any>
    lsifIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
    maxUnlicensedChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    namespaceByName?: FieldPolicy<any> | FieldReadFunction<any>
    node?: FieldPolicy<any> | FieldReadFunction<any>
    notebooks?: FieldPolicy<any> | FieldReadFunction<any>
    organization?: FieldPolicy<any> | FieldReadFunction<any>
    organizationFeatureFlagOverrides?: FieldPolicy<any> | FieldReadFunction<any>
    organizationFeatureFlagValue?: FieldPolicy<any> | FieldReadFunction<any>
    organizations?: FieldPolicy<any> | FieldReadFunction<any>
    orgMembersSummary?: FieldPolicy<any> | FieldReadFunction<any>
    outboundRequests?: FieldPolicy<any> | FieldReadFunction<any>
    outboundWebhookEventTypes?: FieldPolicy<any> | FieldReadFunction<any>
    outboundWebhooks?: FieldPolicy<any> | FieldReadFunction<any>
    outOfBandMigrations?: FieldPolicy<any> | FieldReadFunction<any>
    packageRepoReferences?: FieldPolicy<any> | FieldReadFunction<any>
    parseSearchQuery?: FieldPolicy<any> | FieldReadFunction<any>
    pendingInvitations?: FieldPolicy<any> | FieldReadFunction<any>
    permissions?: FieldPolicy<any> | FieldReadFunction<any>
    permissionsSyncJobs?: FieldPolicy<any> | FieldReadFunction<any>
    phabricatorRepo?: FieldPolicy<any> | FieldReadFunction<any>
    preciseIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    previewRepositoriesFromQuery?: FieldPolicy<any> | FieldReadFunction<any>
    previewRepositoryFilter?: FieldPolicy<any> | FieldReadFunction<any>
    renderMarkdown?: FieldPolicy<any> | FieldReadFunction<any>
    repositories?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryRedirect?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryStats?: FieldPolicy<any> | FieldReadFunction<any>
    requestedLanguageSupport?: FieldPolicy<any> | FieldReadFunction<any>
    resolveWorkspacesForBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    roles?: FieldPolicy<any> | FieldReadFunction<any>
    root?: FieldPolicy<any> | FieldReadFunction<any>
    savedSearches?: FieldPolicy<any> | FieldReadFunction<any>
    search?: FieldPolicy<any> | FieldReadFunction<any>
    searchContextBySpec?: FieldPolicy<any> | FieldReadFunction<any>
    searchContexts?: FieldPolicy<any> | FieldReadFunction<any>
    searchInsightLivePreview?: FieldPolicy<any> | FieldReadFunction<any>
    searchInsightPreview?: FieldPolicy<any> | FieldReadFunction<any>
    searchQueryAggregate?: FieldPolicy<any> | FieldReadFunction<any>
    settingsSubject?: FieldPolicy<any> | FieldReadFunction<any>
    site?: FieldPolicy<any> | FieldReadFunction<any>
    slowRequests?: FieldPolicy<any> | FieldReadFunction<any>
    statusMessages?: FieldPolicy<any> | FieldReadFunction<any>
    surveyResponses?: FieldPolicy<any> | FieldReadFunction<any>
    team?: FieldPolicy<any> | FieldReadFunction<any>
    teams?: FieldPolicy<any> | FieldReadFunction<any>
    temporarySettings?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    users?: FieldPolicy<any> | FieldReadFunction<any>
    usersWithPendingPermissions?: FieldPolicy<any> | FieldReadFunction<any>
    validateScopedInsightQuery?: FieldPolicy<any> | FieldReadFunction<any>
    viewerConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    viewerSettings?: FieldPolicy<any> | FieldReadFunction<any>
    webhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
    webhooks?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryBlockKeySpecifier = ('id' | 'queryInput' | QueryBlockKeySpecifier)[]
export type QueryBlockFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    queryInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RandomizeUserPasswordResultKeySpecifier = (
    | 'emailSent'
    | 'resetPasswordURL'
    | RandomizeUserPasswordResultKeySpecifier
)[]
export type RandomizeUserPasswordResultFieldPolicy = {
    emailSent?: FieldPolicy<any> | FieldReadFunction<any>
    resetPasswordURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RangeKeySpecifier = ('end' | 'start' | RangeKeySpecifier)[]
export type RangeFieldPolicy = {
    end?: FieldPolicy<any> | FieldReadFunction<any>
    start?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RedirectKeySpecifier = ('url' | RedirectKeySpecifier)[]
export type RedirectFieldPolicy = {
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RegistryExtensionKeySpecifier = ('extensionID' | 'id' | 'manifest' | RegistryExtensionKeySpecifier)[]
export type RegistryExtensionFieldPolicy = {
    extensionID?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    manifest?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RegistryExtensionConnectionKeySpecifier = ('nodes' | RegistryExtensionConnectionKeySpecifier)[]
export type RegistryExtensionConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepoChangesetsStatsKeySpecifier = (
    | 'closed'
    | 'draft'
    | 'merged'
    | 'open'
    | 'total'
    | 'unpublished'
    | RepoChangesetsStatsKeySpecifier
)[]
export type RepoChangesetsStatsFieldPolicy = {
    closed?: FieldPolicy<any> | FieldReadFunction<any>
    draft?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    open?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
    unpublished?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepoCorruptionLogKeySpecifier = ('reason' | 'timestamp' | RepoCorruptionLogKeySpecifier)[]
export type RepoCorruptionLogFieldPolicy = {
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryKeySpecifier = (
    | 'authorizedUsers'
    | 'batchChanges'
    | 'batchChangesDiffStat'
    | 'branches'
    | 'changesetsStats'
    | 'cloneInProgress'
    | 'codeIntelligenceCommitGraph'
    | 'codeIntelSummary'
    | 'commit'
    | 'comparison'
    | 'contributors'
    | 'createdAt'
    | 'defaultBranch'
    | 'description'
    | 'detail'
    | 'diskSizeBytes'
    | 'externalRepository'
    | 'externalServices'
    | 'externalURLs'
    | 'firstEverCommit'
    | 'gitRefs'
    | 'id'
    | 'indexConfiguration'
    | 'isArchived'
    | 'isFork'
    | 'isPrivate'
    | 'keyValuePairs'
    | 'label'
    | 'language'
    | 'lsifIndexes'
    | 'lsifUploads'
    | 'matches'
    | 'mirrorInfo'
    | 'name'
    | 'permissionsInfo'
    | 'previewGitObjectFilter'
    | 'stars'
    | 'tags'
    | 'textSearchIndex'
    | 'updatedAt'
    | 'uri'
    | 'url'
    | 'viewerCanAdminister'
    | RepositoryKeySpecifier
)[]
export type RepositoryFieldPolicy = {
    authorizedUsers?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    batchChangesDiffStat?: FieldPolicy<any> | FieldReadFunction<any>
    branches?: FieldPolicy<any> | FieldReadFunction<any>
    changesetsStats?: FieldPolicy<any> | FieldReadFunction<any>
    cloneInProgress?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelligenceCommitGraph?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelSummary?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    comparison?: FieldPolicy<any> | FieldReadFunction<any>
    contributors?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    defaultBranch?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    detail?: FieldPolicy<any> | FieldReadFunction<any>
    diskSizeBytes?: FieldPolicy<any> | FieldReadFunction<any>
    externalRepository?: FieldPolicy<any> | FieldReadFunction<any>
    externalServices?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    firstEverCommit?: FieldPolicy<any> | FieldReadFunction<any>
    gitRefs?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    indexConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    isArchived?: FieldPolicy<any> | FieldReadFunction<any>
    isFork?: FieldPolicy<any> | FieldReadFunction<any>
    isPrivate?: FieldPolicy<any> | FieldReadFunction<any>
    keyValuePairs?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    language?: FieldPolicy<any> | FieldReadFunction<any>
    lsifIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
    mirrorInfo?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    permissionsInfo?: FieldPolicy<any> | FieldReadFunction<any>
    previewGitObjectFilter?: FieldPolicy<any> | FieldReadFunction<any>
    stars?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    textSearchIndex?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    uri?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryComparisonKeySpecifier = (
    | 'baseRepository'
    | 'commits'
    | 'fileDiffs'
    | 'headRepository'
    | 'range'
    | RepositoryComparisonKeySpecifier
)[]
export type RepositoryComparisonFieldPolicy = {
    baseRepository?: FieldPolicy<any> | FieldReadFunction<any>
    commits?: FieldPolicy<any> | FieldReadFunction<any>
    fileDiffs?: FieldPolicy<any> | FieldReadFunction<any>
    headRepository?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | RepositoryConnectionKeySpecifier
)[]
export type RepositoryConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryContributorKeySpecifier = (
    | 'commits'
    | 'count'
    | 'person'
    | 'repository'
    | RepositoryContributorKeySpecifier
)[]
export type RepositoryContributorFieldPolicy = {
    commits?: FieldPolicy<any> | FieldReadFunction<any>
    count?: FieldPolicy<any> | FieldReadFunction<any>
    person?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryContributorConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | RepositoryContributorConnectionKeySpecifier
)[]
export type RepositoryContributorConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryFilterPreviewKeySpecifier = (
    | 'limit'
    | 'matchesAllRepos'
    | 'nodes'
    | 'totalCount'
    | 'totalMatches'
    | RepositoryFilterPreviewKeySpecifier
)[]
export type RepositoryFilterPreviewFieldPolicy = {
    limit?: FieldPolicy<any> | FieldReadFunction<any>
    matchesAllRepos?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    totalMatches?: FieldPolicy<any> | FieldReadFunction<any>
}
export type repositoryIndexserverHostKeySpecifier = ('name' | repositoryIndexserverHostKeySpecifier)[]
export type repositoryIndexserverHostFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryPreviewPayloadKeySpecifier = (
    | 'numberOfRepositories'
    | 'query'
    | RepositoryPreviewPayloadKeySpecifier
)[]
export type RepositoryPreviewPayloadFieldPolicy = {
    numberOfRepositories?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositorySearchScopeKeySpecifier = ('allRepositories' | 'search' | RepositorySearchScopeKeySpecifier)[]
export type RepositorySearchScopeFieldPolicy = {
    allRepositories?: FieldPolicy<any> | FieldReadFunction<any>
    search?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryStatsKeySpecifier = (
    | 'cloned'
    | 'cloning'
    | 'corrupted'
    | 'failedFetch'
    | 'gitDirBytes'
    | 'indexed'
    | 'indexedLinesCount'
    | 'notCloned'
    | 'total'
    | RepositoryStatsKeySpecifier
)[]
export type RepositoryStatsFieldPolicy = {
    cloned?: FieldPolicy<any> | FieldReadFunction<any>
    cloning?: FieldPolicy<any> | FieldReadFunction<any>
    corrupted?: FieldPolicy<any> | FieldReadFunction<any>
    failedFetch?: FieldPolicy<any> | FieldReadFunction<any>
    gitDirBytes?: FieldPolicy<any> | FieldReadFunction<any>
    indexed?: FieldPolicy<any> | FieldReadFunction<any>
    indexedLinesCount?: FieldPolicy<any> | FieldReadFunction<any>
    notCloned?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryTextSearchIndexKeySpecifier = (
    | 'host'
    | 'refs'
    | 'repository'
    | 'status'
    | RepositoryTextSearchIndexKeySpecifier
)[]
export type RepositoryTextSearchIndexFieldPolicy = {
    host?: FieldPolicy<any> | FieldReadFunction<any>
    refs?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryTextSearchIndexedRefKeySpecifier = (
    | 'current'
    | 'indexed'
    | 'indexedCommit'
    | 'ref'
    | 'skippedIndexed'
    | RepositoryTextSearchIndexedRefKeySpecifier
)[]
export type RepositoryTextSearchIndexedRefFieldPolicy = {
    current?: FieldPolicy<any> | FieldReadFunction<any>
    indexed?: FieldPolicy<any> | FieldReadFunction<any>
    indexedCommit?: FieldPolicy<any> | FieldReadFunction<any>
    ref?: FieldPolicy<any> | FieldReadFunction<any>
    skippedIndexed?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryTextSearchIndexStatusKeySpecifier = (
    | 'contentByteSize'
    | 'contentFilesCount'
    | 'defaultBranchNewLinesCount'
    | 'indexByteSize'
    | 'indexShardsCount'
    | 'newLinesCount'
    | 'otherBranchesNewLinesCount'
    | 'updatedAt'
    | RepositoryTextSearchIndexStatusKeySpecifier
)[]
export type RepositoryTextSearchIndexStatusFieldPolicy = {
    contentByteSize?: FieldPolicy<any> | FieldReadFunction<any>
    contentFilesCount?: FieldPolicy<any> | FieldReadFunction<any>
    defaultBranchNewLinesCount?: FieldPolicy<any> | FieldReadFunction<any>
    indexByteSize?: FieldPolicy<any> | FieldReadFunction<any>
    indexShardsCount?: FieldPolicy<any> | FieldReadFunction<any>
    newLinesCount?: FieldPolicy<any> | FieldReadFunction<any>
    otherBranchesNewLinesCount?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResolvedBatchSpecWorkspaceKeySpecifier = (
    | 'branch'
    | 'ignored'
    | 'onlyFetchWorkspace'
    | 'path'
    | 'repository'
    | 'searchResultPaths'
    | 'unsupported'
    | ResolvedBatchSpecWorkspaceKeySpecifier
)[]
export type ResolvedBatchSpecWorkspaceFieldPolicy = {
    branch?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    onlyFetchWorkspace?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    searchResultPaths?: FieldPolicy<any> | FieldReadFunction<any>
    unsupported?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RoleKeySpecifier = ('createdAt' | 'id' | 'name' | 'permissions' | 'system' | RoleKeySpecifier)[]
export type RoleFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    permissions?: FieldPolicy<any> | FieldReadFunction<any>
    system?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RoleConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | RoleConnectionKeySpecifier)[]
export type RoleConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SavedSearchKeySpecifier = (
    | 'description'
    | 'id'
    | 'namespace'
    | 'notify'
    | 'notifySlack'
    | 'query'
    | 'slackWebhookURL'
    | SavedSearchKeySpecifier
)[]
export type SavedSearchFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    notify?: FieldPolicy<any> | FieldReadFunction<any>
    notifySlack?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    slackWebhookURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SavedSearchesConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | SavedSearchesConnectionKeySpecifier
)[]
export type SavedSearchesConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ScopedInsightQueryPayloadKeySpecifier = (
    | 'invalidReason'
    | 'isValid'
    | 'query'
    | ScopedInsightQueryPayloadKeySpecifier
)[]
export type ScopedInsightQueryPayloadFieldPolicy = {
    invalidReason?: FieldPolicy<any> | FieldReadFunction<any>
    isValid?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchKeySpecifier = ('results' | 'stats' | SearchKeySpecifier)[]
export type SearchFieldPolicy = {
    results?: FieldPolicy<any> | FieldReadFunction<any>
    stats?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchAggregationNotAvailableKeySpecifier = (
    | 'mode'
    | 'reason'
    | 'reasonType'
    | SearchAggregationNotAvailableKeySpecifier
)[]
export type SearchAggregationNotAvailableFieldPolicy = {
    mode?: FieldPolicy<any> | FieldReadFunction<any>
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    reasonType?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchAlertKeySpecifier = ('description' | 'kind' | 'proposedQueries' | 'title' | SearchAlertKeySpecifier)[]
export type SearchAlertFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    proposedQueries?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchBasedCodeIntelSupportKeySpecifier = (
    | 'language'
    | 'supportLevel'
    | SearchBasedCodeIntelSupportKeySpecifier
)[]
export type SearchBasedCodeIntelSupportFieldPolicy = {
    language?: FieldPolicy<any> | FieldReadFunction<any>
    supportLevel?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchContextKeySpecifier = (
    | 'autoDefined'
    | 'description'
    | 'id'
    | 'name'
    | 'namespace'
    | 'public'
    | 'query'
    | 'repositories'
    | 'spec'
    | 'updatedAt'
    | 'viewerCanManage'
    | 'viewerHasAsDefault'
    | 'viewerHasStarred'
    | SearchContextKeySpecifier
)[]
export type SearchContextFieldPolicy = {
    autoDefined?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    public?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    repositories?: FieldPolicy<any> | FieldReadFunction<any>
    spec?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanManage?: FieldPolicy<any> | FieldReadFunction<any>
    viewerHasAsDefault?: FieldPolicy<any> | FieldReadFunction<any>
    viewerHasStarred?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchContextConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | SearchContextConnectionKeySpecifier
)[]
export type SearchContextConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchContextRepositoryRevisionsKeySpecifier = (
    | 'repository'
    | 'revisions'
    | SearchContextRepositoryRevisionsKeySpecifier
)[]
export type SearchContextRepositoryRevisionsFieldPolicy = {
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    revisions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchFilterKeySpecifier = ('count' | 'kind' | 'label' | 'limitHit' | 'value' | SearchFilterKeySpecifier)[]
export type SearchFilterFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    limitHit?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchInsightDataSeriesDefinitionKeySpecifier = (
    | 'generatedFromCaptureGroups'
    | 'groupBy'
    | 'isCalculated'
    | 'query'
    | 'repositoryDefinition'
    | 'repositoryScope'
    | 'seriesId'
    | 'timeScope'
    | SearchInsightDataSeriesDefinitionKeySpecifier
)[]
export type SearchInsightDataSeriesDefinitionFieldPolicy = {
    generatedFromCaptureGroups?: FieldPolicy<any> | FieldReadFunction<any>
    groupBy?: FieldPolicy<any> | FieldReadFunction<any>
    isCalculated?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryDefinition?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryScope?: FieldPolicy<any> | FieldReadFunction<any>
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
    timeScope?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchInsightLivePreviewSeriesKeySpecifier = (
    | 'label'
    | 'points'
    | SearchInsightLivePreviewSeriesKeySpecifier
)[]
export type SearchInsightLivePreviewSeriesFieldPolicy = {
    label?: FieldPolicy<any> | FieldReadFunction<any>
    points?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchQueryAggregateKeySpecifier = (
    | 'aggregations'
    | 'modeAvailability'
    | SearchQueryAggregateKeySpecifier
)[]
export type SearchQueryAggregateFieldPolicy = {
    aggregations?: FieldPolicy<any> | FieldReadFunction<any>
    modeAvailability?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchQueryAnnotationKeySpecifier = ('name' | 'value' | SearchQueryAnnotationKeySpecifier)[]
export type SearchQueryAnnotationFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchQueryDescriptionKeySpecifier = (
    | 'annotations'
    | 'description'
    | 'query'
    | SearchQueryDescriptionKeySpecifier
)[]
export type SearchQueryDescriptionFieldPolicy = {
    annotations?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchResultMatchKeySpecifier = ('body' | 'highlights' | 'url' | SearchResultMatchKeySpecifier)[]
export type SearchResultMatchFieldPolicy = {
    body?: FieldPolicy<any> | FieldReadFunction<any>
    highlights?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchResultsKeySpecifier = (
    | 'alert'
    | 'approximateResultCount'
    | 'cloning'
    | 'dynamicFilters'
    | 'elapsedMilliseconds'
    | 'indexUnavailable'
    | 'limitHit'
    | 'matchCount'
    | 'missing'
    | 'repositories'
    | 'repositoriesCount'
    | 'resultCount'
    | 'results'
    | 'sparkline'
    | 'timedout'
    | SearchResultsKeySpecifier
)[]
export type SearchResultsFieldPolicy = {
    alert?: FieldPolicy<any> | FieldReadFunction<any>
    approximateResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    cloning?: FieldPolicy<any> | FieldReadFunction<any>
    dynamicFilters?: FieldPolicy<any> | FieldReadFunction<any>
    elapsedMilliseconds?: FieldPolicy<any> | FieldReadFunction<any>
    indexUnavailable?: FieldPolicy<any> | FieldReadFunction<any>
    limitHit?: FieldPolicy<any> | FieldReadFunction<any>
    matchCount?: FieldPolicy<any> | FieldReadFunction<any>
    missing?: FieldPolicy<any> | FieldReadFunction<any>
    repositories?: FieldPolicy<any> | FieldReadFunction<any>
    repositoriesCount?: FieldPolicy<any> | FieldReadFunction<any>
    resultCount?: FieldPolicy<any> | FieldReadFunction<any>
    results?: FieldPolicy<any> | FieldReadFunction<any>
    sparkline?: FieldPolicy<any> | FieldReadFunction<any>
    timedout?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchResultsStatsKeySpecifier = (
    | 'approximateResultCount'
    | 'languages'
    | 'sparkline'
    | SearchResultsStatsKeySpecifier
)[]
export type SearchResultsStatsFieldPolicy = {
    approximateResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    languages?: FieldPolicy<any> | FieldReadFunction<any>
    sparkline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeriesDisplayOptionsKeySpecifier = (
    | 'limit'
    | 'numSamples'
    | 'sortOptions'
    | SeriesDisplayOptionsKeySpecifier
)[]
export type SeriesDisplayOptionsFieldPolicy = {
    limit?: FieldPolicy<any> | FieldReadFunction<any>
    numSamples?: FieldPolicy<any> | FieldReadFunction<any>
    sortOptions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeriesSortOptionsKeySpecifier = ('direction' | 'mode' | SeriesSortOptionsKeySpecifier)[]
export type SeriesSortOptionsFieldPolicy = {
    direction?: FieldPolicy<any> | FieldReadFunction<any>
    mode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SessionKeySpecifier = ('canSignOut' | SessionKeySpecifier)[]
export type SessionFieldPolicy = {
    canSignOut?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SettingsKeySpecifier = (
    | 'author'
    | 'configuration'
    | 'contents'
    | 'createdAt'
    | 'id'
    | 'subject'
    | SettingsKeySpecifier
)[]
export type SettingsFieldPolicy = {
    author?: FieldPolicy<any> | FieldReadFunction<any>
    configuration?: FieldPolicy<any> | FieldReadFunction<any>
    contents?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    subject?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SettingsCascadeKeySpecifier = ('final' | 'merged' | 'subjects' | SettingsCascadeKeySpecifier)[]
export type SettingsCascadeFieldPolicy = {
    final?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    subjects?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SettingsMutationKeySpecifier = (
    | 'editConfiguration'
    | 'editSettings'
    | 'overwriteSettings'
    | SettingsMutationKeySpecifier
)[]
export type SettingsMutationFieldPolicy = {
    editConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    editSettings?: FieldPolicy<any> | FieldReadFunction<any>
    overwriteSettings?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SettingsSubjectKeySpecifier = (
    | 'configurationCascade'
    | 'id'
    | 'latestSettings'
    | 'settingsCascade'
    | 'settingsURL'
    | 'viewerCanAdminister'
    | SettingsSubjectKeySpecifier
)[]
export type SettingsSubjectFieldPolicy = {
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SignatureKeySpecifier = ('date' | 'person' | SignatureKeySpecifier)[]
export type SignatureFieldPolicy = {
    date?: FieldPolicy<any> | FieldReadFunction<any>
    person?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteKeySpecifier = (
    | 'accessTokens'
    | 'alerts'
    | 'allowEditExternalServicesWithFile'
    | 'allowSiteSettingsEdits'
    | 'analytics'
    | 'authProviders'
    | 'buildVersion'
    | 'canReloadSite'
    | 'configuration'
    | 'configurationCascade'
    | 'enableLegacyExtensions'
    | 'externalAccounts'
    | 'externalServicesFromFile'
    | 'freeUsersExceeded'
    | 'hasCodeIntelligence'
    | 'id'
    | 'latestSettings'
    | 'monitoringStatistics'
    | 'needsRepositoryConfiguration'
    | 'productSubscription'
    | 'productVersion'
    | 'sendsEmailVerificationEmails'
    | 'settingsCascade'
    | 'settingsURL'
    | 'siteID'
    | 'updateCheck'
    | 'usageStatistics'
    | 'users'
    | 'viewerCanAdminister'
    | SiteKeySpecifier
)[]
export type SiteFieldPolicy = {
    accessTokens?: FieldPolicy<any> | FieldReadFunction<any>
    alerts?: FieldPolicy<any> | FieldReadFunction<any>
    allowEditExternalServicesWithFile?: FieldPolicy<any> | FieldReadFunction<any>
    allowSiteSettingsEdits?: FieldPolicy<any> | FieldReadFunction<any>
    analytics?: FieldPolicy<any> | FieldReadFunction<any>
    authProviders?: FieldPolicy<any> | FieldReadFunction<any>
    buildVersion?: FieldPolicy<any> | FieldReadFunction<any>
    canReloadSite?: FieldPolicy<any> | FieldReadFunction<any>
    configuration?: FieldPolicy<any> | FieldReadFunction<any>
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
    enableLegacyExtensions?: FieldPolicy<any> | FieldReadFunction<any>
    externalAccounts?: FieldPolicy<any> | FieldReadFunction<any>
    externalServicesFromFile?: FieldPolicy<any> | FieldReadFunction<any>
    freeUsersExceeded?: FieldPolicy<any> | FieldReadFunction<any>
    hasCodeIntelligence?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    monitoringStatistics?: FieldPolicy<any> | FieldReadFunction<any>
    needsRepositoryConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    productSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    productVersion?: FieldPolicy<any> | FieldReadFunction<any>
    sendsEmailVerificationEmails?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    siteID?: FieldPolicy<any> | FieldReadFunction<any>
    updateCheck?: FieldPolicy<any> | FieldReadFunction<any>
    usageStatistics?: FieldPolicy<any> | FieldReadFunction<any>
    users?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteConfigurationKeySpecifier = (
    | 'effectiveContents'
    | 'history'
    | 'id'
    | 'validationMessages'
    | SiteConfigurationKeySpecifier
)[]
export type SiteConfigurationFieldPolicy = {
    effectiveContents?: FieldPolicy<any> | FieldReadFunction<any>
    history?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    validationMessages?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteConfigurationChangeKeySpecifier = (
    | 'author'
    | 'createdAt'
    | 'diff'
    | 'id'
    | 'reproducedDiff'
    | 'updatedAt'
    | SiteConfigurationChangeKeySpecifier
)[]
export type SiteConfigurationChangeFieldPolicy = {
    author?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    reproducedDiff?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteConfigurationChangeConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | SiteConfigurationChangeConnectionKeySpecifier
)[]
export type SiteConfigurationChangeConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteUsagePeriodKeySpecifier = (
    | 'anonymousUserCount'
    | 'integrationUserCount'
    | 'registeredUserCount'
    | 'startTime'
    | 'userCount'
    | SiteUsagePeriodKeySpecifier
)[]
export type SiteUsagePeriodFieldPolicy = {
    anonymousUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    integrationUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    registeredUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    startTime?: FieldPolicy<any> | FieldReadFunction<any>
    userCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteUsageStatisticsKeySpecifier = ('daus' | 'maus' | 'waus' | SiteUsageStatisticsKeySpecifier)[]
export type SiteUsageStatisticsFieldPolicy = {
    daus?: FieldPolicy<any> | FieldReadFunction<any>
    maus?: FieldPolicy<any> | FieldReadFunction<any>
    waus?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteUserKeySpecifier = (
    | 'createdAt'
    | 'deletedAt'
    | 'displayName'
    | 'email'
    | 'eventsCount'
    | 'id'
    | 'lastActiveAt'
    | 'locked'
    | 'siteAdmin'
    | 'username'
    | SiteUserKeySpecifier
)[]
export type SiteUserFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    email?: FieldPolicy<any> | FieldReadFunction<any>
    eventsCount?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    lastActiveAt?: FieldPolicy<any> | FieldReadFunction<any>
    locked?: FieldPolicy<any> | FieldReadFunction<any>
    siteAdmin?: FieldPolicy<any> | FieldReadFunction<any>
    username?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteUsersKeySpecifier = ('nodes' | 'totalCount' | SiteUsersKeySpecifier)[]
export type SiteUsersFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SkippedIndexedKeySpecifier = ('count' | 'query' | SkippedIndexedKeySpecifier)[]
export type SkippedIndexedFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SlowRequestKeySpecifier = (
    | 'duration'
    | 'errors'
    | 'filepath'
    | 'index'
    | 'name'
    | 'query'
    | 'repository'
    | 'source'
    | 'start'
    | 'user'
    | 'variables'
    | SlowRequestKeySpecifier
)[]
export type SlowRequestFieldPolicy = {
    duration?: FieldPolicy<any> | FieldReadFunction<any>
    errors?: FieldPolicy<any> | FieldReadFunction<any>
    filepath?: FieldPolicy<any> | FieldReadFunction<any>
    index?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    source?: FieldPolicy<any> | FieldReadFunction<any>
    start?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    variables?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SlowRequestConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | SlowRequestConnectionKeySpecifier
)[]
export type SlowRequestConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SubmoduleKeySpecifier = ('commit' | 'path' | 'url' | SubmoduleKeySpecifier)[]
export type SubmoduleFieldPolicy = {
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SurveyResponseKeySpecifier = (
    | 'better'
    | 'createdAt'
    | 'email'
    | 'id'
    | 'otherUseCase'
    | 'reason'
    | 'score'
    | 'user'
    | SurveyResponseKeySpecifier
)[]
export type SurveyResponseFieldPolicy = {
    better?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    email?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    otherUseCase?: FieldPolicy<any> | FieldReadFunction<any>
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    score?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SurveyResponseConnectionKeySpecifier = (
    | 'averageScore'
    | 'last30DaysCount'
    | 'netPromoterScore'
    | 'nodes'
    | 'totalCount'
    | SurveyResponseConnectionKeySpecifier
)[]
export type SurveyResponseConnectionFieldPolicy = {
    averageScore?: FieldPolicy<any> | FieldReadFunction<any>
    last30DaysCount?: FieldPolicy<any> | FieldReadFunction<any>
    netPromoterScore?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolKeySpecifier = (
    | 'canonicalURL'
    | 'containerName'
    | 'fileLocal'
    | 'kind'
    | 'language'
    | 'location'
    | 'name'
    | 'url'
    | SymbolKeySpecifier
)[]
export type SymbolFieldPolicy = {
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    containerName?: FieldPolicy<any> | FieldReadFunction<any>
    fileLocal?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    language?: FieldPolicy<any> | FieldReadFunction<any>
    location?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolBlockKeySpecifier = ('id' | 'symbolInput' | SymbolBlockKeySpecifier)[]
export type SymbolBlockFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    symbolInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolBlockInputKeySpecifier = (
    | 'filePath'
    | 'lineContext'
    | 'repositoryName'
    | 'revision'
    | 'symbolContainerName'
    | 'symbolKind'
    | 'symbolName'
    | SymbolBlockInputKeySpecifier
)[]
export type SymbolBlockInputFieldPolicy = {
    filePath?: FieldPolicy<any> | FieldReadFunction<any>
    lineContext?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryName?: FieldPolicy<any> | FieldReadFunction<any>
    revision?: FieldPolicy<any> | FieldReadFunction<any>
    symbolContainerName?: FieldPolicy<any> | FieldReadFunction<any>
    symbolKind?: FieldPolicy<any> | FieldReadFunction<any>
    symbolName?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolConnectionKeySpecifier = ('nodes' | 'pageInfo' | SymbolConnectionKeySpecifier)[]
export type SymbolConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolInfoKeySpecifier = ('definition' | 'hover' | SymbolInfoKeySpecifier)[]
export type SymbolInfoFieldPolicy = {
    definition?: FieldPolicy<any> | FieldReadFunction<any>
    hover?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolLocationKeySpecifier = (
    | 'character'
    | 'commit'
    | 'length'
    | 'line'
    | 'path'
    | 'range'
    | 'repo'
    | SymbolLocationKeySpecifier
)[]
export type SymbolLocationFieldPolicy = {
    character?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    length?: FieldPolicy<any> | FieldReadFunction<any>
    line?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
    repo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SyncErrorKeySpecifier = ('message' | SyncErrorKeySpecifier)[]
export type SyncErrorFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TeamKeySpecifier = (
    | 'childTeams'
    | 'displayName'
    | 'id'
    | 'members'
    | 'name'
    | 'parentTeam'
    | 'readonly'
    | 'url'
    | 'viewerCanAdminister'
    | TeamKeySpecifier
)[]
export type TeamFieldPolicy = {
    childTeams?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    members?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    parentTeam?: FieldPolicy<any> | FieldReadFunction<any>
    readonly?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TeamConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | TeamConnectionKeySpecifier)[]
export type TeamConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TeamMemberKeySpecifier = ('teams' | TeamMemberKeySpecifier)[]
export type TeamMemberFieldPolicy = {
    teams?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TeamMemberConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | TeamMemberConnectionKeySpecifier
)[]
export type TeamMemberConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TemporarySettingsKeySpecifier = ('contents' | TemporarySettingsKeySpecifier)[]
export type TemporarySettingsFieldPolicy = {
    contents?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TimeoutDatapointAlertKeySpecifier = ('time' | TimeoutDatapointAlertKeySpecifier)[]
export type TimeoutDatapointAlertFieldPolicy = {
    time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TreeEntryKeySpecifier = (
    | 'canonicalURL'
    | 'externalURLs'
    | 'isDirectory'
    | 'isSingleChild'
    | 'lsif'
    | 'name'
    | 'path'
    | 'submodule'
    | 'symbols'
    | 'url'
    | TreeEntryKeySpecifier
)[]
export type TreeEntryFieldPolicy = {
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    isSingleChild?: FieldPolicy<any> | FieldReadFunction<any>
    lsif?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    submodule?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TreeEntryLSIFDataKeySpecifier = ('diagnostics' | 'lsifUploads' | TreeEntryLSIFDataKeySpecifier)[]
export type TreeEntryLSIFDataFieldPolicy = {
    diagnostics?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdateCheckKeySpecifier = (
    | 'checkedAt'
    | 'errorMessage'
    | 'pending'
    | 'updateVersionAvailable'
    | UpdateCheckKeySpecifier
)[]
export type UpdateCheckFieldPolicy = {
    checkedAt?: FieldPolicy<any> | FieldReadFunction<any>
    errorMessage?: FieldPolicy<any> | FieldReadFunction<any>
    pending?: FieldPolicy<any> | FieldReadFunction<any>
    updateVersionAvailable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdateQueueKeySpecifier = ('index' | 'total' | 'updating' | UpdateQueueKeySpecifier)[]
export type UpdateQueueFieldPolicy = {
    index?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
    updating?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdateScheduleKeySpecifier = ('due' | 'index' | 'intervalSeconds' | 'total' | UpdateScheduleKeySpecifier)[]
export type UpdateScheduleFieldPolicy = {
    due?: FieldPolicy<any> | FieldReadFunction<any>
    index?: FieldPolicy<any> | FieldReadFunction<any>
    intervalSeconds?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdateSettingsPayloadKeySpecifier = ('empty' | UpdateSettingsPayloadKeySpecifier)[]
export type UpdateSettingsPayloadFieldPolicy = {
    empty?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserKeySpecifier = (
    | 'accessTokens'
    | 'avatarURL'
    | 'batchChanges'
    | 'batchChangesCodeHosts'
    | 'builtinAuth'
    | 'configurationCascade'
    | 'createdAt'
    | 'databaseID'
    | 'displayName'
    | 'email'
    | 'emails'
    | 'eventLogs'
    | 'executorSecrets'
    | 'externalAccounts'
    | 'id'
    | 'invitableCollaborators'
    | 'latestSettings'
    | 'monitors'
    | 'namespaceName'
    | 'organizationMemberships'
    | 'organizations'
    | 'permissions'
    | 'permissionsInfo'
    | 'roles'
    | 'searchable'
    | 'session'
    | 'settingsCascade'
    | 'settingsURL'
    | 'siteAdmin'
    | 'surveyResponses'
    | 'tags'
    | 'teams'
    | 'tosAccepted'
    | 'updatedAt'
    | 'url'
    | 'usageStatistics'
    | 'username'
    | 'viewerCanAdminister'
    | 'viewerCanChangeUsername'
    | UserKeySpecifier
)[]
export type UserFieldPolicy = {
    accessTokens?: FieldPolicy<any> | FieldReadFunction<any>
    avatarURL?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    batchChangesCodeHosts?: FieldPolicy<any> | FieldReadFunction<any>
    builtinAuth?: FieldPolicy<any> | FieldReadFunction<any>
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    databaseID?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    email?: FieldPolicy<any> | FieldReadFunction<any>
    emails?: FieldPolicy<any> | FieldReadFunction<any>
    eventLogs?: FieldPolicy<any> | FieldReadFunction<any>
    executorSecrets?: FieldPolicy<any> | FieldReadFunction<any>
    externalAccounts?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    invitableCollaborators?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    monitors?: FieldPolicy<any> | FieldReadFunction<any>
    namespaceName?: FieldPolicy<any> | FieldReadFunction<any>
    organizationMemberships?: FieldPolicy<any> | FieldReadFunction<any>
    organizations?: FieldPolicy<any> | FieldReadFunction<any>
    permissions?: FieldPolicy<any> | FieldReadFunction<any>
    permissionsInfo?: FieldPolicy<any> | FieldReadFunction<any>
    roles?: FieldPolicy<any> | FieldReadFunction<any>
    searchable?: FieldPolicy<any> | FieldReadFunction<any>
    session?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    siteAdmin?: FieldPolicy<any> | FieldReadFunction<any>
    surveyResponses?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    teams?: FieldPolicy<any> | FieldReadFunction<any>
    tosAccepted?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    usageStatistics?: FieldPolicy<any> | FieldReadFunction<any>
    username?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanChangeUsername?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | UserConnectionKeySpecifier)[]
export type UserConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserEmailKeySpecifier = (
    | 'email'
    | 'isPrimary'
    | 'user'
    | 'verificationPending'
    | 'verified'
    | 'viewerCanManuallyVerify'
    | UserEmailKeySpecifier
)[]
export type UserEmailFieldPolicy = {
    email?: FieldPolicy<any> | FieldReadFunction<any>
    isPrimary?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    verificationPending?: FieldPolicy<any> | FieldReadFunction<any>
    verified?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanManuallyVerify?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserPermissionKeySpecifier = ('bindID' | 'permission' | UserPermissionKeySpecifier)[]
export type UserPermissionFieldPolicy = {
    bindID?: FieldPolicy<any> | FieldReadFunction<any>
    permission?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUsageStatisticsKeySpecifier = (
    | 'codeIntelligenceActions'
    | 'findReferencesActions'
    | 'lastActiveCodeHostIntegrationTime'
    | 'lastActiveTime'
    | 'pageViews'
    | 'searchQueries'
    | UserUsageStatisticsKeySpecifier
)[]
export type UserUsageStatisticsFieldPolicy = {
    codeIntelligenceActions?: FieldPolicy<any> | FieldReadFunction<any>
    findReferencesActions?: FieldPolicy<any> | FieldReadFunction<any>
    lastActiveCodeHostIntegrationTime?: FieldPolicy<any> | FieldReadFunction<any>
    lastActiveTime?: FieldPolicy<any> | FieldReadFunction<any>
    pageViews?: FieldPolicy<any> | FieldReadFunction<any>
    searchQueries?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VirtualFileKeySpecifier = (
    | 'binary'
    | 'byteSize'
    | 'canonicalURL'
    | 'content'
    | 'externalURLs'
    | 'highlight'
    | 'isDirectory'
    | 'name'
    | 'path'
    | 'richHTML'
    | 'totalLines'
    | 'url'
    | VirtualFileKeySpecifier
)[]
export type VirtualFileFieldPolicy = {
    binary?: FieldPolicy<any> | FieldReadFunction<any>
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    richHTML?: FieldPolicy<any> | FieldReadFunction<any>
    totalLines?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleApplyPreviewTargetsAttachKeySpecifier = (
    | 'changesetSpec'
    | VisibleApplyPreviewTargetsAttachKeySpecifier
)[]
export type VisibleApplyPreviewTargetsAttachFieldPolicy = {
    changesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleApplyPreviewTargetsDetachKeySpecifier = (
    | 'changeset'
    | VisibleApplyPreviewTargetsDetachKeySpecifier
)[]
export type VisibleApplyPreviewTargetsDetachFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleApplyPreviewTargetsUpdateKeySpecifier = (
    | 'changeset'
    | 'changesetSpec'
    | VisibleApplyPreviewTargetsUpdateKeySpecifier
)[]
export type VisibleApplyPreviewTargetsUpdateFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
    changesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleBatchSpecWorkspaceKeySpecifier = (
    | 'batchSpec'
    | 'branch'
    | 'cachedResultFound'
    | 'changesetSpecs'
    | 'diffStat'
    | 'executor'
    | 'failureMessage'
    | 'finishedAt'
    | 'id'
    | 'ignored'
    | 'onlyFetchWorkspace'
    | 'path'
    | 'placeInGlobalQueue'
    | 'placeInQueue'
    | 'queuedAt'
    | 'repository'
    | 'searchResultPaths'
    | 'stages'
    | 'startedAt'
    | 'state'
    | 'step'
    | 'stepCacheResultCount'
    | 'steps'
    | 'unsupported'
    | VisibleBatchSpecWorkspaceKeySpecifier
)[]
export type VisibleBatchSpecWorkspaceFieldPolicy = {
    batchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    branch?: FieldPolicy<any> | FieldReadFunction<any>
    cachedResultFound?: FieldPolicy<any> | FieldReadFunction<any>
    changesetSpecs?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    executor?: FieldPolicy<any> | FieldReadFunction<any>
    failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    onlyFetchWorkspace?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    placeInGlobalQueue?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    searchResultPaths?: FieldPolicy<any> | FieldReadFunction<any>
    stages?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    step?: FieldPolicy<any> | FieldReadFunction<any>
    stepCacheResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    steps?: FieldPolicy<any> | FieldReadFunction<any>
    unsupported?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleChangesetApplyPreviewKeySpecifier = (
    | 'delta'
    | 'operations'
    | 'targets'
    | VisibleChangesetApplyPreviewKeySpecifier
)[]
export type VisibleChangesetApplyPreviewFieldPolicy = {
    delta?: FieldPolicy<any> | FieldReadFunction<any>
    operations?: FieldPolicy<any> | FieldReadFunction<any>
    targets?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleChangesetSpecKeySpecifier = (
    | 'description'
    | 'expiresAt'
    | 'forkTarget'
    | 'id'
    | 'type'
    | 'workspace'
    | VisibleChangesetSpecKeySpecifier
)[]
export type VisibleChangesetSpecFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    forkTarget?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
    workspace?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookKeySpecifier = (
    | 'codeHostKind'
    | 'codeHostURN'
    | 'createdAt'
    | 'createdBy'
    | 'id'
    | 'name'
    | 'secret'
    | 'updatedAt'
    | 'updatedBy'
    | 'url'
    | 'uuid'
    | 'webhookLogs'
    | WebhookKeySpecifier
)[]
export type WebhookFieldPolicy = {
    codeHostKind?: FieldPolicy<any> | FieldReadFunction<any>
    codeHostURN?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    createdBy?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    secret?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedBy?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    uuid?: FieldPolicy<any> | FieldReadFunction<any>
    webhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookConnectionKeySpecifier = ('nodes' | 'pageInfo' | 'totalCount' | WebhookConnectionKeySpecifier)[]
export type WebhookConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogKeySpecifier = (
    | 'externalService'
    | 'id'
    | 'receivedAt'
    | 'request'
    | 'response'
    | 'statusCode'
    | WebhookLogKeySpecifier
)[]
export type WebhookLogFieldPolicy = {
    externalService?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    receivedAt?: FieldPolicy<any> | FieldReadFunction<any>
    request?: FieldPolicy<any> | FieldReadFunction<any>
    response?: FieldPolicy<any> | FieldReadFunction<any>
    statusCode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogConnectionKeySpecifier = (
    | 'nodes'
    | 'pageInfo'
    | 'totalCount'
    | WebhookLogConnectionKeySpecifier
)[]
export type WebhookLogConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogMessageKeySpecifier = ('body' | 'headers' | WebhookLogMessageKeySpecifier)[]
export type WebhookLogMessageFieldPolicy = {
    body?: FieldPolicy<any> | FieldReadFunction<any>
    headers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogRequestKeySpecifier = (
    | 'body'
    | 'headers'
    | 'method'
    | 'url'
    | 'version'
    | WebhookLogRequestKeySpecifier
)[]
export type WebhookLogRequestFieldPolicy = {
    body?: FieldPolicy<any> | FieldReadFunction<any>
    headers?: FieldPolicy<any> | FieldReadFunction<any>
    method?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogResponseKeySpecifier = ('body' | 'headers' | WebhookLogResponseKeySpecifier)[]
export type WebhookLogResponseFieldPolicy = {
    body?: FieldPolicy<any> | FieldReadFunction<any>
    headers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrictTypedTypePolicies = {
    AccessToken?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AccessTokenKeySpecifier | (() => undefined | AccessTokenKeySpecifier)
        fields?: AccessTokenFieldPolicy
    }
    AccessTokenConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AccessTokenConnectionKeySpecifier | (() => undefined | AccessTokenConnectionKeySpecifier)
        fields?: AccessTokenConnectionFieldPolicy
    }
    AggregationGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AggregationGroupKeySpecifier | (() => undefined | AggregationGroupKeySpecifier)
        fields?: AggregationGroupFieldPolicy
    }
    AggregationModeAvailability?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AggregationModeAvailabilityKeySpecifier
            | (() => undefined | AggregationModeAvailabilityKeySpecifier)
        fields?: AggregationModeAvailabilityFieldPolicy
    }
    Alert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AlertKeySpecifier | (() => undefined | AlertKeySpecifier)
        fields?: AlertFieldPolicy
    }
    Analytics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsKeySpecifier | (() => undefined | AnalyticsKeySpecifier)
        fields?: AnalyticsFieldPolicy
    }
    AnalyticsBatchChangesResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsBatchChangesResultKeySpecifier
            | (() => undefined | AnalyticsBatchChangesResultKeySpecifier)
        fields?: AnalyticsBatchChangesResultFieldPolicy
    }
    AnalyticsCodeIntelByLanguageResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsCodeIntelByLanguageResultKeySpecifier
            | (() => undefined | AnalyticsCodeIntelByLanguageResultKeySpecifier)
        fields?: AnalyticsCodeIntelByLanguageResultFieldPolicy
    }
    AnalyticsCodeIntelRepositoryResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsCodeIntelRepositoryResultKeySpecifier
            | (() => undefined | AnalyticsCodeIntelRepositoryResultKeySpecifier)
        fields?: AnalyticsCodeIntelRepositoryResultFieldPolicy
    }
    AnalyticsCodeIntelResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsCodeIntelResultKeySpecifier
            | (() => undefined | AnalyticsCodeIntelResultKeySpecifier)
        fields?: AnalyticsCodeIntelResultFieldPolicy
    }
    AnalyticsCodesInsightsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsCodesInsightsResultKeySpecifier
            | (() => undefined | AnalyticsCodesInsightsResultKeySpecifier)
        fields?: AnalyticsCodesInsightsResultFieldPolicy
    }
    AnalyticsExtensionsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsExtensionsResultKeySpecifier
            | (() => undefined | AnalyticsExtensionsResultKeySpecifier)
        fields?: AnalyticsExtensionsResultFieldPolicy
    }
    AnalyticsMonthlyActiveUsers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsMonthlyActiveUsersKeySpecifier
            | (() => undefined | AnalyticsMonthlyActiveUsersKeySpecifier)
        fields?: AnalyticsMonthlyActiveUsersFieldPolicy
    }
    AnalyticsNotebooksResults?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsNotebooksResultsKeySpecifier
            | (() => undefined | AnalyticsNotebooksResultsKeySpecifier)
        fields?: AnalyticsNotebooksResultsFieldPolicy
    }
    AnalyticsReposSummartResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsReposSummartResultKeySpecifier
            | (() => undefined | AnalyticsReposSummartResultKeySpecifier)
        fields?: AnalyticsReposSummartResultFieldPolicy
    }
    AnalyticsSearchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsSearchResultKeySpecifier | (() => undefined | AnalyticsSearchResultKeySpecifier)
        fields?: AnalyticsSearchResultFieldPolicy
    }
    AnalyticsStatItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsStatItemKeySpecifier | (() => undefined | AnalyticsStatItemKeySpecifier)
        fields?: AnalyticsStatItemFieldPolicy
    }
    AnalyticsStatItemNode?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsStatItemNodeKeySpecifier | (() => undefined | AnalyticsStatItemNodeKeySpecifier)
        fields?: AnalyticsStatItemNodeFieldPolicy
    }
    AnalyticsStatItemSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsStatItemSummaryKeySpecifier
            | (() => undefined | AnalyticsStatItemSummaryKeySpecifier)
        fields?: AnalyticsStatItemSummaryFieldPolicy
    }
    AnalyticsUsersFrequencyItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsUsersFrequencyItemKeySpecifier
            | (() => undefined | AnalyticsUsersFrequencyItemKeySpecifier)
        fields?: AnalyticsUsersFrequencyItemFieldPolicy
    }
    AnalyticsUsersResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsUsersResultKeySpecifier | (() => undefined | AnalyticsUsersResultKeySpecifier)
        fields?: AnalyticsUsersResultFieldPolicy
    }
    AuditLogColumnChange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AuditLogColumnChangeKeySpecifier | (() => undefined | AuditLogColumnChangeKeySpecifier)
        fields?: AuditLogColumnChangeFieldPolicy
    }
    AuthProvider?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AuthProviderKeySpecifier | (() => undefined | AuthProviderKeySpecifier)
        fields?: AuthProviderFieldPolicy
    }
    AuthProviderConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AuthProviderConnectionKeySpecifier | (() => undefined | AuthProviderConnectionKeySpecifier)
        fields?: AuthProviderConnectionFieldPolicy
    }
    AutocompleteMemberSearchItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AutocompleteMemberSearchItemKeySpecifier
            | (() => undefined | AutocompleteMemberSearchItemKeySpecifier)
        fields?: AutocompleteMemberSearchItemFieldPolicy
    }
    BackfillStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BackfillStatusKeySpecifier | (() => undefined | BackfillStatusKeySpecifier)
        fields?: BackfillStatusFieldPolicy
    }
    BackgroundJob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BackgroundJobKeySpecifier | (() => undefined | BackgroundJobKeySpecifier)
        fields?: BackgroundJobFieldPolicy
    }
    BackgroundJobConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BackgroundJobConnectionKeySpecifier
            | (() => undefined | BackgroundJobConnectionKeySpecifier)
        fields?: BackgroundJobConnectionFieldPolicy
    }
    BackgroundRoutine?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BackgroundRoutineKeySpecifier | (() => undefined | BackgroundRoutineKeySpecifier)
        fields?: BackgroundRoutineFieldPolicy
    }
    BackgroundRoutineInstance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BackgroundRoutineInstanceKeySpecifier
            | (() => undefined | BackgroundRoutineInstanceKeySpecifier)
        fields?: BackgroundRoutineInstanceFieldPolicy
    }
    BackgroundRoutineRecentRun?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BackgroundRoutineRecentRunKeySpecifier
            | (() => undefined | BackgroundRoutineRecentRunKeySpecifier)
        fields?: BackgroundRoutineRecentRunFieldPolicy
    }
    BackgroundRoutineStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BackgroundRoutineStatsKeySpecifier | (() => undefined | BackgroundRoutineStatsKeySpecifier)
        fields?: BackgroundRoutineStatsFieldPolicy
    }
    BatchChange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangeKeySpecifier | (() => undefined | BatchChangeKeySpecifier)
        fields?: BatchChangeFieldPolicy
    }
    BatchChangeConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangeConnectionKeySpecifier | (() => undefined | BatchChangeConnectionKeySpecifier)
        fields?: BatchChangeConnectionFieldPolicy
    }
    BatchChangeDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangeDescriptionKeySpecifier | (() => undefined | BatchChangeDescriptionKeySpecifier)
        fields?: BatchChangeDescriptionFieldPolicy
    }
    BatchChangesCodeHost?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangesCodeHostKeySpecifier | (() => undefined | BatchChangesCodeHostKeySpecifier)
        fields?: BatchChangesCodeHostFieldPolicy
    }
    BatchChangesCodeHostConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchChangesCodeHostConnectionKeySpecifier
            | (() => undefined | BatchChangesCodeHostConnectionKeySpecifier)
        fields?: BatchChangesCodeHostConnectionFieldPolicy
    }
    BatchChangesCredential?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangesCredentialKeySpecifier | (() => undefined | BatchChangesCredentialKeySpecifier)
        fields?: BatchChangesCredentialFieldPolicy
    }
    BatchSpec?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecKeySpecifier | (() => undefined | BatchSpecKeySpecifier)
        fields?: BatchSpecFieldPolicy
    }
    BatchSpecConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecConnectionKeySpecifier | (() => undefined | BatchSpecConnectionKeySpecifier)
        fields?: BatchSpecConnectionFieldPolicy
    }
    BatchSpecWorkspace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecWorkspaceKeySpecifier | (() => undefined | BatchSpecWorkspaceKeySpecifier)
        fields?: BatchSpecWorkspaceFieldPolicy
    }
    BatchSpecWorkspaceConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceConnectionKeySpecifier
            | (() => undefined | BatchSpecWorkspaceConnectionKeySpecifier)
        fields?: BatchSpecWorkspaceConnectionFieldPolicy
    }
    BatchSpecWorkspaceEnvironmentVariable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceEnvironmentVariableKeySpecifier
            | (() => undefined | BatchSpecWorkspaceEnvironmentVariableKeySpecifier)
        fields?: BatchSpecWorkspaceEnvironmentVariableFieldPolicy
    }
    BatchSpecWorkspaceFile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecWorkspaceFileKeySpecifier | (() => undefined | BatchSpecWorkspaceFileKeySpecifier)
        fields?: BatchSpecWorkspaceFileFieldPolicy
    }
    BatchSpecWorkspaceFileConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceFileConnectionKeySpecifier
            | (() => undefined | BatchSpecWorkspaceFileConnectionKeySpecifier)
        fields?: BatchSpecWorkspaceFileConnectionFieldPolicy
    }
    BatchSpecWorkspaceOutputVariable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceOutputVariableKeySpecifier
            | (() => undefined | BatchSpecWorkspaceOutputVariableKeySpecifier)
        fields?: BatchSpecWorkspaceOutputVariableFieldPolicy
    }
    BatchSpecWorkspaceResolution?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceResolutionKeySpecifier
            | (() => undefined | BatchSpecWorkspaceResolutionKeySpecifier)
        fields?: BatchSpecWorkspaceResolutionFieldPolicy
    }
    BatchSpecWorkspacesStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspacesStatsKeySpecifier
            | (() => undefined | BatchSpecWorkspacesStatsKeySpecifier)
        fields?: BatchSpecWorkspacesStatsFieldPolicy
    }
    BatchSpecWorkspaceStages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceStagesKeySpecifier
            | (() => undefined | BatchSpecWorkspaceStagesKeySpecifier)
        fields?: BatchSpecWorkspaceStagesFieldPolicy
    }
    BatchSpecWorkspaceStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecWorkspaceStepKeySpecifier | (() => undefined | BatchSpecWorkspaceStepKeySpecifier)
        fields?: BatchSpecWorkspaceStepFieldPolicy
    }
    BatchSpecWorkspaceStepOutputLineConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceStepOutputLineConnectionKeySpecifier
            | (() => undefined | BatchSpecWorkspaceStepOutputLineConnectionKeySpecifier)
        fields?: BatchSpecWorkspaceStepOutputLineConnectionFieldPolicy
    }
    BehindAheadCounts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BehindAheadCountsKeySpecifier | (() => undefined | BehindAheadCountsKeySpecifier)
        fields?: BehindAheadCountsFieldPolicy
    }
    BidirectionalPageInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BidirectionalPageInfoKeySpecifier | (() => undefined | BidirectionalPageInfoKeySpecifier)
        fields?: BidirectionalPageInfoFieldPolicy
    }
    BitbucketProjectPermissionJob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BitbucketProjectPermissionJobKeySpecifier
            | (() => undefined | BitbucketProjectPermissionJobKeySpecifier)
        fields?: BitbucketProjectPermissionJobFieldPolicy
    }
    BitbucketProjectPermissionJobs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BitbucketProjectPermissionJobsKeySpecifier
            | (() => undefined | BitbucketProjectPermissionJobsKeySpecifier)
        fields?: BitbucketProjectPermissionJobsFieldPolicy
    }
    BulkOperation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BulkOperationKeySpecifier | (() => undefined | BulkOperationKeySpecifier)
        fields?: BulkOperationFieldPolicy
    }
    BulkOperationConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BulkOperationConnectionKeySpecifier
            | (() => undefined | BulkOperationConnectionKeySpecifier)
        fields?: BulkOperationConnectionFieldPolicy
    }
    Changeset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetKeySpecifier | (() => undefined | ChangesetKeySpecifier)
        fields?: ChangesetFieldPolicy
    }
    ChangesetApplyPreviewConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ChangesetApplyPreviewConnectionKeySpecifier
            | (() => undefined | ChangesetApplyPreviewConnectionKeySpecifier)
        fields?: ChangesetApplyPreviewConnectionFieldPolicy
    }
    ChangesetApplyPreviewConnectionStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ChangesetApplyPreviewConnectionStatsKeySpecifier
            | (() => undefined | ChangesetApplyPreviewConnectionStatsKeySpecifier)
        fields?: ChangesetApplyPreviewConnectionStatsFieldPolicy
    }
    ChangesetConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetConnectionKeySpecifier | (() => undefined | ChangesetConnectionKeySpecifier)
        fields?: ChangesetConnectionFieldPolicy
    }
    ChangesetCounts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetCountsKeySpecifier | (() => undefined | ChangesetCountsKeySpecifier)
        fields?: ChangesetCountsFieldPolicy
    }
    ChangesetEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetEventKeySpecifier | (() => undefined | ChangesetEventKeySpecifier)
        fields?: ChangesetEventFieldPolicy
    }
    ChangesetEventConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ChangesetEventConnectionKeySpecifier
            | (() => undefined | ChangesetEventConnectionKeySpecifier)
        fields?: ChangesetEventConnectionFieldPolicy
    }
    ChangesetJobError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetJobErrorKeySpecifier | (() => undefined | ChangesetJobErrorKeySpecifier)
        fields?: ChangesetJobErrorFieldPolicy
    }
    ChangesetLabel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetLabelKeySpecifier | (() => undefined | ChangesetLabelKeySpecifier)
        fields?: ChangesetLabelFieldPolicy
    }
    ChangesetSpec?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetSpecKeySpecifier | (() => undefined | ChangesetSpecKeySpecifier)
        fields?: ChangesetSpecFieldPolicy
    }
    ChangesetSpecConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ChangesetSpecConnectionKeySpecifier
            | (() => undefined | ChangesetSpecConnectionKeySpecifier)
        fields?: ChangesetSpecConnectionFieldPolicy
    }
    ChangesetSpecDelta?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetSpecDeltaKeySpecifier | (() => undefined | ChangesetSpecDeltaKeySpecifier)
        fields?: ChangesetSpecDeltaFieldPolicy
    }
    ChangesetsStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetsStatsKeySpecifier | (() => undefined | ChangesetsStatsKeySpecifier)
        fields?: ChangesetsStatsFieldPolicy
    }
    CheckMirrorRepositoryConnectionResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CheckMirrorRepositoryConnectionResultKeySpecifier
            | (() => undefined | CheckMirrorRepositoryConnectionResultKeySpecifier)
        fields?: CheckMirrorRepositoryConnectionResultFieldPolicy
    }
    ChunkMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChunkMatchKeySpecifier | (() => undefined | ChunkMatchKeySpecifier)
        fields?: ChunkMatchFieldPolicy
    }
    ClientConfigurationDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ClientConfigurationDetailsKeySpecifier
            | (() => undefined | ClientConfigurationDetailsKeySpecifier)
        fields?: ClientConfigurationDetailsFieldPolicy
    }
    CloningProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CloningProgressKeySpecifier | (() => undefined | CloningProgressKeySpecifier)
        fields?: CloningProgressFieldPolicy
    }
    CodeIntelCommit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelCommitKeySpecifier | (() => undefined | CodeIntelCommitKeySpecifier)
        fields?: CodeIntelCommitFieldPolicy
    }
    CodeIntelExternalRepository?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelExternalRepositoryKeySpecifier
            | (() => undefined | CodeIntelExternalRepositoryKeySpecifier)
        fields?: CodeIntelExternalRepositoryFieldPolicy
    }
    CodeIntelGitBlob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelGitBlobKeySpecifier | (() => undefined | CodeIntelGitBlobKeySpecifier)
        fields?: CodeIntelGitBlobFieldPolicy
    }
    CodeIntelGitObject?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelGitObjectKeySpecifier | (() => undefined | CodeIntelGitObjectKeySpecifier)
        fields?: CodeIntelGitObjectFieldPolicy
    }
    CodeIntelGitTree?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelGitTreeKeySpecifier | (() => undefined | CodeIntelGitTreeKeySpecifier)
        fields?: CodeIntelGitTreeFieldPolicy
    }
    CodeIntelIndexer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelIndexerKeySpecifier | (() => undefined | CodeIntelIndexerKeySpecifier)
        fields?: CodeIntelIndexerFieldPolicy
    }
    CodeIntelligenceCommitGraph?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceCommitGraphKeySpecifier
            | (() => undefined | CodeIntelligenceCommitGraphKeySpecifier)
        fields?: CodeIntelligenceCommitGraphFieldPolicy
    }
    CodeIntelligenceConfigurationPolicy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceConfigurationPolicyKeySpecifier
            | (() => undefined | CodeIntelligenceConfigurationPolicyKeySpecifier)
        fields?: CodeIntelligenceConfigurationPolicyFieldPolicy
    }
    CodeIntelligenceConfigurationPolicyConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceConfigurationPolicyConnectionKeySpecifier
            | (() => undefined | CodeIntelligenceConfigurationPolicyConnectionKeySpecifier)
        fields?: CodeIntelligenceConfigurationPolicyConnectionFieldPolicy
    }
    CodeIntelligenceRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelligenceRangeKeySpecifier | (() => undefined | CodeIntelligenceRangeKeySpecifier)
        fields?: CodeIntelligenceRangeFieldPolicy
    }
    CodeIntelligenceRangeConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceRangeConnectionKeySpecifier
            | (() => undefined | CodeIntelligenceRangeConnectionKeySpecifier)
        fields?: CodeIntelligenceRangeConnectionFieldPolicy
    }
    CodeIntelligenceRetentionPolicyMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceRetentionPolicyMatchKeySpecifier
            | (() => undefined | CodeIntelligenceRetentionPolicyMatchKeySpecifier)
        fields?: CodeIntelligenceRetentionPolicyMatchFieldPolicy
    }
    CodeIntelligenceRetentionPolicyMatchesConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceRetentionPolicyMatchesConnectionKeySpecifier
            | (() => undefined | CodeIntelligenceRetentionPolicyMatchesConnectionKeySpecifier)
        fields?: CodeIntelligenceRetentionPolicyMatchesConnectionFieldPolicy
    }
    CodeIntelRepository?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelRepositoryKeySpecifier | (() => undefined | CodeIntelRepositoryKeySpecifier)
        fields?: CodeIntelRepositoryFieldPolicy
    }
    CodeIntelRepositorySummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelRepositorySummaryKeySpecifier
            | (() => undefined | CodeIntelRepositorySummaryKeySpecifier)
        fields?: CodeIntelRepositorySummaryFieldPolicy
    }
    CodeIntelSupport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelSupportKeySpecifier | (() => undefined | CodeIntelSupportKeySpecifier)
        fields?: CodeIntelSupportFieldPolicy
    }
    CodeownersFileEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeownersFileEntryKeySpecifier | (() => undefined | CodeownersFileEntryKeySpecifier)
        fields?: CodeownersFileEntryFieldPolicy
    }
    CommitSearchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CommitSearchResultKeySpecifier | (() => undefined | CommitSearchResultKeySpecifier)
        fields?: CommitSearchResultFieldPolicy
    }
    ComputeEnvironmentEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ComputeEnvironmentEntryKeySpecifier
            | (() => undefined | ComputeEnvironmentEntryKeySpecifier)
        fields?: ComputeEnvironmentEntryFieldPolicy
    }
    ComputeMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ComputeMatchKeySpecifier | (() => undefined | ComputeMatchKeySpecifier)
        fields?: ComputeMatchFieldPolicy
    }
    ComputeMatchContext?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ComputeMatchContextKeySpecifier | (() => undefined | ComputeMatchContextKeySpecifier)
        fields?: ComputeMatchContextFieldPolicy
    }
    ComputeText?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ComputeTextKeySpecifier | (() => undefined | ComputeTextKeySpecifier)
        fields?: ComputeTextFieldPolicy
    }
    Configuration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ConfigurationKeySpecifier | (() => undefined | ConfigurationKeySpecifier)
        fields?: ConfigurationFieldPolicy
    }
    ConfigurationCascade?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ConfigurationCascadeKeySpecifier | (() => undefined | ConfigurationCascadeKeySpecifier)
        fields?: ConfigurationCascadeFieldPolicy
    }
    Connection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ConnectionKeySpecifier | (() => undefined | ConnectionKeySpecifier)
        fields?: ConnectionFieldPolicy
    }
    ConnectionPageInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ConnectionPageInfoKeySpecifier | (() => undefined | ConnectionPageInfoKeySpecifier)
        fields?: ConnectionPageInfoFieldPolicy
    }
    CreateAccessTokenResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CreateAccessTokenResultKeySpecifier
            | (() => undefined | CreateAccessTokenResultKeySpecifier)
        fields?: CreateAccessTokenResultFieldPolicy
    }
    CreateUserResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CreateUserResultKeySpecifier | (() => undefined | CreateUserResultKeySpecifier)
        fields?: CreateUserResultFieldPolicy
    }
    DefaultSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DefaultSettingsKeySpecifier | (() => undefined | DefaultSettingsKeySpecifier)
        fields?: DefaultSettingsFieldPolicy
    }
    Diagnostic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DiagnosticKeySpecifier | (() => undefined | DiagnosticKeySpecifier)
        fields?: DiagnosticFieldPolicy
    }
    DiagnosticConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DiagnosticConnectionKeySpecifier | (() => undefined | DiagnosticConnectionKeySpecifier)
        fields?: DiagnosticConnectionFieldPolicy
    }
    Diff?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DiffKeySpecifier | (() => undefined | DiffKeySpecifier)
        fields?: DiffFieldPolicy
    }
    DiffStat?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DiffStatKeySpecifier | (() => undefined | DiffStatKeySpecifier)
        fields?: DiffStatFieldPolicy
    }
    DotcomMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DotcomMutationKeySpecifier | (() => undefined | DotcomMutationKeySpecifier)
        fields?: DotcomMutationFieldPolicy
    }
    DotcomQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DotcomQueryKeySpecifier | (() => undefined | DotcomQueryKeySpecifier)
        fields?: DotcomQueryFieldPolicy
    }
    EmptyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | EmptyResponseKeySpecifier | (() => undefined | EmptyResponseKeySpecifier)
        fields?: EmptyResponseFieldPolicy
    }
    Error?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ErrorKeySpecifier | (() => undefined | ErrorKeySpecifier)
        fields?: ErrorFieldPolicy
    }
    ErrorExtensions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ErrorExtensionsKeySpecifier | (() => undefined | ErrorExtensionsKeySpecifier)
        fields?: ErrorExtensionsFieldPolicy
    }
    EvaluatedFeatureFlag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | EvaluatedFeatureFlagKeySpecifier | (() => undefined | EvaluatedFeatureFlagKeySpecifier)
        fields?: EvaluatedFeatureFlagFieldPolicy
    }
    EventLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | EventLogKeySpecifier | (() => undefined | EventLogKeySpecifier)
        fields?: EventLogFieldPolicy
    }
    EventLogsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | EventLogsConnectionKeySpecifier | (() => undefined | EventLogsConnectionKeySpecifier)
        fields?: EventLogsConnectionFieldPolicy
    }
    ExecutionLogEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExecutionLogEntryKeySpecifier | (() => undefined | ExecutionLogEntryKeySpecifier)
        fields?: ExecutionLogEntryFieldPolicy
    }
    Executor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExecutorKeySpecifier | (() => undefined | ExecutorKeySpecifier)
        fields?: ExecutorFieldPolicy
    }
    ExecutorConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExecutorConnectionKeySpecifier | (() => undefined | ExecutorConnectionKeySpecifier)
        fields?: ExecutorConnectionFieldPolicy
    }
    ExecutorSecret?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExecutorSecretKeySpecifier | (() => undefined | ExecutorSecretKeySpecifier)
        fields?: ExecutorSecretFieldPolicy
    }
    ExecutorSecretAccessLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExecutorSecretAccessLogKeySpecifier
            | (() => undefined | ExecutorSecretAccessLogKeySpecifier)
        fields?: ExecutorSecretAccessLogFieldPolicy
    }
    ExecutorSecretAccessLogConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExecutorSecretAccessLogConnectionKeySpecifier
            | (() => undefined | ExecutorSecretAccessLogConnectionKeySpecifier)
        fields?: ExecutorSecretAccessLogConnectionFieldPolicy
    }
    ExecutorSecretConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExecutorSecretConnectionKeySpecifier
            | (() => undefined | ExecutorSecretConnectionKeySpecifier)
        fields?: ExecutorSecretConnectionFieldPolicy
    }
    ExhaustiveSearchAggregationResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExhaustiveSearchAggregationResultKeySpecifier
            | (() => undefined | ExhaustiveSearchAggregationResultKeySpecifier)
        fields?: ExhaustiveSearchAggregationResultFieldPolicy
    }
    ExistingChangesetReference?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExistingChangesetReferenceKeySpecifier
            | (() => undefined | ExistingChangesetReferenceKeySpecifier)
        fields?: ExistingChangesetReferenceFieldPolicy
    }
    ExtensionManifest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExtensionManifestKeySpecifier | (() => undefined | ExtensionManifestKeySpecifier)
        fields?: ExtensionManifestFieldPolicy
    }
    ExtensionRegistry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExtensionRegistryKeySpecifier | (() => undefined | ExtensionRegistryKeySpecifier)
        fields?: ExtensionRegistryFieldPolicy
    }
    ExternalAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalAccountKeySpecifier | (() => undefined | ExternalAccountKeySpecifier)
        fields?: ExternalAccountFieldPolicy
    }
    ExternalAccountConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalAccountConnectionKeySpecifier
            | (() => undefined | ExternalAccountConnectionKeySpecifier)
        fields?: ExternalAccountConnectionFieldPolicy
    }
    ExternalChangeset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalChangesetKeySpecifier | (() => undefined | ExternalChangesetKeySpecifier)
        fields?: ExternalChangesetFieldPolicy
    }
    ExternalLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalLinkKeySpecifier | (() => undefined | ExternalLinkKeySpecifier)
        fields?: ExternalLinkFieldPolicy
    }
    ExternalRepository?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalRepositoryKeySpecifier | (() => undefined | ExternalRepositoryKeySpecifier)
        fields?: ExternalRepositoryFieldPolicy
    }
    ExternalService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalServiceKeySpecifier | (() => undefined | ExternalServiceKeySpecifier)
        fields?: ExternalServiceFieldPolicy
    }
    ExternalServiceAvailabilityUnknown?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceAvailabilityUnknownKeySpecifier
            | (() => undefined | ExternalServiceAvailabilityUnknownKeySpecifier)
        fields?: ExternalServiceAvailabilityUnknownFieldPolicy
    }
    ExternalServiceAvailable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceAvailableKeySpecifier
            | (() => undefined | ExternalServiceAvailableKeySpecifier)
        fields?: ExternalServiceAvailableFieldPolicy
    }
    ExternalServiceConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceConnectionKeySpecifier
            | (() => undefined | ExternalServiceConnectionKeySpecifier)
        fields?: ExternalServiceConnectionFieldPolicy
    }
    ExternalServiceSyncError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceSyncErrorKeySpecifier
            | (() => undefined | ExternalServiceSyncErrorKeySpecifier)
        fields?: ExternalServiceSyncErrorFieldPolicy
    }
    ExternalServiceSyncJob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalServiceSyncJobKeySpecifier | (() => undefined | ExternalServiceSyncJobKeySpecifier)
        fields?: ExternalServiceSyncJobFieldPolicy
    }
    ExternalServiceSyncJobConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceSyncJobConnectionKeySpecifier
            | (() => undefined | ExternalServiceSyncJobConnectionKeySpecifier)
        fields?: ExternalServiceSyncJobConnectionFieldPolicy
    }
    ExternalServiceUnavailable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceUnavailableKeySpecifier
            | (() => undefined | ExternalServiceUnavailableKeySpecifier)
        fields?: ExternalServiceUnavailableFieldPolicy
    }
    FeatureFlagBoolean?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FeatureFlagBooleanKeySpecifier | (() => undefined | FeatureFlagBooleanKeySpecifier)
        fields?: FeatureFlagBooleanFieldPolicy
    }
    FeatureFlagOverride?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FeatureFlagOverrideKeySpecifier | (() => undefined | FeatureFlagOverrideKeySpecifier)
        fields?: FeatureFlagOverrideFieldPolicy
    }
    FeatureFlagRollout?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FeatureFlagRolloutKeySpecifier | (() => undefined | FeatureFlagRolloutKeySpecifier)
        fields?: FeatureFlagRolloutFieldPolicy
    }
    File?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileKeySpecifier | (() => undefined | FileKeySpecifier)
        fields?: FileFieldPolicy
    }
    File2?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | File2KeySpecifier | (() => undefined | File2KeySpecifier)
        fields?: File2FieldPolicy
    }
    FileBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileBlockKeySpecifier | (() => undefined | FileBlockKeySpecifier)
        fields?: FileBlockFieldPolicy
    }
    FileBlockInput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileBlockInputKeySpecifier | (() => undefined | FileBlockInputKeySpecifier)
        fields?: FileBlockInputFieldPolicy
    }
    FileBlockLineRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileBlockLineRangeKeySpecifier | (() => undefined | FileBlockLineRangeKeySpecifier)
        fields?: FileBlockLineRangeFieldPolicy
    }
    FileDiff?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileDiffKeySpecifier | (() => undefined | FileDiffKeySpecifier)
        fields?: FileDiffFieldPolicy
    }
    FileDiffConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileDiffConnectionKeySpecifier | (() => undefined | FileDiffConnectionKeySpecifier)
        fields?: FileDiffConnectionFieldPolicy
    }
    FileDiffHunk?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileDiffHunkKeySpecifier | (() => undefined | FileDiffHunkKeySpecifier)
        fields?: FileDiffHunkFieldPolicy
    }
    FileDiffHunkRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileDiffHunkRangeKeySpecifier | (() => undefined | FileDiffHunkRangeKeySpecifier)
        fields?: FileDiffHunkRangeFieldPolicy
    }
    FileMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileMatchKeySpecifier | (() => undefined | FileMatchKeySpecifier)
        fields?: FileMatchFieldPolicy
    }
    ForkTarget?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ForkTargetKeySpecifier | (() => undefined | ForkTargetKeySpecifier)
        fields?: ForkTargetFieldPolicy
    }
    GenericIncompleteDatapointAlert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | GenericIncompleteDatapointAlertKeySpecifier
            | (() => undefined | GenericIncompleteDatapointAlertKeySpecifier)
        fields?: GenericIncompleteDatapointAlertFieldPolicy
    }
    GenericSearchResultInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | GenericSearchResultInterfaceKeySpecifier
            | (() => undefined | GenericSearchResultInterfaceKeySpecifier)
        fields?: GenericSearchResultInterfaceFieldPolicy
    }
    GitBlob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitBlobKeySpecifier | (() => undefined | GitBlobKeySpecifier)
        fields?: GitBlobFieldPolicy
    }
    GitBlobLSIFData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitBlobLSIFDataKeySpecifier | (() => undefined | GitBlobLSIFDataKeySpecifier)
        fields?: GitBlobLSIFDataFieldPolicy
    }
    GitBranchChangesetDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | GitBranchChangesetDescriptionKeySpecifier
            | (() => undefined | GitBranchChangesetDescriptionKeySpecifier)
        fields?: GitBranchChangesetDescriptionFieldPolicy
    }
    GitCommit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitCommitKeySpecifier | (() => undefined | GitCommitKeySpecifier)
        fields?: GitCommitFieldPolicy
    }
    GitCommitConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitCommitConnectionKeySpecifier | (() => undefined | GitCommitConnectionKeySpecifier)
        fields?: GitCommitConnectionFieldPolicy
    }
    GitCommitDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitCommitDescriptionKeySpecifier | (() => undefined | GitCommitDescriptionKeySpecifier)
        fields?: GitCommitDescriptionFieldPolicy
    }
    GitObject?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitObjectKeySpecifier | (() => undefined | GitObjectKeySpecifier)
        fields?: GitObjectFieldPolicy
    }
    GitObjectFilterPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitObjectFilterPreviewKeySpecifier | (() => undefined | GitObjectFilterPreviewKeySpecifier)
        fields?: GitObjectFilterPreviewFieldPolicy
    }
    GitRef?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitRefKeySpecifier | (() => undefined | GitRefKeySpecifier)
        fields?: GitRefFieldPolicy
    }
    GitRefConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitRefConnectionKeySpecifier | (() => undefined | GitRefConnectionKeySpecifier)
        fields?: GitRefConnectionFieldPolicy
    }
    GitRevisionRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitRevisionRangeKeySpecifier | (() => undefined | GitRevisionRangeKeySpecifier)
        fields?: GitRevisionRangeFieldPolicy
    }
    GitRevSpecExpr?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitRevSpecExprKeySpecifier | (() => undefined | GitRevSpecExprKeySpecifier)
        fields?: GitRevSpecExprFieldPolicy
    }
    GitTree?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitTreeKeySpecifier | (() => undefined | GitTreeKeySpecifier)
        fields?: GitTreeFieldPolicy
    }
    GitTreeCodeIntelInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitTreeCodeIntelInfoKeySpecifier | (() => undefined | GitTreeCodeIntelInfoKeySpecifier)
        fields?: GitTreeCodeIntelInfoFieldPolicy
    }
    GitTreeLSIFData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitTreeLSIFDataKeySpecifier | (() => undefined | GitTreeLSIFDataKeySpecifier)
        fields?: GitTreeLSIFDataFieldPolicy
    }
    GitTreePreciseCoverage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitTreePreciseCoverageKeySpecifier | (() => undefined | GitTreePreciseCoverageKeySpecifier)
        fields?: GitTreePreciseCoverageFieldPolicy
    }
    GitTreeSearchBasedCoverage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | GitTreeSearchBasedCoverageKeySpecifier
            | (() => undefined | GitTreeSearchBasedCoverageKeySpecifier)
        fields?: GitTreeSearchBasedCoverageFieldPolicy
    }
    GitUpdatesDisabled?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitUpdatesDisabledKeySpecifier | (() => undefined | GitUpdatesDisabledKeySpecifier)
        fields?: GitUpdatesDisabledFieldPolicy
    }
    GlobalChangesetsStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GlobalChangesetsStatsKeySpecifier | (() => undefined | GlobalChangesetsStatsKeySpecifier)
        fields?: GlobalChangesetsStatsFieldPolicy
    }
    HiddenApplyPreviewTargetsAttach?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenApplyPreviewTargetsAttachKeySpecifier
            | (() => undefined | HiddenApplyPreviewTargetsAttachKeySpecifier)
        fields?: HiddenApplyPreviewTargetsAttachFieldPolicy
    }
    HiddenApplyPreviewTargetsDetach?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenApplyPreviewTargetsDetachKeySpecifier
            | (() => undefined | HiddenApplyPreviewTargetsDetachKeySpecifier)
        fields?: HiddenApplyPreviewTargetsDetachFieldPolicy
    }
    HiddenApplyPreviewTargetsUpdate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenApplyPreviewTargetsUpdateKeySpecifier
            | (() => undefined | HiddenApplyPreviewTargetsUpdateKeySpecifier)
        fields?: HiddenApplyPreviewTargetsUpdateFieldPolicy
    }
    HiddenBatchSpecWorkspace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenBatchSpecWorkspaceKeySpecifier
            | (() => undefined | HiddenBatchSpecWorkspaceKeySpecifier)
        fields?: HiddenBatchSpecWorkspaceFieldPolicy
    }
    HiddenChangesetApplyPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenChangesetApplyPreviewKeySpecifier
            | (() => undefined | HiddenChangesetApplyPreviewKeySpecifier)
        fields?: HiddenChangesetApplyPreviewFieldPolicy
    }
    HiddenChangesetSpec?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HiddenChangesetSpecKeySpecifier | (() => undefined | HiddenChangesetSpecKeySpecifier)
        fields?: HiddenChangesetSpecFieldPolicy
    }
    HiddenExternalChangeset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenExternalChangesetKeySpecifier
            | (() => undefined | HiddenExternalChangesetKeySpecifier)
        fields?: HiddenExternalChangesetFieldPolicy
    }
    Highlight?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HighlightKeySpecifier | (() => undefined | HighlightKeySpecifier)
        fields?: HighlightFieldPolicy
    }
    HighlightedDiffHunkBody?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HighlightedDiffHunkBodyKeySpecifier
            | (() => undefined | HighlightedDiffHunkBodyKeySpecifier)
        fields?: HighlightedDiffHunkBodyFieldPolicy
    }
    HighlightedDiffHunkLine?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HighlightedDiffHunkLineKeySpecifier
            | (() => undefined | HighlightedDiffHunkLineKeySpecifier)
        fields?: HighlightedDiffHunkLineFieldPolicy
    }
    HighlightedFile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HighlightedFileKeySpecifier | (() => undefined | HighlightedFileKeySpecifier)
        fields?: HighlightedFileFieldPolicy
    }
    HighlightedString?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HighlightedStringKeySpecifier | (() => undefined | HighlightedStringKeySpecifier)
        fields?: HighlightedStringFieldPolicy
    }
    Hover?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HoverKeySpecifier | (() => undefined | HoverKeySpecifier)
        fields?: HoverFieldPolicy
    }
    HTTPHeader?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HTTPHeaderKeySpecifier | (() => undefined | HTTPHeaderKeySpecifier)
        fields?: HTTPHeaderFieldPolicy
    }
    Hunk?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HunkKeySpecifier | (() => undefined | HunkKeySpecifier)
        fields?: HunkFieldPolicy
    }
    IncompleteDatapointAlert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | IncompleteDatapointAlertKeySpecifier
            | (() => undefined | IncompleteDatapointAlertKeySpecifier)
        fields?: IncompleteDatapointAlertFieldPolicy
    }
    IndexConfiguration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | IndexConfigurationKeySpecifier | (() => undefined | IndexConfigurationKeySpecifier)
        fields?: IndexConfigurationFieldPolicy
    }
    IndexingProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | IndexingProgressKeySpecifier | (() => undefined | IndexingProgressKeySpecifier)
        fields?: IndexingProgressFieldPolicy
    }
    IndexStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | IndexStepKeySpecifier | (() => undefined | IndexStepKeySpecifier)
        fields?: IndexStepFieldPolicy
    }
    IndexSteps?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | IndexStepsKeySpecifier | (() => undefined | IndexStepsKeySpecifier)
        fields?: IndexStepsFieldPolicy
    }
    InferredAvailableIndexers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InferredAvailableIndexersKeySpecifier
            | (() => undefined | InferredAvailableIndexersKeySpecifier)
        fields?: InferredAvailableIndexersFieldPolicy
    }
    Insight?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightKeySpecifier | (() => undefined | InsightKeySpecifier)
        fields?: InsightFieldPolicy
    }
    InsightBackfillQueueItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightBackfillQueueItemKeySpecifier
            | (() => undefined | InsightBackfillQueueItemKeySpecifier)
        fields?: InsightBackfillQueueItemFieldPolicy
    }
    InsightBackfillQueueItemConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightBackfillQueueItemConnectionKeySpecifier
            | (() => undefined | InsightBackfillQueueItemConnectionKeySpecifier)
        fields?: InsightBackfillQueueItemConnectionFieldPolicy
    }
    InsightDataPoint?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightDataPointKeySpecifier | (() => undefined | InsightDataPointKeySpecifier)
        fields?: InsightDataPointFieldPolicy
    }
    InsightIntervalTimeScope?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightIntervalTimeScopeKeySpecifier
            | (() => undefined | InsightIntervalTimeScopeKeySpecifier)
        fields?: InsightIntervalTimeScopeFieldPolicy
    }
    InsightRepositoryScope?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightRepositoryScopeKeySpecifier | (() => undefined | InsightRepositoryScopeKeySpecifier)
        fields?: InsightRepositoryScopeFieldPolicy
    }
    InsightsDashboard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightsDashboardKeySpecifier | (() => undefined | InsightsDashboardKeySpecifier)
        fields?: InsightsDashboardFieldPolicy
    }
    InsightsDashboardConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightsDashboardConnectionKeySpecifier
            | (() => undefined | InsightsDashboardConnectionKeySpecifier)
        fields?: InsightsDashboardConnectionFieldPolicy
    }
    InsightsDashboardPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightsDashboardPayloadKeySpecifier
            | (() => undefined | InsightsDashboardPayloadKeySpecifier)
        fields?: InsightsDashboardPayloadFieldPolicy
    }
    InsightSeriesMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightSeriesMetadataKeySpecifier | (() => undefined | InsightSeriesMetadataKeySpecifier)
        fields?: InsightSeriesMetadataFieldPolicy
    }
    InsightSeriesMetadataPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightSeriesMetadataPayloadKeySpecifier
            | (() => undefined | InsightSeriesMetadataPayloadKeySpecifier)
        fields?: InsightSeriesMetadataPayloadFieldPolicy
    }
    InsightSeriesQueryStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightSeriesQueryStatusKeySpecifier
            | (() => undefined | InsightSeriesQueryStatusKeySpecifier)
        fields?: InsightSeriesQueryStatusFieldPolicy
    }
    InsightSeriesStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightSeriesStatusKeySpecifier | (() => undefined | InsightSeriesStatusKeySpecifier)
        fields?: InsightSeriesStatusFieldPolicy
    }
    InsightsPermissionGrants?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightsPermissionGrantsKeySpecifier
            | (() => undefined | InsightsPermissionGrantsKeySpecifier)
        fields?: InsightsPermissionGrantsFieldPolicy
    }
    InsightsSeries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightsSeriesKeySpecifier | (() => undefined | InsightsSeriesKeySpecifier)
        fields?: InsightsSeriesFieldPolicy
    }
    InsightView?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewKeySpecifier | (() => undefined | InsightViewKeySpecifier)
        fields?: InsightViewFieldPolicy
    }
    InsightViewConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewConnectionKeySpecifier | (() => undefined | InsightViewConnectionKeySpecifier)
        fields?: InsightViewConnectionFieldPolicy
    }
    InsightViewDebug?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewDebugKeySpecifier | (() => undefined | InsightViewDebugKeySpecifier)
        fields?: InsightViewDebugFieldPolicy
    }
    InsightViewFilters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewFiltersKeySpecifier | (() => undefined | InsightViewFiltersKeySpecifier)
        fields?: InsightViewFiltersFieldPolicy
    }
    InsightViewPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewPayloadKeySpecifier | (() => undefined | InsightViewPayloadKeySpecifier)
        fields?: InsightViewPayloadFieldPolicy
    }
    InviteUserToOrganizationResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InviteUserToOrganizationResultKeySpecifier
            | (() => undefined | InviteUserToOrganizationResultKeySpecifier)
        fields?: InviteUserToOrganizationResultFieldPolicy
    }
    KeyValuePair?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | KeyValuePairKeySpecifier | (() => undefined | KeyValuePairKeySpecifier)
        fields?: KeyValuePairFieldPolicy
    }
    Language?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LanguageKeySpecifier | (() => undefined | LanguageKeySpecifier)
        fields?: LanguageFieldPolicy
    }
    LanguageStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LanguageStatisticsKeySpecifier | (() => undefined | LanguageStatisticsKeySpecifier)
        fields?: LanguageStatisticsFieldPolicy
    }
    LFS?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LFSKeySpecifier | (() => undefined | LFSKeySpecifier)
        fields?: LFSFieldPolicy
    }
    LineChartDataSeriesPresentation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LineChartDataSeriesPresentationKeySpecifier
            | (() => undefined | LineChartDataSeriesPresentationKeySpecifier)
        fields?: LineChartDataSeriesPresentationFieldPolicy
    }
    LineChartInsightViewPresentation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LineChartInsightViewPresentationKeySpecifier
            | (() => undefined | LineChartInsightViewPresentationKeySpecifier)
        fields?: LineChartInsightViewPresentationFieldPolicy
    }
    LineMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LineMatchKeySpecifier | (() => undefined | LineMatchKeySpecifier)
        fields?: LineMatchFieldPolicy
    }
    LineRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LineRangeKeySpecifier | (() => undefined | LineRangeKeySpecifier)
        fields?: LineRangeFieldPolicy
    }
    Location?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LocationKeySpecifier | (() => undefined | LocationKeySpecifier)
        fields?: LocationFieldPolicy
    }
    LocationConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LocationConnectionKeySpecifier | (() => undefined | LocationConnectionKeySpecifier)
        fields?: LocationConnectionFieldPolicy
    }
    LSIFIndex?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFIndexKeySpecifier | (() => undefined | LSIFIndexKeySpecifier)
        fields?: LSIFIndexFieldPolicy
    }
    LSIFIndexConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFIndexConnectionKeySpecifier | (() => undefined | LSIFIndexConnectionKeySpecifier)
        fields?: LSIFIndexConnectionFieldPolicy
    }
    LSIFIndexesWithRepositoryNamespace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LSIFIndexesWithRepositoryNamespaceKeySpecifier
            | (() => undefined | LSIFIndexesWithRepositoryNamespaceKeySpecifier)
        fields?: LSIFIndexesWithRepositoryNamespaceFieldPolicy
    }
    LSIFUpload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFUploadKeySpecifier | (() => undefined | LSIFUploadKeySpecifier)
        fields?: LSIFUploadFieldPolicy
    }
    LSIFUploadAuditLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFUploadAuditLogKeySpecifier | (() => undefined | LSIFUploadAuditLogKeySpecifier)
        fields?: LSIFUploadAuditLogFieldPolicy
    }
    LSIFUploadConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFUploadConnectionKeySpecifier | (() => undefined | LSIFUploadConnectionKeySpecifier)
        fields?: LSIFUploadConnectionFieldPolicy
    }
    LSIFUploadDocumentPathsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LSIFUploadDocumentPathsConnectionKeySpecifier
            | (() => undefined | LSIFUploadDocumentPathsConnectionKeySpecifier)
        fields?: LSIFUploadDocumentPathsConnectionFieldPolicy
    }
    LSIFUploadsWithRepositoryNamespace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LSIFUploadsWithRepositoryNamespaceKeySpecifier
            | (() => undefined | LSIFUploadsWithRepositoryNamespaceKeySpecifier)
        fields?: LSIFUploadsWithRepositoryNamespaceFieldPolicy
    }
    Markdown?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MarkdownKeySpecifier | (() => undefined | MarkdownKeySpecifier)
        fields?: MarkdownFieldPolicy
    }
    MarkdownBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MarkdownBlockKeySpecifier | (() => undefined | MarkdownBlockKeySpecifier)
        fields?: MarkdownBlockFieldPolicy
    }
    MirrorRepositoryInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MirrorRepositoryInfoKeySpecifier | (() => undefined | MirrorRepositoryInfoKeySpecifier)
        fields?: MirrorRepositoryInfoFieldPolicy
    }
    Monitor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorKeySpecifier | (() => undefined | MonitorKeySpecifier)
        fields?: MonitorFieldPolicy
    }
    MonitorActionConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MonitorActionConnectionKeySpecifier
            | (() => undefined | MonitorActionConnectionKeySpecifier)
        fields?: MonitorActionConnectionFieldPolicy
    }
    MonitorActionEmailRecipientsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MonitorActionEmailRecipientsConnectionKeySpecifier
            | (() => undefined | MonitorActionEmailRecipientsConnectionKeySpecifier)
        fields?: MonitorActionEmailRecipientsConnectionFieldPolicy
    }
    MonitorActionEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorActionEventKeySpecifier | (() => undefined | MonitorActionEventKeySpecifier)
        fields?: MonitorActionEventFieldPolicy
    }
    MonitorActionEventConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MonitorActionEventConnectionKeySpecifier
            | (() => undefined | MonitorActionEventConnectionKeySpecifier)
        fields?: MonitorActionEventConnectionFieldPolicy
    }
    MonitorConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorConnectionKeySpecifier | (() => undefined | MonitorConnectionKeySpecifier)
        fields?: MonitorConnectionFieldPolicy
    }
    MonitorEmail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorEmailKeySpecifier | (() => undefined | MonitorEmailKeySpecifier)
        fields?: MonitorEmailFieldPolicy
    }
    MonitoringAlert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitoringAlertKeySpecifier | (() => undefined | MonitoringAlertKeySpecifier)
        fields?: MonitoringAlertFieldPolicy
    }
    MonitoringStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitoringStatisticsKeySpecifier | (() => undefined | MonitoringStatisticsKeySpecifier)
        fields?: MonitoringStatisticsFieldPolicy
    }
    MonitorQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorQueryKeySpecifier | (() => undefined | MonitorQueryKeySpecifier)
        fields?: MonitorQueryFieldPolicy
    }
    MonitorSlackWebhook?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorSlackWebhookKeySpecifier | (() => undefined | MonitorSlackWebhookKeySpecifier)
        fields?: MonitorSlackWebhookFieldPolicy
    }
    MonitorTriggerEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorTriggerEventKeySpecifier | (() => undefined | MonitorTriggerEventKeySpecifier)
        fields?: MonitorTriggerEventFieldPolicy
    }
    MonitorTriggerEventConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MonitorTriggerEventConnectionKeySpecifier
            | (() => undefined | MonitorTriggerEventConnectionKeySpecifier)
        fields?: MonitorTriggerEventConnectionFieldPolicy
    }
    MonitorWebhook?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorWebhookKeySpecifier | (() => undefined | MonitorWebhookKeySpecifier)
        fields?: MonitorWebhookFieldPolicy
    }
    Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier)
        fields?: MutationFieldPolicy
    }
    Namespace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NamespaceKeySpecifier | (() => undefined | NamespaceKeySpecifier)
        fields?: NamespaceFieldPolicy
    }
    NewRepositoryConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | NewRepositoryConnectionKeySpecifier
            | (() => undefined | NewRepositoryConnectionKeySpecifier)
        fields?: NewRepositoryConnectionFieldPolicy
    }
    NewUsersConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NewUsersConnectionKeySpecifier | (() => undefined | NewUsersConnectionKeySpecifier)
        fields?: NewUsersConnectionFieldPolicy
    }
    Node?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NodeKeySpecifier | (() => undefined | NodeKeySpecifier)
        fields?: NodeFieldPolicy
    }
    NonExhaustiveSearchAggregationResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | NonExhaustiveSearchAggregationResultKeySpecifier
            | (() => undefined | NonExhaustiveSearchAggregationResultKeySpecifier)
        fields?: NonExhaustiveSearchAggregationResultFieldPolicy
    }
    Notebook?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotebookKeySpecifier | (() => undefined | NotebookKeySpecifier)
        fields?: NotebookFieldPolicy
    }
    NotebookConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotebookConnectionKeySpecifier | (() => undefined | NotebookConnectionKeySpecifier)
        fields?: NotebookConnectionFieldPolicy
    }
    NotebookStar?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotebookStarKeySpecifier | (() => undefined | NotebookStarKeySpecifier)
        fields?: NotebookStarFieldPolicy
    }
    NotebookStarConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotebookStarConnectionKeySpecifier | (() => undefined | NotebookStarConnectionKeySpecifier)
        fields?: NotebookStarConnectionFieldPolicy
    }
    Org?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrgKeySpecifier | (() => undefined | OrgKeySpecifier)
        fields?: OrgFieldPolicy
    }
    OrganizationInvitation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrganizationInvitationKeySpecifier | (() => undefined | OrganizationInvitationKeySpecifier)
        fields?: OrganizationInvitationFieldPolicy
    }
    OrganizationMembership?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrganizationMembershipKeySpecifier | (() => undefined | OrganizationMembershipKeySpecifier)
        fields?: OrganizationMembershipFieldPolicy
    }
    OrganizationMembershipConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OrganizationMembershipConnectionKeySpecifier
            | (() => undefined | OrganizationMembershipConnectionKeySpecifier)
        fields?: OrganizationMembershipConnectionFieldPolicy
    }
    OrgConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrgConnectionKeySpecifier | (() => undefined | OrgConnectionKeySpecifier)
        fields?: OrgConnectionFieldPolicy
    }
    OrgMembersSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrgMembersSummaryKeySpecifier | (() => undefined | OrgMembersSummaryKeySpecifier)
        fields?: OrgMembersSummaryFieldPolicy
    }
    OutboundRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OutboundRequestKeySpecifier | (() => undefined | OutboundRequestKeySpecifier)
        fields?: OutboundRequestFieldPolicy
    }
    OutboundRequestConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OutboundRequestConnectionKeySpecifier
            | (() => undefined | OutboundRequestConnectionKeySpecifier)
        fields?: OutboundRequestConnectionFieldPolicy
    }
    OutboundWebhook?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OutboundWebhookKeySpecifier | (() => undefined | OutboundWebhookKeySpecifier)
        fields?: OutboundWebhookFieldPolicy
    }
    OutboundWebhookConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OutboundWebhookConnectionKeySpecifier
            | (() => undefined | OutboundWebhookConnectionKeySpecifier)
        fields?: OutboundWebhookConnectionFieldPolicy
    }
    OutboundWebhookEventType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OutboundWebhookEventTypeKeySpecifier
            | (() => undefined | OutboundWebhookEventTypeKeySpecifier)
        fields?: OutboundWebhookEventTypeFieldPolicy
    }
    OutboundWebhookJob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OutboundWebhookJobKeySpecifier | (() => undefined | OutboundWebhookJobKeySpecifier)
        fields?: OutboundWebhookJobFieldPolicy
    }
    OutboundWebhookLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OutboundWebhookLogKeySpecifier | (() => undefined | OutboundWebhookLogKeySpecifier)
        fields?: OutboundWebhookLogFieldPolicy
    }
    OutboundWebhookLogConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OutboundWebhookLogConnectionKeySpecifier
            | (() => undefined | OutboundWebhookLogConnectionKeySpecifier)
        fields?: OutboundWebhookLogConnectionFieldPolicy
    }
    OutboundWebhookLogStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OutboundWebhookLogStatsKeySpecifier
            | (() => undefined | OutboundWebhookLogStatsKeySpecifier)
        fields?: OutboundWebhookLogStatsFieldPolicy
    }
    OutboundWebhookScopedEventType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OutboundWebhookScopedEventTypeKeySpecifier
            | (() => undefined | OutboundWebhookScopedEventTypeKeySpecifier)
        fields?: OutboundWebhookScopedEventTypeFieldPolicy
    }
    OutOfBandMigration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OutOfBandMigrationKeySpecifier | (() => undefined | OutOfBandMigrationKeySpecifier)
        fields?: OutOfBandMigrationFieldPolicy
    }
    OutOfBandMigrationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OutOfBandMigrationErrorKeySpecifier
            | (() => undefined | OutOfBandMigrationErrorKeySpecifier)
        fields?: OutOfBandMigrationErrorFieldPolicy
    }
    Ownable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OwnableKeySpecifier | (() => undefined | OwnableKeySpecifier)
        fields?: OwnableFieldPolicy
    }
    Ownership?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OwnershipKeySpecifier | (() => undefined | OwnershipKeySpecifier)
        fields?: OwnershipFieldPolicy
    }
    OwnershipConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OwnershipConnectionKeySpecifier | (() => undefined | OwnershipConnectionKeySpecifier)
        fields?: OwnershipConnectionFieldPolicy
    }
    PackageRepoReference?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PackageRepoReferenceKeySpecifier | (() => undefined | PackageRepoReferenceKeySpecifier)
        fields?: PackageRepoReferenceFieldPolicy
    }
    PackageRepoReferenceConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PackageRepoReferenceConnectionKeySpecifier
            | (() => undefined | PackageRepoReferenceConnectionKeySpecifier)
        fields?: PackageRepoReferenceConnectionFieldPolicy
    }
    PackageRepoReferenceVersion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PackageRepoReferenceVersionKeySpecifier
            | (() => undefined | PackageRepoReferenceVersionKeySpecifier)
        fields?: PackageRepoReferenceVersionFieldPolicy
    }
    PageInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier)
        fields?: PageInfoFieldPolicy
    }
    ParentSourcegraphDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ParentSourcegraphDetailsKeySpecifier
            | (() => undefined | ParentSourcegraphDetailsKeySpecifier)
        fields?: ParentSourcegraphDetailsFieldPolicy
    }
    Permission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PermissionKeySpecifier | (() => undefined | PermissionKeySpecifier)
        fields?: PermissionFieldPolicy
    }
    PermissionConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PermissionConnectionKeySpecifier | (() => undefined | PermissionConnectionKeySpecifier)
        fields?: PermissionConnectionFieldPolicy
    }
    PermissionsInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PermissionsInfoKeySpecifier | (() => undefined | PermissionsInfoKeySpecifier)
        fields?: PermissionsInfoFieldPolicy
    }
    PermissionsProviderState?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PermissionsProviderStateKeySpecifier
            | (() => undefined | PermissionsProviderStateKeySpecifier)
        fields?: PermissionsProviderStateFieldPolicy
    }
    PermissionsSyncJob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PermissionsSyncJobKeySpecifier | (() => undefined | PermissionsSyncJobKeySpecifier)
        fields?: PermissionsSyncJobFieldPolicy
    }
    PermissionsSyncJobsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PermissionsSyncJobsConnectionKeySpecifier
            | (() => undefined | PermissionsSyncJobsConnectionKeySpecifier)
        fields?: PermissionsSyncJobsConnectionFieldPolicy
    }
    Person?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PersonKeySpecifier | (() => undefined | PersonKeySpecifier)
        fields?: PersonFieldPolicy
    }
    PhabricatorRepo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PhabricatorRepoKeySpecifier | (() => undefined | PhabricatorRepoKeySpecifier)
        fields?: PhabricatorRepoFieldPolicy
    }
    PieChartInsightViewPresentation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PieChartInsightViewPresentationKeySpecifier
            | (() => undefined | PieChartInsightViewPresentationKeySpecifier)
        fields?: PieChartInsightViewPresentationFieldPolicy
    }
    Position?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PositionKeySpecifier | (() => undefined | PositionKeySpecifier)
        fields?: PositionFieldPolicy
    }
    PreciseCodeIntelSupport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PreciseCodeIntelSupportKeySpecifier
            | (() => undefined | PreciseCodeIntelSupportKeySpecifier)
        fields?: PreciseCodeIntelSupportFieldPolicy
    }
    PreciseIndex?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PreciseIndexKeySpecifier | (() => undefined | PreciseIndexKeySpecifier)
        fields?: PreciseIndexFieldPolicy
    }
    PreciseIndexConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PreciseIndexConnectionKeySpecifier | (() => undefined | PreciseIndexConnectionKeySpecifier)
        fields?: PreciseIndexConnectionFieldPolicy
    }
    PreIndexStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PreIndexStepKeySpecifier | (() => undefined | PreIndexStepKeySpecifier)
        fields?: PreIndexStepFieldPolicy
    }
    PreviewRepositoryComparison?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PreviewRepositoryComparisonKeySpecifier
            | (() => undefined | PreviewRepositoryComparisonKeySpecifier)
        fields?: PreviewRepositoryComparisonFieldPolicy
    }
    ProductLicense?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ProductLicenseKeySpecifier | (() => undefined | ProductLicenseKeySpecifier)
        fields?: ProductLicenseFieldPolicy
    }
    ProductLicenseConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductLicenseConnectionKeySpecifier
            | (() => undefined | ProductLicenseConnectionKeySpecifier)
        fields?: ProductLicenseConnectionFieldPolicy
    }
    ProductLicenseInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ProductLicenseInfoKeySpecifier | (() => undefined | ProductLicenseInfoKeySpecifier)
        fields?: ProductLicenseInfoFieldPolicy
    }
    ProductSubscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ProductSubscriptionKeySpecifier | (() => undefined | ProductSubscriptionKeySpecifier)
        fields?: ProductSubscriptionFieldPolicy
    }
    ProductSubscriptionConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductSubscriptionConnectionKeySpecifier
            | (() => undefined | ProductSubscriptionConnectionKeySpecifier)
        fields?: ProductSubscriptionConnectionFieldPolicy
    }
    ProductSubscriptionStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductSubscriptionStatusKeySpecifier
            | (() => undefined | ProductSubscriptionStatusKeySpecifier)
        fields?: ProductSubscriptionStatusFieldPolicy
    }
    PublicExternalAccountData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PublicExternalAccountDataKeySpecifier
            | (() => undefined | PublicExternalAccountDataKeySpecifier)
        fields?: PublicExternalAccountDataFieldPolicy
    }
    Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier)
        fields?: QueryFieldPolicy
    }
    QueryBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | QueryBlockKeySpecifier | (() => undefined | QueryBlockKeySpecifier)
        fields?: QueryBlockFieldPolicy
    }
    RandomizeUserPasswordResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RandomizeUserPasswordResultKeySpecifier
            | (() => undefined | RandomizeUserPasswordResultKeySpecifier)
        fields?: RandomizeUserPasswordResultFieldPolicy
    }
    Range?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RangeKeySpecifier | (() => undefined | RangeKeySpecifier)
        fields?: RangeFieldPolicy
    }
    Redirect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RedirectKeySpecifier | (() => undefined | RedirectKeySpecifier)
        fields?: RedirectFieldPolicy
    }
    RegistryExtension?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RegistryExtensionKeySpecifier | (() => undefined | RegistryExtensionKeySpecifier)
        fields?: RegistryExtensionFieldPolicy
    }
    RegistryExtensionConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RegistryExtensionConnectionKeySpecifier
            | (() => undefined | RegistryExtensionConnectionKeySpecifier)
        fields?: RegistryExtensionConnectionFieldPolicy
    }
    RepoChangesetsStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepoChangesetsStatsKeySpecifier | (() => undefined | RepoChangesetsStatsKeySpecifier)
        fields?: RepoChangesetsStatsFieldPolicy
    }
    RepoCorruptionLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepoCorruptionLogKeySpecifier | (() => undefined | RepoCorruptionLogKeySpecifier)
        fields?: RepoCorruptionLogFieldPolicy
    }
    Repository?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryKeySpecifier | (() => undefined | RepositoryKeySpecifier)
        fields?: RepositoryFieldPolicy
    }
    RepositoryComparison?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryComparisonKeySpecifier | (() => undefined | RepositoryComparisonKeySpecifier)
        fields?: RepositoryComparisonFieldPolicy
    }
    RepositoryConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryConnectionKeySpecifier | (() => undefined | RepositoryConnectionKeySpecifier)
        fields?: RepositoryConnectionFieldPolicy
    }
    RepositoryContributor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryContributorKeySpecifier | (() => undefined | RepositoryContributorKeySpecifier)
        fields?: RepositoryContributorFieldPolicy
    }
    RepositoryContributorConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryContributorConnectionKeySpecifier
            | (() => undefined | RepositoryContributorConnectionKeySpecifier)
        fields?: RepositoryContributorConnectionFieldPolicy
    }
    RepositoryFilterPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryFilterPreviewKeySpecifier
            | (() => undefined | RepositoryFilterPreviewKeySpecifier)
        fields?: RepositoryFilterPreviewFieldPolicy
    }
    repositoryIndexserverHost?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | repositoryIndexserverHostKeySpecifier
            | (() => undefined | repositoryIndexserverHostKeySpecifier)
        fields?: repositoryIndexserverHostFieldPolicy
    }
    RepositoryPreviewPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryPreviewPayloadKeySpecifier
            | (() => undefined | RepositoryPreviewPayloadKeySpecifier)
        fields?: RepositoryPreviewPayloadFieldPolicy
    }
    RepositorySearchScope?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositorySearchScopeKeySpecifier | (() => undefined | RepositorySearchScopeKeySpecifier)
        fields?: RepositorySearchScopeFieldPolicy
    }
    RepositoryStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryStatsKeySpecifier | (() => undefined | RepositoryStatsKeySpecifier)
        fields?: RepositoryStatsFieldPolicy
    }
    RepositoryTextSearchIndex?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryTextSearchIndexKeySpecifier
            | (() => undefined | RepositoryTextSearchIndexKeySpecifier)
        fields?: RepositoryTextSearchIndexFieldPolicy
    }
    RepositoryTextSearchIndexedRef?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryTextSearchIndexedRefKeySpecifier
            | (() => undefined | RepositoryTextSearchIndexedRefKeySpecifier)
        fields?: RepositoryTextSearchIndexedRefFieldPolicy
    }
    RepositoryTextSearchIndexStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryTextSearchIndexStatusKeySpecifier
            | (() => undefined | RepositoryTextSearchIndexStatusKeySpecifier)
        fields?: RepositoryTextSearchIndexStatusFieldPolicy
    }
    ResolvedBatchSpecWorkspace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ResolvedBatchSpecWorkspaceKeySpecifier
            | (() => undefined | ResolvedBatchSpecWorkspaceKeySpecifier)
        fields?: ResolvedBatchSpecWorkspaceFieldPolicy
    }
    Role?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RoleKeySpecifier | (() => undefined | RoleKeySpecifier)
        fields?: RoleFieldPolicy
    }
    RoleConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RoleConnectionKeySpecifier | (() => undefined | RoleConnectionKeySpecifier)
        fields?: RoleConnectionFieldPolicy
    }
    SavedSearch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SavedSearchKeySpecifier | (() => undefined | SavedSearchKeySpecifier)
        fields?: SavedSearchFieldPolicy
    }
    SavedSearchesConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SavedSearchesConnectionKeySpecifier
            | (() => undefined | SavedSearchesConnectionKeySpecifier)
        fields?: SavedSearchesConnectionFieldPolicy
    }
    ScopedInsightQueryPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ScopedInsightQueryPayloadKeySpecifier
            | (() => undefined | ScopedInsightQueryPayloadKeySpecifier)
        fields?: ScopedInsightQueryPayloadFieldPolicy
    }
    Search?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchKeySpecifier | (() => undefined | SearchKeySpecifier)
        fields?: SearchFieldPolicy
    }
    SearchAggregationNotAvailable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchAggregationNotAvailableKeySpecifier
            | (() => undefined | SearchAggregationNotAvailableKeySpecifier)
        fields?: SearchAggregationNotAvailableFieldPolicy
    }
    SearchAlert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchAlertKeySpecifier | (() => undefined | SearchAlertKeySpecifier)
        fields?: SearchAlertFieldPolicy
    }
    SearchBasedCodeIntelSupport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchBasedCodeIntelSupportKeySpecifier
            | (() => undefined | SearchBasedCodeIntelSupportKeySpecifier)
        fields?: SearchBasedCodeIntelSupportFieldPolicy
    }
    SearchContext?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchContextKeySpecifier | (() => undefined | SearchContextKeySpecifier)
        fields?: SearchContextFieldPolicy
    }
    SearchContextConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchContextConnectionKeySpecifier
            | (() => undefined | SearchContextConnectionKeySpecifier)
        fields?: SearchContextConnectionFieldPolicy
    }
    SearchContextRepositoryRevisions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchContextRepositoryRevisionsKeySpecifier
            | (() => undefined | SearchContextRepositoryRevisionsKeySpecifier)
        fields?: SearchContextRepositoryRevisionsFieldPolicy
    }
    SearchFilter?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchFilterKeySpecifier | (() => undefined | SearchFilterKeySpecifier)
        fields?: SearchFilterFieldPolicy
    }
    SearchInsightDataSeriesDefinition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchInsightDataSeriesDefinitionKeySpecifier
            | (() => undefined | SearchInsightDataSeriesDefinitionKeySpecifier)
        fields?: SearchInsightDataSeriesDefinitionFieldPolicy
    }
    SearchInsightLivePreviewSeries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchInsightLivePreviewSeriesKeySpecifier
            | (() => undefined | SearchInsightLivePreviewSeriesKeySpecifier)
        fields?: SearchInsightLivePreviewSeriesFieldPolicy
    }
    SearchQueryAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchQueryAggregateKeySpecifier | (() => undefined | SearchQueryAggregateKeySpecifier)
        fields?: SearchQueryAggregateFieldPolicy
    }
    SearchQueryAnnotation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchQueryAnnotationKeySpecifier | (() => undefined | SearchQueryAnnotationKeySpecifier)
        fields?: SearchQueryAnnotationFieldPolicy
    }
    SearchQueryDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchQueryDescriptionKeySpecifier | (() => undefined | SearchQueryDescriptionKeySpecifier)
        fields?: SearchQueryDescriptionFieldPolicy
    }
    SearchResultMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchResultMatchKeySpecifier | (() => undefined | SearchResultMatchKeySpecifier)
        fields?: SearchResultMatchFieldPolicy
    }
    SearchResults?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchResultsKeySpecifier | (() => undefined | SearchResultsKeySpecifier)
        fields?: SearchResultsFieldPolicy
    }
    SearchResultsStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchResultsStatsKeySpecifier | (() => undefined | SearchResultsStatsKeySpecifier)
        fields?: SearchResultsStatsFieldPolicy
    }
    SeriesDisplayOptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SeriesDisplayOptionsKeySpecifier | (() => undefined | SeriesDisplayOptionsKeySpecifier)
        fields?: SeriesDisplayOptionsFieldPolicy
    }
    SeriesSortOptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SeriesSortOptionsKeySpecifier | (() => undefined | SeriesSortOptionsKeySpecifier)
        fields?: SeriesSortOptionsFieldPolicy
    }
    Session?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SessionKeySpecifier | (() => undefined | SessionKeySpecifier)
        fields?: SessionFieldPolicy
    }
    Settings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SettingsKeySpecifier | (() => undefined | SettingsKeySpecifier)
        fields?: SettingsFieldPolicy
    }
    SettingsCascade?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SettingsCascadeKeySpecifier | (() => undefined | SettingsCascadeKeySpecifier)
        fields?: SettingsCascadeFieldPolicy
    }
    SettingsMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SettingsMutationKeySpecifier | (() => undefined | SettingsMutationKeySpecifier)
        fields?: SettingsMutationFieldPolicy
    }
    SettingsSubject?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SettingsSubjectKeySpecifier | (() => undefined | SettingsSubjectKeySpecifier)
        fields?: SettingsSubjectFieldPolicy
    }
    Signature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SignatureKeySpecifier | (() => undefined | SignatureKeySpecifier)
        fields?: SignatureFieldPolicy
    }
    Site?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteKeySpecifier | (() => undefined | SiteKeySpecifier)
        fields?: SiteFieldPolicy
    }
    SiteConfiguration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteConfigurationKeySpecifier | (() => undefined | SiteConfigurationKeySpecifier)
        fields?: SiteConfigurationFieldPolicy
    }
    SiteConfigurationChange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SiteConfigurationChangeKeySpecifier
            | (() => undefined | SiteConfigurationChangeKeySpecifier)
        fields?: SiteConfigurationChangeFieldPolicy
    }
    SiteConfigurationChangeConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SiteConfigurationChangeConnectionKeySpecifier
            | (() => undefined | SiteConfigurationChangeConnectionKeySpecifier)
        fields?: SiteConfigurationChangeConnectionFieldPolicy
    }
    SiteUsagePeriod?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteUsagePeriodKeySpecifier | (() => undefined | SiteUsagePeriodKeySpecifier)
        fields?: SiteUsagePeriodFieldPolicy
    }
    SiteUsageStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteUsageStatisticsKeySpecifier | (() => undefined | SiteUsageStatisticsKeySpecifier)
        fields?: SiteUsageStatisticsFieldPolicy
    }
    SiteUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteUserKeySpecifier | (() => undefined | SiteUserKeySpecifier)
        fields?: SiteUserFieldPolicy
    }
    SiteUsers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteUsersKeySpecifier | (() => undefined | SiteUsersKeySpecifier)
        fields?: SiteUsersFieldPolicy
    }
    SkippedIndexed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SkippedIndexedKeySpecifier | (() => undefined | SkippedIndexedKeySpecifier)
        fields?: SkippedIndexedFieldPolicy
    }
    SlowRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SlowRequestKeySpecifier | (() => undefined | SlowRequestKeySpecifier)
        fields?: SlowRequestFieldPolicy
    }
    SlowRequestConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SlowRequestConnectionKeySpecifier | (() => undefined | SlowRequestConnectionKeySpecifier)
        fields?: SlowRequestConnectionFieldPolicy
    }
    Submodule?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SubmoduleKeySpecifier | (() => undefined | SubmoduleKeySpecifier)
        fields?: SubmoduleFieldPolicy
    }
    SurveyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SurveyResponseKeySpecifier | (() => undefined | SurveyResponseKeySpecifier)
        fields?: SurveyResponseFieldPolicy
    }
    SurveyResponseConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SurveyResponseConnectionKeySpecifier
            | (() => undefined | SurveyResponseConnectionKeySpecifier)
        fields?: SurveyResponseConnectionFieldPolicy
    }
    Symbol?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolKeySpecifier | (() => undefined | SymbolKeySpecifier)
        fields?: SymbolFieldPolicy
    }
    SymbolBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolBlockKeySpecifier | (() => undefined | SymbolBlockKeySpecifier)
        fields?: SymbolBlockFieldPolicy
    }
    SymbolBlockInput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolBlockInputKeySpecifier | (() => undefined | SymbolBlockInputKeySpecifier)
        fields?: SymbolBlockInputFieldPolicy
    }
    SymbolConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolConnectionKeySpecifier | (() => undefined | SymbolConnectionKeySpecifier)
        fields?: SymbolConnectionFieldPolicy
    }
    SymbolInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolInfoKeySpecifier | (() => undefined | SymbolInfoKeySpecifier)
        fields?: SymbolInfoFieldPolicy
    }
    SymbolLocation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolLocationKeySpecifier | (() => undefined | SymbolLocationKeySpecifier)
        fields?: SymbolLocationFieldPolicy
    }
    SyncError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SyncErrorKeySpecifier | (() => undefined | SyncErrorKeySpecifier)
        fields?: SyncErrorFieldPolicy
    }
    Team?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TeamKeySpecifier | (() => undefined | TeamKeySpecifier)
        fields?: TeamFieldPolicy
    }
    TeamConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TeamConnectionKeySpecifier | (() => undefined | TeamConnectionKeySpecifier)
        fields?: TeamConnectionFieldPolicy
    }
    TeamMember?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TeamMemberKeySpecifier | (() => undefined | TeamMemberKeySpecifier)
        fields?: TeamMemberFieldPolicy
    }
    TeamMemberConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TeamMemberConnectionKeySpecifier | (() => undefined | TeamMemberConnectionKeySpecifier)
        fields?: TeamMemberConnectionFieldPolicy
    }
    TemporarySettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TemporarySettingsKeySpecifier | (() => undefined | TemporarySettingsKeySpecifier)
        fields?: TemporarySettingsFieldPolicy
    }
    TimeoutDatapointAlert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TimeoutDatapointAlertKeySpecifier | (() => undefined | TimeoutDatapointAlertKeySpecifier)
        fields?: TimeoutDatapointAlertFieldPolicy
    }
    TreeEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TreeEntryKeySpecifier | (() => undefined | TreeEntryKeySpecifier)
        fields?: TreeEntryFieldPolicy
    }
    TreeEntryLSIFData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TreeEntryLSIFDataKeySpecifier | (() => undefined | TreeEntryLSIFDataKeySpecifier)
        fields?: TreeEntryLSIFDataFieldPolicy
    }
    UpdateCheck?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UpdateCheckKeySpecifier | (() => undefined | UpdateCheckKeySpecifier)
        fields?: UpdateCheckFieldPolicy
    }
    UpdateQueue?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UpdateQueueKeySpecifier | (() => undefined | UpdateQueueKeySpecifier)
        fields?: UpdateQueueFieldPolicy
    }
    UpdateSchedule?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UpdateScheduleKeySpecifier | (() => undefined | UpdateScheduleKeySpecifier)
        fields?: UpdateScheduleFieldPolicy
    }
    UpdateSettingsPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UpdateSettingsPayloadKeySpecifier | (() => undefined | UpdateSettingsPayloadKeySpecifier)
        fields?: UpdateSettingsPayloadFieldPolicy
    }
    User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier)
        fields?: UserFieldPolicy
    }
    UserConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserConnectionKeySpecifier | (() => undefined | UserConnectionKeySpecifier)
        fields?: UserConnectionFieldPolicy
    }
    UserEmail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserEmailKeySpecifier | (() => undefined | UserEmailKeySpecifier)
        fields?: UserEmailFieldPolicy
    }
    UserPermission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserPermissionKeySpecifier | (() => undefined | UserPermissionKeySpecifier)
        fields?: UserPermissionFieldPolicy
    }
    UserUsageStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserUsageStatisticsKeySpecifier | (() => undefined | UserUsageStatisticsKeySpecifier)
        fields?: UserUsageStatisticsFieldPolicy
    }
    VirtualFile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | VirtualFileKeySpecifier | (() => undefined | VirtualFileKeySpecifier)
        fields?: VirtualFileFieldPolicy
    }
    VisibleApplyPreviewTargetsAttach?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleApplyPreviewTargetsAttachKeySpecifier
            | (() => undefined | VisibleApplyPreviewTargetsAttachKeySpecifier)
        fields?: VisibleApplyPreviewTargetsAttachFieldPolicy
    }
    VisibleApplyPreviewTargetsDetach?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleApplyPreviewTargetsDetachKeySpecifier
            | (() => undefined | VisibleApplyPreviewTargetsDetachKeySpecifier)
        fields?: VisibleApplyPreviewTargetsDetachFieldPolicy
    }
    VisibleApplyPreviewTargetsUpdate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleApplyPreviewTargetsUpdateKeySpecifier
            | (() => undefined | VisibleApplyPreviewTargetsUpdateKeySpecifier)
        fields?: VisibleApplyPreviewTargetsUpdateFieldPolicy
    }
    VisibleBatchSpecWorkspace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleBatchSpecWorkspaceKeySpecifier
            | (() => undefined | VisibleBatchSpecWorkspaceKeySpecifier)
        fields?: VisibleBatchSpecWorkspaceFieldPolicy
    }
    VisibleChangesetApplyPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleChangesetApplyPreviewKeySpecifier
            | (() => undefined | VisibleChangesetApplyPreviewKeySpecifier)
        fields?: VisibleChangesetApplyPreviewFieldPolicy
    }
    VisibleChangesetSpec?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | VisibleChangesetSpecKeySpecifier | (() => undefined | VisibleChangesetSpecKeySpecifier)
        fields?: VisibleChangesetSpecFieldPolicy
    }
    Webhook?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookKeySpecifier | (() => undefined | WebhookKeySpecifier)
        fields?: WebhookFieldPolicy
    }
    WebhookConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookConnectionKeySpecifier | (() => undefined | WebhookConnectionKeySpecifier)
        fields?: WebhookConnectionFieldPolicy
    }
    WebhookLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogKeySpecifier | (() => undefined | WebhookLogKeySpecifier)
        fields?: WebhookLogFieldPolicy
    }
    WebhookLogConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogConnectionKeySpecifier | (() => undefined | WebhookLogConnectionKeySpecifier)
        fields?: WebhookLogConnectionFieldPolicy
    }
    WebhookLogMessage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogMessageKeySpecifier | (() => undefined | WebhookLogMessageKeySpecifier)
        fields?: WebhookLogMessageFieldPolicy
    }
    WebhookLogRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogRequestKeySpecifier | (() => undefined | WebhookLogRequestKeySpecifier)
        fields?: WebhookLogRequestFieldPolicy
    }
    WebhookLogResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogResponseKeySpecifier | (() => undefined | WebhookLogResponseKeySpecifier)
        fields?: WebhookLogResponseFieldPolicy
    }
}
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies
